import { render, staticRenderFns } from "./OrdersLocationSelect.vue?vue&type=template&id=973e81c4&scoped=true&"
import script from "./OrdersLocationSelect.vue?vue&type=script&lang=js&"
export * from "./OrdersLocationSelect.vue?vue&type=script&lang=js&"
import style0 from "./OrdersLocationSelect.vue?vue&type=style&index=0&id=973e81c4&prod&lang=sass&scoped=true&"
import style1 from "./OrdersLocationSelect.vue?vue&type=style&index=1&id=973e81c4&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "973e81c4",
  null
  
)

export default component.exports