<template>
  <div class="order__container">
    <div v-if="isModalOpen" class="modal-window" @click="toggleModal(false)">
      <form @submit.prevent="saveComment" @click.stop>
        <img
          @click="toggleModal(false)"
          src="@/assets/img/icons/close.svg"
          alt=""
        />
        <input placeholder="Введите комментарий" v-model="comment" />
        <button @click="saveComment">Сохранить</button>
      </form>
    </div>
    <div class="order__item">
      <div class="order__item-title">
        {{ item.title }}
        <div v-if="!item.added_by_promo_code">
          <img
            @click="toggleModal(true)"
            src="@/assets/img/icons/comment.png"
            alt=""
          />
        </div>
      </div>
      <div
        class="order__item-qty"
        :class="{ readonly: isPaid || order.is_receipt_printed }"
      >
        <span
          @click="changeQty('decrement', 1)"
          :class="{
            readonly:
              order.is_receipt_printed ||
              item.qty === 1 ||
              (order.status.status === 'open' &&
                item.price_per_item != 0 &&
                !department.can_full_edit_confirmed_orders),
          }"
          ><svg-icon name="minus" class="order__item-qty-icon"></svg-icon
        ></span>
        <input
          type="number"
          @keypress="typ($event)"
          class="order__item-qty-number"
          :class="$style.inputNumber"
          @input="changeValueQty($event, item.qty)"
          v-model="item.qty"
          @change="changeQty"
        />
        <span
          @click="changeQty('increment', 1)"
          :class="{ readonly: item.qty === 100 }"
          ><svg-icon name="plus" class="order__item-qty-icon"></svg-icon
        ></span>
      </div>
      <div class="order__item-price" :class="{ readonly: isPaid }">
        {{
          order.source === "aggregator"
            ? item.aggregator_price_per_item
            : item.price_per_item
        }}
      </div>
      <div
        class="order__item-actions"
        :class="{
          readonly:
            isPaid ||
            order.is_receipt_printed ||
            (order.status.status === 'open' &&
              item.price_per_item != 0 &&
              !department.can_full_edit_confirmed_orders),
        }"
      >
        <div @click="remove(item.id)">
          <svg-icon name="remove" class="order__item-qty-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="order__item-toppings-add" v-if="item.comment">
      {{ item.comment }}
    </div>
    <div
      v-if="item.modifier_groups.length > 0"
      :style="{
        opacity: '0.5',
        fontSize: '13px',
        margin: '8px 0 0 0',
      }"
    >
      <div v-for="i in item.modifier_groups" :key="i.id">
        {{ i.title }}:
        <span v-for="(j, index) in i.modifiers" :key="j.id">
          {{ j.title + " " + j.qty + "X"
          }}{{ index !== i.modifiers.length - 1 ? "," : "." }}
        </span>
      </div>
    </div>
    <div
      class="open-toppings"
      @click="
        $store.commit('setToppingsModal', {
          isOpen: true,
          item: item,
          type: 'patch',
        })
      "
      v-if="item.are_exists_modifier_groups"
    >
      {{ item.modifier_groups.length > 0 ? "Изменить" : "Добавить" }}
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import { mapActions, mapGetters } from "vuex";
//import Order from "@/api/order.js";

export default {
  name: "OrderItem",
  components: { SvgIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
      comment: null,
    };
  },
  methods: {
    toggleModal(value) {
      this.isModalOpen = value;
      if (value) {
        if (this.item.comment) {
          this.comment = this.item.comment;
        }
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    },
    async saveComment() {
      if (this.comment !== null) {
        await this.$store.dispatch("addComment", {
          itemId: this.item.id,
          comment: this.comment,
        });
      }

      this.isModalOpen = false;
      document.body.style.overflow = "visible";
    },
    typ: function (event) {
      if (
        event.keyCode === 43 ||
        event.keyCode === 45 ||
        event.keyCode === 101
      ) {
        event.preventDefault();
      }
    },
    ...mapActions({
      remove: "removeItemFromOrder",
    }),
    changeValueQty(event, item) {
      if (item > 100) {
        this.item.qty = 100;
      }
    },
    changeQty(action, val) {
      if (this.item.qty < 1) {
        this.item.qty = 1;
      }
      let qty = this.item.qty;
      if (action === "increment") {
        qty += val;
        this.item.qty += val;
      } else if (action === "decrement") {
        qty -= val;
        this.item.qty -= val;
      }
      this.$store.dispatch("changeOrderItemQty", {
        itemId: this.item.id,
        qty,
      });
    },
  },
  computed: {
    ...mapGetters(["order", "isPaid", "department"]),
    readonly() {
      return this.order.status.status === "open";
    },
  },
};
</script>

<style lang="sass" scoped>
.open-toppings
  color: rgba(235, 64, 65, 1)
  font-size: 14px
  cursor: pointer
.modal-window
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)
  z-index: 10
  form
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 600px
    height: 400px
    padding: 32px 16px 16px 16px
    background: #fff
    border-radius: 8px
    button
      outline: 0
      border: none
      background-color: #ff2537
      border-radius: 1rem
      font-size: 16px
      padding: 0 0.75rem
      height: 3rem
      line-height: 18px
      color: #fff
      &:active,
      &:focus
        outline: none
      &::-moz-focus-inner
        border: 0

    img
      position: absolute
      top: 16px
      right: 16px
      cursor: pointer
    input
      all: unset
      width: 100%
      padding: 0 0 280px 0
.order__container
  padding: 10px 0
  border-bottom: 1px solid #F0EEEE
  border-top: 1px solid #F0EEEE
  .order__item-toppings-add
    padding: 8px 0 10px 0
    font-size: 13px
  .order__item-toppings
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    gap: 16px
    .order__item-topping
      text-align: center
      cursor: pointer
      padding: 12px
      background: #FFFFFF
      border: 1px solid #F0EEEE
      border-radius: 12px
      &.checkedTopping
        border: 1px solid #FF2537
      .order__item-topping-checkbox
        margin: 0 auto 20px auto
        padding: 0
        left: 5px
      .order__item-topping-title
        font-size: 12px
      .order__item-topping-price
        font-size: 14px

.order__item
    display: flex
    font-weight: bold

    &-title
        width: 55%
        display: flex
        align-items: center
        >div
          margin: 0 0 0 16px
          img
            cursor: pointer
            width: 20px
            height: 20px

    &-qty
        display: flex
        align-items: center
        width: 20%
        text-align: center

        &-icon
            cursor: pointer
            width: 1.2rem
            height: 1.2rem

        &-number
            padding: 0 10px

    &-price
        width: 25%
        text-align: center

    &-actions
        width: 10%
        text-align: right
</style>

<style lang="scss" module>
.inputNumber {
  min-width: 50px;
  font-size: 1rem;
  height: 20px;
  border: 0;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>
