import Department from '@/api/department'

export default {
    state: {
        isCityFetching: false,
        isDepartmentFetching: false,
        city: null,
        department: null,
        chosenSitySlug: null,
    },
    getters: {
        takeAwayDepartments: (state, getters) => {
            return getters.user.city.departments
        },
        currentDepartment: (state, getters) => {
            return getters.user.department  // {id: int, title: string}
        },
        hasModule: (state) => (module) => {
            if (state.department) {
                return state.department.modules.includes(module)
            } else {
                return false
            }
        },
        department: (state) => {
            return state.department
        },
        city: (state) => {
            return state.city
        },
        currentCity: (state, getters) => {
            return getters.user.department.city  // {id: int, title: string}
        },
        currentCountry: (state, getters) => {
            return getters.user.department.city.country
        },
        chosenSitySlug: (state) => {
            return state.chosenSitySlug
        }
    },
    mutations: {
        setCity(state, city) {
            state.city = city
        },
        setDepartment(state, { department }) {
            state.department = department
        },
        toggleFetching(state, { entity }) {
            const key = `is${entity}Fetching`
            state[key] = !state[key]
        },
        setChosenSitySlug(state, value) {
            state.chosenSitySlug = value
        },
    },
    actions: {
        async fetchDepartment({ commit, getters }) {
            commit('toggleFetching', { entity: 'Department' })
            const department = await Department.get(getters.user.department.slug)
            commit('setDepartment', { department })
            commit('toggleFetching', { entity: 'Department' })
        }
    }
}