import User from '@/api/user'
import {UPDATE_ORDER_USER} from "./mutation-types";

const getDefaultState = () => {
    return {
        phoneCode: null
    }
};

export default {
    state: getDefaultState(),
    mutations: {
        [UPDATE_ORDER_USER] (state, {order, user}) {
            order.user = user
        },
        setPhoneCode (state, phoneCode) {
            state.phoneCode = phoneCode
        }
    },
    actions: {
        async setOrderUserField({commit}, {field, value, user, order}) {
            let userUpdated = await User.setField(field, value, user.id);
            commit(UPDATE_ORDER_USER, {order, user: userUpdated})
        },
        async setPhoneCode() {
            const resp = await User.getUser()
            let phoneCode = resp.data.country.phone_code
            this.commit('setPhoneCode', phoneCode)
        },
    }
}