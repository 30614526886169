import { render, staticRenderFns } from "./OrderDiscountsList.vue?vue&type=template&id=33699f79&scoped=true&"
import script from "./OrderDiscountsList.vue?vue&type=script&lang=js&"
export * from "./OrderDiscountsList.vue?vue&type=script&lang=js&"
import style0 from "./OrderDiscountsList.vue?vue&type=style&index=0&id=33699f79&prod&lang=sass&scoped=true&"
import style1 from "./OrderDiscountsList.vue?vue&type=style&index=1&id=33699f79&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "33699f79",
  null
  
)

export default component.exports