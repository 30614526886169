import {Model} from './index'

class Category extends Model {
    path = '/v1/categories';

    list(cityId) {
        let params = {
            city: cityId
        };
        return this._list(params)
    }
}

export default new Category()
