<template>
  <div class="order__points" :class="{ readonly: isPaid }">
    <img :src="iconPath" class="order__points-img" alt="" />
    <span class="order__points-caption">{{ userPoints }} баллов</span>
    <div
      :class="$style.input"
      v-if="$store.state.location.city.use_user_code"
      class="fancy-input fancy-input--no-label"
    >
      <input
        type="text"
        class="order__points-input"
        :class="{ readonly: loadingForm }"
        placeholder="Код"
        v-model="pointsCode"
      />
    </div>

    <div
      v-if="
        !$store.state.location.city.use_user_code ||
        order.payment_info.points_used > 0
      "
      class="fancy-input fancy-input--no-label"
      :class="$style.input"
    >
      <input
        @blur="savePoints"
        type="text"
        class="order__points-input"
        :class="{ readonly: loadingForm }"
        v-model="points"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderUserPoints",
  data: () => {
    return {
      pointsCode: "",
      isCode: false,
      points: 0,
    };
  },
  methods: {
    async savePoints() {
      if (!this.points) {
        this.points = 0;
      }
      await this.$store.dispatch("changeUserPoints", { points: this.points });
    },
  },
  computed: {
    ...mapGetters(["order", "loadingForm", "isPaid", "currentCity"]),
    userPoints() {
      const countryId = localStorage.getItem("countryId");

      let points = 0;
      const countryPoints = this.order?.user?.country_points;

      if (countryPoints) {
        for (let i = 0; i < countryPoints.length; i++) {
          if (countryPoints[i].country_id == countryId) {
            points = countryPoints[i].points;
          }
        }
      }

      return points;
    },
    iconPath() {
      let name = "points";
      if (this.order.points_used) {
        return require(`@/assets/img/icons/${name}--done.svg`);
      } else {
        return require(`@/assets/img/icons/${name}.svg`);
      }
    },
  },
  watch: {
    async pointsCode(code) {
      if (code.length === 4) {
        await this.$store.dispatch("applyUserPoints", { code });
        this.points = this.order.payment_info.points_used;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.order__points
    display: flex
    align-items: center

    &-img
        width: 45px
        height: 45px

    &-caption
        padding: 0 15px

    &-input
        width: 85px
</style>

<style lang="scss" module>
.input {
  width: 70px;
  min-width: auto;
  input {
    width: 100% !important;
    min-width: auto;
  }
}
</style>
