export default {
    watch: {
        getOrders(newVal, oldVal) {
            if (newVal[0]?.id !== oldVal[0]?.id) {
                this.playNotification()
            }
        }
    },
    methods: {
        playNotification() {
            const audio = new Audio(require('@/assets/sounds/new-order.wav'))
            audio.play()
        }
    }
}