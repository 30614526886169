<template>
  <div>
    <page-header />
    <!-- start main -->
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active tab-month" id="tab-2">
            <div class="tab-month__top">
              <div class="tab-month__top-calendar">
                <p>{{ currentMonthName }}.{{ new Date().getFullYear() }}</p>
              </div>
              <ul class="tab-month__top-list">
                <li>
                  <router-link :to="{ name: 'analytics-monthly' }"
                    >Сводка</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'analytics-monthly-by-days' }"
                    >По дням</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="main-row">
              <revenue-count-block
                v-if="monthlySummaryCurrent && monthlySummaryYearAgo"
                keyword="amount"
                header="Выручка"
                subheader="Выручка общая"
                :month="currentMonthName"
                :summary-current="monthlySummaryCurrent"
                :summary-year-ago="monthlySummaryYearAgo"
              />
              <revenue-count-block
                v-if="monthlySummaryCurrent && monthlySummaryYearAgo"
                keyword="count"
                header="Заказы"
                subheader="Количество заказов"
                :month="currentMonthName"
                :summary-current="monthlySummaryCurrent"
                :summary-year-ago="monthlySummaryYearAgo"
              />
            </div>
            <div class="main-row">
              <div class="main-col col-50">
                <div class="main-col__row">
                  <h3 class="main-col__row-title color-red">
                    Выручка за последние 12 месяцев
                  </h3>
                  <chart-block
                    v-if="annualSummaryByMonths"
                    :width="490"
                    :height="490"
                    header="Выручка"
                    :values="revenueValues"
                    :labels="revenueLabels"
                  />
                </div>
              </div>
              <div class="main-col col-50">
                <div class="main-col__row">
                  <h3 class="main-col__row-title color-red">
                    Количество заказов за последние 12 месяцев
                  </h3>
                  <chart-block
                    v-if="annualSummaryByMonths"
                    :width="490"
                    :height="490"
                    header="Количество заказов"
                    :values="countValues"
                    :labels="revenueLabels"
                  />
                </div>
              </div>
            </div>
            <h2 class="section-title">СКОРОСТЬ</h2>
            <div class="main-row">
              <div class="main-col col-50">
                <div class="main-col__row">
                  <h3 class="main-col__row-title color-red">
                    Скорость кухни, %
                  </h3>
                  <success-rate-bars
                    v-if="monthlySummaryCurrent"
                    :summary-current="monthlySummaryCurrent"
                    keyword="kitchen"
                  />
                  <success-rate-chart
                    v-if="annualSummaryByMonths"
                    :labels="revenueLabels"
                    :summary="annualSummaryByMonths"
                    keyword="kitchen"
                  />
                </div>
              </div>
              <div class="main-col col-50">
                <div class="main-col__row">
                  <h3 class="main-col__row-title color-red">
                    Скорость доставки, %
                  </h3>
                  <success-rate-bars
                    v-if="monthlySummaryCurrent"
                    :summary-current="monthlySummaryCurrent"
                    keyword="delivery"
                  />
                  <success-rate-chart
                    v-if="annualSummaryByMonths"
                    :labels="revenueLabels"
                    :summary="annualSummaryByMonths"
                    keyword="delivery"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end main -->
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import RevenueCountBlock from "@/components/analytics/monthly/RevenueCountBlock";
import Analytics from "@/api/analytics";
import ChartBlock from "@/components/analytics/monthly/ChartBlock";
import SuccessRateBars from "@/components/analytics/monthly/SuccessRateBars";
import SuccessRateChart from "@/components/analytics/monthly/SuccessRateChart";

export default {
  name: "AnalyticsMonthlyPageComponent",
  components: {
    SuccessRateChart,
    SuccessRateBars,
    ChartBlock,
    RevenueCountBlock,
    PageTabs,
    PageHeader,
  },
  props: {
    period: {
      type: String,
      required: true,
    },
    labelsHandler: {
      type: Function,
      required: true,
    },
    chartStartDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      monthlySummaryCurrent: null,
      monthlySummaryYearAgo: null,
      annualSummaryByMonths: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const today = new Date().toJSON().slice(0, 7);
      const yearAgo = new Date();
      yearAgo.setFullYear(yearAgo.getFullYear() - 1);
      const yearAgoFormatted = yearAgo.toJSON().slice(0, 7);
      this.monthlySummaryCurrent = await Analytics.dailyOrdersSummary({
        date: today,
        departmentSlug: this.$store.getters.currentDepartment.slug,
        period: "monthly",
      });
      this.monthlySummaryYearAgo = await Analytics.dailyOrdersSummary({
        date: yearAgoFormatted,
        departmentSlug: this.$store.getters.currentDepartment.slug,
        period: "monthly",
      });
      this.annualSummaryByMonths = await Analytics.monthlyOrdersSummary({
        startDate: this.chartStartDate,
        departmentSlug: this.$store.getters.currentDepartment.slug,
        period: this.period,
      });
    },
  },
  computed: {
    currentMonthName() {
      const today = new Date();
      return today.toLocaleString("default", { month: "long" });
    },
    revenueValues() {
      const res = [];
      this.annualSummaryByMonths.forEach((month) => {
        res.push(month.total_amount);
      });
      return res;
    },
    revenueLabels() {
      return this.labelsHandler(this.annualSummaryByMonths);
    },
    countValues() {
      const res = [];
      this.annualSummaryByMonths.forEach((month) => {
        res.push(month.total_count);
      });
      return res;
    },
  },
};
</script>

<style scoped></style>
