<template>
  <div class="order__money">
    <div>
      <div>Сумма: {{ order.payment_info.subtotal }}</div>
      <div>Баллы: {{ order.payment_info.points_used }}</div>
      <div>Скидка: {{ getRest }}</div>
      <div>Обслуживание: {{ getService }}</div>
    </div>
    <div class="order__money-right-col">
      <div>Сдача: {{ restAmount }}</div>
      <div class="order__money-total">
        Итого:
        <span class="order__money-total-amount">{{
          order.payment_info.total_price
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderMoney",
  computed: {
    ...mapGetters(["order", "restAmount"]),
    getRest() {
      if (this.order.payment_info.discount_percent_amount != 0) {
        return this.order.payment_info.discount_percent_amount[0] === "-"
          ? "+" + this.order.payment_info.discount_percent_amount.slice(1)
          : this.order.payment_info.discount_percent_amount;
      } else if(this.order.payment_info.discount_amount != 0){
        return this.order.payment_info.discount_amount;
      }
      else return 0
    },
    getService() {
      if (this.order.payment_info.service_percent_amount) {
        return this.order.payment_info?.service_percent_amount[0] === "-"
          ? "+" + this.order.payment_info?.service_percent_amount.slice(1)
          : this.order.payment_info?.service_percent_amount;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../assets/sass/variables"

.order__money
    display: flex
    justify-content: space-between
    align-items: flex-end
    padding: 1rem 0

    &-right-col
        text-align: right

    &-total
        font-size: 21px
        font-weight: bold
        &-amount
            color: $brand-color
</style>
