<template>
  <div class="order__discounts" :class="{ readonly: isPaid || loadingForm }">
    <div v-if="discounts.length > 0" class="discount">
      <p>Скидка:</p>
      <div
        v-if="$store.state.location.city.use_discount_amount"
        class="fancy-input fancy-input--no-label"
        :class="$style.input"
      >
        <input
          @blur="saveDiscount"
          v-model.number="descount"
          type="number"
          placeholder="скидка"
          class="order__rest-from-input disabled_scroll"
        />
      </div>
      <order-discount
        v-for="discount in discounts"
        :key="discount.id"
        :discount="discount"
      ></order-discount>
    </div>

    <div v-if="services.length > 0" class="negative-discount">
      <p>Обслуживание:</p>
      <order-services
        v-for="discount in services"
        :key="discount.id"
        :discount="discount"
      ></order-services>
    </div>
  </div>
</template>

<script>
import OrderDiscount from "@/components/order/order-discounts/OrderDiscount";
import { mapGetters } from "vuex";
import City from "@/api/city";
import OrderServices from "@/components/order/order-discounts/OrderServices.vue";

export default {
  name: "OrderDiscountsList",
  components: { OrderServices, OrderDiscount },
  data: function () {
    return {
      discounts: [],
      services: [],
      descount: 0,
    };
  },
  created() {
    this.getDiscount();
    this.getServices();
  },
  methods: {
    async getDiscount() {
      let path = localStorage.getItem("citySlug") + "/discounts";
      this.discounts = await City.get(path);
      this.discounts = this.discounts.results;
    },
    async getServices() {
      let path = localStorage.getItem("citySlug") + "/services";
      this.services = await City.get(path);
      this.services = this.services.results;
    },
    async saveDiscount() {
      if (!this.descount) {
        this.descount = 0;
      }
      let payload = {
        field: "payment_info.discount_amount",
        value: this.descount,
      };

      await this.$store.dispatch("setOrderField", payload);
    },
  },
  computed: {
    ...mapGetters(["isPaid", "loadingForm", "order"]),
  },
  watch: {
    order() {
      if (this.order.payment_info.discount_percent) {
        this.descount = 0;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"
.order__discounts
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: $vertical-padding-default 0

.discount, .negative-discount
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 1rem
  p

    margin: 0 !important
.negative-discount
  margin: 1rem 0 0 0
</style>

<style lang="scss" module>
.input {
  width: 7rem;
  input {
    min-width: auto !important;
    width: 100%;
  }
}
</style>
