import Vue from "vue";
import Router from "vue-router";
import OrdersManagementPage from "@/components/management/OrdersManagementPage";
import OrdersCookingPage from "@/components/cooking/OrdersCookingPage";
import LoginPage from "@/components/auth/LoginPage";
import SelectRolePage from "@/components/auth/SelectRolePage";
import OrderEditPage from "@/components/order/OrderEditPage";
import OrderCancelPage from "@/components/order/OrderCancelPage";
import OrderReceiptPage from "@/components/order-receipt/OrderReceiptPage";
import OrdersPackingPage from "@/components/packing/OrdersPackingPage";
import OrdersListDatePage from "@/components/orders-list/OrdersListDatePage";
import AnalyticsDailyPage from "@/components/analytics/daily/AnalyticsDailyPage";
import AnalyticsReportsRevenuePage from "@/components/analytics/reports/AnalyticsReportsRevenuePage";
import AnalyticsReportsBestsellersPage from "@/components/analytics/reports/AnalyticsReportsBestsellersPage";
import AnalyticsReportsCourierLate from "@/components/analytics/reports/AnalyticsReportsCourierLate";
import AnalyticsMonthlySummaryPage from "@/components/analytics/monthly/AnalyticsMonthlySummaryPage";
import AnalyticsMonthlyByDaysPage from "@/components/analytics/monthly/AnalyticsMonthlyByDaysPage";
import DepartmentsPerformancePage from "@/components/analytics/performance/DepartmentsPerformancePage";
import UsersListPage from "@/components/users/UsersListPage";
import UsersEditPage from "@/components/users/UsersEditPage";
import UsersCreatePage from "@/components/users/UsersCreatePage";
import SchedulePage from "@/components/schedule/SchedulePage";
import MenuItemsListPage from "@/components/menu/MenuItemsListPage";
import InfoScreenPage from "@/components/info-screen/InfoScreenPage"
import DirectorPage from "@/components/director/DirectorPage"
import StoriesPage from "@/components/stories/StoriesPage";
import StoriesCreatePage from "@/components/stories/StoriesCreatePage";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/roles",
      name: "roles",
      component: SelectRolePage,
    },
    {
      path: "/management",
      name: "operator",
      component: OrdersManagementPage,
      meta: { title: " | Оператор" },
    },
    {
      path: "/orders/add",
      name: "order-add-page",
      component: OrderEditPage,
      meta: { title: " | Оператор" },
    },
    {
      path: "/orders/list/:date",
      name: "orders-list-date",
      component: OrdersListDatePage,
      meta: { title: " | Оператор" },
    },
    {
      path: "/orders/:id/edit",
      name: "order-edit-page",
      component: OrderEditPage,
      meta: { title: " | Оператор" },
    },
    {
      path: "/orders/:id/cancel",
      name: "order-cancel-page",
      component: OrderCancelPage,
      meta: { title: " | Оператор" },
    },
    {
      path: "/orders/:id/receipt",
      name: "order-receipt-page",
      component: OrderReceiptPage,
      meta: { title: " | Оператор" },
    },
    {
      path: "/cooking",
      name: "cook",
      component: OrdersCookingPage,
      meta: { title: " | Повар" },
    },
    {
      path: "/packing",
      name: "packer",
      component: OrdersPackingPage,
      meta: { title: " | Упаковщик" },
    },
    {
      path: "/manager",
      name: "manager",
      redirect: () => {
        return "/analytics/daily";
      },
      meta: { title: " | Управляющий" },
    },
    {
      path: "/analytics/daily",
      name: "analytics-daily",
      component: AnalyticsDailyPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/analytics/monthly/summary",
      name: "analytics-monthly",
      component: AnalyticsMonthlySummaryPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/analytics/monthly/by-days",
      name: "analytics-monthly-by-days",
      component: AnalyticsMonthlyByDaysPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/analytics/reports/revenue",
      name: "analytics-reports-revenue",
      component: AnalyticsReportsRevenuePage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/analytics/reports/bestsellers",
      name: "analytics-reports-bestsellers",
      component: AnalyticsReportsBestsellersPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/analytics/reports/couriers/late",
      name: "analytics-reports-courier-late",
      component: AnalyticsReportsCourierLate,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/analytics/departments-performance",
      name: "analytics-departments-performance",
      component: DepartmentsPerformancePage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/users",
      name: "users-list",
      component: UsersListPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/users/:id/edit",
      name: "users-edit",
      component: UsersEditPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/users/create",
      name: "users-create",
      component: UsersCreatePage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/schedule",
      name: "schedule",
      component: SchedulePage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/menu-items",
      name: "menu-items-list",
      component: MenuItemsListPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/takeaway-info-screen",
      name: "takeaway_info_screen",
      component: InfoScreenPage,
      meta: { title: " | Зал" }
    },
    {
      path: "/director",
      name: "director",
      component: DirectorPage,
      meta: { title: " | Директор" }
    },
    {
      path: "/stories",
      name: "stories",
      component: StoriesPage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/stories/create",
      name: "stories-create",
      component: StoriesCreatePage,
      meta: { title: " | Управляющий" }
    },
    {
      path: "/stories/:id/edit",
      name: "stories-edit",
      component: StoriesCreatePage,
      meta: { title: " | Управляющий" }
    },
  ],
});
