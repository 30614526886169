<template>
  <div
    @click="showCategoryMenuItems(category)"
    class="categories__item"
    :style="'background:' + category.dashboard_bg"
  >
    {{ category.title }}
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CategoriesList",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      showCategoryMenuItems: "setCategoryMenuItems",
    }),
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.categories__item
    padding: 30px 20px
    font-size: 20px
    font-weight: bold
    margin-bottom: 10px
    color: white
    background: lightgrey
    cursor: pointer
    border-radius: $border-radius-default
    text-align: center
</style>
