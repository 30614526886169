<template>
  <div
    class="order__discounts-discount btn"
    :class="isCurrentOrderServices ? 'btn--primary' : 'btn--secondary'"
    @click="setOrderServices()"
  >
    {{ this.discount.percent }}%
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderServices",
  props: {
    discount: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setOrderServices() {
      let discount = 0;
      if (this.isCurrentOrderServices) {
        discount = 0;
      } else {
        discount = this.discount.percent;
      }
      let payload = {
        field: "payment_info.service_percent",
        value: discount,
      };
      this.$store.dispatch("setOrderField", payload);
    },
  },
  computed: {
    ...mapGetters(["order"]),
    isCurrentOrderServices() {
      return this.order.payment_info.service_percent === this.discount.percent;
    },
  },
};
</script>

<style lang="sass" scoped>
.order__discounts-discount
  padding: 10px 25px
  margin-left: 15px
</style>
