<template>
  <analytics-monthly-page-component
    period="daily"
    :labels-handler="labelsHandler"
    :chart-start-date="chartStartDate"
  />
</template>

<script>
import AnalyticsMonthlyPageComponent from "@/components/analytics/monthly/AnalyticsMonthlyPageComponent";
export default {
  name: "AnalyticsMonthlyPage",
  components: { AnalyticsMonthlyPageComponent },
  methods: {
    labelsHandler(data) {
      const res = [];
      data.forEach((month) => {
        res.push(month.date.slice(5));
      });
      return res;
    },
  },
  computed: {
    chartStartDate() {
      const monthAgo = new Date();
      monthAgo.setMonth(monthAgo.getMonth() - 1);
      return monthAgo.toJSON().slice(0, 10);
    },
  },
};
</script>
