<template>
  <div>
    <page-header/>
    <!-- start main -->
    <section class="main">
      <div class="wrapper">
        <page-tabs/>
        <div class="main-wrap">
          <div class="tab tab-active tab-month" id="tab-2">
            <div class="tab-month__top">
              <div class="tab-month__top-calendar">
                <p>Добавление персонала</p>
              </div>
            </div>
            <div class="main-block">
              <user-form />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import UserForm from "@/components/users/UserForm";

export default {
  name: "UsersEditPage",
  components: { UserForm, PageTabs, PageHeader },
}
</script>

<style scoped>

</style>