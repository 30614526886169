<template>
  <div class="order__payment-types" :class="{ readonly: isPaid }">
    <order-payment-type
      v-for="paymentType in paymentTypes"
      :key="paymentType.id"
      :paymentType="paymentType"
    ></order-payment-type>
  </div>
</template>

<script>
import OrderPaymentType from "@/components/order/order-payment-types/OrderPaymentType";
import { SET_ORDER_FIELD } from "@/store/modules/order-form/mutation-types";
import Order from "@/api/order";
import { mapGetters } from "vuex";

export default {
  name: "OrderPaymentTypeList",
  components: { OrderPaymentType },
  data: () => {
    return {
      paymentTypes: [
        { id: Order.PAYMENT_TYPE.cash, label: "cash", readOnly: false },
        { id: Order.PAYMENT_TYPE.pos, label: "pos", readOnly: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["isPaid", "order", "city"]),
  },
  watch: {
    city() {
      if (!this.city.is_cash_pay) {
        this.paymentTypes = [
          {
            id: Order.PAYMENT_TYPE.pos,
            label: "pos",
            readOnly: false,
          },
        ];
        let payload = {
          field: "payment_info.payment_type",
          value: Order.PAYMENT_TYPE.pos,
        };
        this.$store.commit(SET_ORDER_FIELD, payload);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.order__payment-types
    display: flex
    padding: $vertical-padding-default 0
</style>
