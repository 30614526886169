<template>
  <div :class="{ 'order__form--padded': isDelivery }">
    <div class="order__phone-name" v-if="!isStay">
      <div
        class="fancy-input"
        :class="{
          readonly: order.status.status !== 'cart',
        }"
      >
        <label for="phone">Телефон</label>
        <input
          @focus="markPhone"
          class="tel"
          id="phone"
          type="text"
          v-model="rawPhone"
          @blur="setPhone"
          :placeholder="$store.getters.user.department.city.country.phone_code"
        />
      </div>
      <div
        class="fancy-input"
        :class="{
          readonly: readonly,
        }"
      >
        <label for="name">Имя</label>
        <input id="name" type="text" v-model="firstName" />
      </div>
    </div>
    <div
      class="order__phone-name"
      v-else
      :class="{
        readonly: readonly,
      }"
    >
      <div
        class="fancy-input"
        :class="{
          readonly: order.status.status !== 'cart',
        }"
      >
        <label for="phone">Телефон</label>
        <input
          @focus="markPhone"
          id="phone"
          type="text"
          v-model="rawPhone"
          @blur="setPhone"
          :placeholder="$store.getters.user.department.city.country.phone_code"
        />
      </div>
      <div class="fancy-input">
        <label for="stay">Номер стола</label>
        <input id="stay" type="text" v-model="table_number" />
      </div>
    </div>
    <div
      class="order__address"
      v-show="isDelivery"
      :class="{
        readonly: readonly,
      }"
    >
      <div v-if="isZone" class="zone-address">
        <div v-if="zoneError" class="zone-error">{{ zoneError }}</div>
        <div class="fancy-input">
          <label for="address">Улица, дом</label>
          <input
            id="address"
            type="text"
            v-model="zoneAddress"
            @input="getSuggest"
            @keyup.enter="setSuggest(null, zoneAddress)"
          />
        </div>
        <div class="zone-suggest" v-if="suggest.length">
          <div
            v-for="(item, idx) in suggest"
            :key="idx"
            @click="setSuggest(item.id, item.formatted_address)"
          >
            {{ item.formatted_address }}
          </div>
        </div>
      </div>
      <div v-if="!isZone" class="fancy-input order__address-street">
        <label>Улица</label>
        <google-autocomplete-street-input
          @changed="onStreetChanged"
        ></google-autocomplete-street-input>
      </div>
      <div v-if="!isZone" class="fancy-input">
        <label for="house">Дом</label>
        <input id="house" type="text" v-model="house" />
      </div>
      <div class="fancy-input">
        <label for="apartment">Квартира</label>
        <input id="apartment" type="text" v-model="apartment" />
      </div>
      <div class="fancy-input">
        <label for="porch">Подъезд</label>
        <input id="porch" type="text" v-model="porch" />
      </div>
      <div class="fancy-input">
        <label for="floor">Этаж</label>
        <input id="floor" type="text" v-model="floor" />
      </div>
      <div class="fancy-input">
        <label for="door_code">Домофон</label>
        <input id="door_code" type="text" v-model="door_code" />
      </div>
    </div>
    <div class="order__additional-info" :class="$style.comment">
      <div class="fancy-input order__additional-info-comment">
        <label for="comment">Комментарий о заказе</label>
        <textarea
          id="comment"
          rows="3"
          v-model.lazy="comment"
          @change="setComment"
          v-model="comment"
        ></textarea>
      </div>
      <div>
        <div class="order__to-time" :class="$style.date">
          <div class="order__to-time-label" :class="$style.label">
            Ко времени
          </div>
          <div class="order__to-time-inputs" :class="$style.inputs">
            <VueCtkDateTimePicker
              color="#000"
              button-color="#FF2537"
              locale="ru"
              label="Доставить к"
              :min-date="formatData"
              v-model="deliverAt"
              minute-interval="5"
              :no-button-now="true"
              format="YYYY-MM-DD HH:mm"
              input-size="lg"
              @is-shown="openPicker"
              @is-hidden="setDeliverAt"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isDelivery && lastAddresses.length > 0"
      class="order__suggested-addresses"
      :class="$style.auto"
    >
      <ul>
        <li
          v-for="address in lastAddresses"
          :key="`${address.street}-${address.house}-${address.porch}-${address.floor}-${address.apartment}`"
        >
          <span
            class="order__suggested-addresses-row"
            @click="setOrderAddress(address)"
            >{{ friendlyAddress(address) }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Order from "@/api/order";
import { SET_ORDER_FIELD } from "@/store/modules/order-form/mutation-types";
import { mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
import GoogleAutocompleteStreetInput from "@/components/order/order-form/GoogleAutocompleteStreetInput";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import { buildAddressString } from "@/utils/receipt";
import { format } from "date-fns";
import City from "@/api/city";
import debounce from "debounce";

const { mapFields } = createHelpers({
  mutationType: SET_ORDER_FIELD,
});

export default {
  name: "OrderForm",
  components: { GoogleAutocompleteStreetInput, VueCtkDateTimePicker },
  data() {
    return {
      Order: Order,
      rawPhone: null,
      temp: "",
      disabledHours: [],
      formatData: null,
      isZone: false,
      suggest: [],
      zoneError: null,
      zoneAddress: "",
    };
  },
  async mounted() {
    let path = localStorage.getItem("citySlug");
    const city = await City.get(path);
    this.$store.commit("setCity", city);
    this.isZone = city.are_delivery_zones_enabled;

    if (this.isZone && this.order.id) {
      this.zoneAddress =
        this.order.address.street + ", " + this.order.address.house;
    }

    this.$store.commit("setOrderPhone", { value: null });
    if (this.order.contacts.phone) {
      if (this.order.contacts.phone.substr(0, 2) == "+7") {
        let cleaned = ("" + this.order.contacts.phone).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
          this.rawPhone =
            "+7(" + match[2] + ")" + match[3] + "-" + match[4] + "-" + match[5];
        }
      }
    } else {
      this.rawPhone =
        this.$store.getters.user.department.city.country.phone_code;
    }
    this.deliverTime();
  },
  methods: {
    markPhone() {
      if (this.rawPhone === "+7") {
        this.rawPhone += "(";
      }
    },
    getSuggest: debounce(async function () {
      if (this.zoneAddress) {
        const resp = await this.$store.dispatch("getSuggest", {
          input: this.zoneAddress,
          city: localStorage.getItem("citySlug"),
        });
        this.suggest = resp;
      }
    }, 300),
    async setSuggest(id, address) {
      const resp = await this.$store.dispatch("getGeo", {
        id: id,
        address: localStorage.getItem("citySlug") + ", " + address,
        city: localStorage.getItem("citySlug"),
      });

      this.suggest = [];
      if (resp.components.street) {
        this.zoneAddress =
          resp.components.street + ", " + resp.components.house;
      } else if (resp.components.district) {
        this.zoneAddress =
          resp.components.district + ", " + resp.components.house;
      }

      this.latitude = resp.coordinates.latitude;
      this.longitude = resp.coordinates.longitude;

      const count = await this.$store.dispatch("checkGeo", {
        city: localStorage.getItem("citySlug"),
        coordinates:
          parseFloat(this.latitude) + ", " + parseFloat(this.longitude),
      });

      if (count.count < 1) {
        this.zoneError = "Адрес не входит в зону доставки или не существует";
        return;
      } else if (
        count.count == 1 &&
        count.results[0].slug !== this.departmentSlug
      ) {
        this.zoneError = "Адрес не входит в зону доставки или не существует";
        return;
      } else {
        this.zoneError = "";
        this.street = this.zoneAddress.split(",")[0].trimStart();
        this.house = this.zoneAddress.split(",")[1].trimStart();
        this.formatted_address = this.zoneAddress;
        if (this.order.id) {
          let payload = {
            field: "address",
            value: this.order.address,
          };
          this.$store.dispatch("setOrderField", payload);
        } else {
          await this.$store.dispatch("saveNotSyncedFields");
          this.street = this.zoneAddress.split(",")[0].trimStart();
          this.house = this.zoneAddress.split(",")[1].trimStart();
          this.formatted_address = this.zoneAddress;
        }
      }
    },
    setDeliverAt() {
      let payload = {
        field: "order.deliver_at",
        value: this.deliverAt,
      };
      this.$store.dispatch("setOrderField", payload);
    },
    setPhone() {
      let payload = {
        field: "contacts.phone",
        value: this.rawPhone,
      };
      this.$store.commit("setOrderPhone", { value: payload.value });
      this.$store.dispatch("setOrderField", payload);
      this.$store.dispatch("fetchLastAddresses", this.rawPhone);
    },
    setComment() {
      if (this.order.status.is_confirmed) {
        let payload = {
          field: "comment",
          value: this.comment,
        };
        this.$store.dispatch("setOrderField", payload);
      }
    },

    openPicker() {
      function padTo2Digits(num) {
        return num.toString().padStart(2, "0");
      }

      function formatDate(date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join("-") +
          " " +
          [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(
            ":"
          )
        );
      }

      this.formatData = formatDate(new Date());
      window.setInterval(() => {
        function padTo2Digits(num) {
          return num.toString().padStart(2, "0");
        }
        function formatDate(date) {
          return (
            [
              date.getFullYear(),
              padTo2Digits(date.getMonth() + 1),
              padTo2Digits(date.getDate()),
            ].join("-") +
            " " +
            [
              padTo2Digits(date.getHours()),
              padTo2Digits(date.getMinutes()),
            ].join(":")
          );
        }
        this.formatData = formatDate(new Date());
      }, 1000);
    },
    deliverTime() {
      const isToday = (someDate) => {
        const today = new Date();
        return (
          someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
        );
      };
      let time = "";
      this.temp = this.deliverAt;

      if (isToday(new Date(this.deliverAt))) {
        time = format(new Date(this.deliverAt), "HH:mm");
      } else {
        time = format(new Date(this.deliverAt), "HH:mm (dd.MM)");
      }

      time = time.split("");
      this.temp = this.temp.split("");
      this.temp[11] = time[0];
      this.temp[12] = time[1];
      this.temp[14] = time[3];
      this.temp[15] = time[4];
      this.temp = this.temp.join("");
      this.deliverAt = this.temp.substring(0, this.temp.length - 1);
    },
    onStreetChanged(streetName) {
      this.street = streetName;
    },
    friendlyAddress(address) {
      const obj = { address, delivery_type: Order.DELIVERY_TYPE.delivery };
      return buildAddressString(obj);
    },
    setOrderAddress(address) {
      window.console.log(this.currentCity);

      this.$store.commit(SET_ORDER_FIELD, { field: "address", value: address });
      this.zoneAddress = this.order.address.formatted_address;
      if (this.isZone) {
        this.setSuggest(null, this.zoneAddress);
      }
    },
  },
  computed: {
    ...mapGetters(["order", "lastAddresses", "currentCity"]),
    ...mapFields({
      comment: "order.comment",
      firstName: "order.contacts.first_name",
      street: "order.address.street",
      house: "order.address.house",
      apartment: "order.address.apartment",
      porch: "order.address.porch",
      floor: "order.address.floor",
      door_code: "order.address.door_code",
      deliverAt: "order.deliver_at",
      table_number: "order.contacts.table_number",
      phone: "order.contacts.phone",
      formatted_address: "order.address.formatted_address",
      latitude: "order.address.latitude",
      longitude: "order.address.longitude",
    }),
    isDelivery() {
      return this.order.delivery_type === Order.DELIVERY_TYPE.delivery;
    },
    isStay() {
      return this.order.delivery_type === Order.DELIVERY_TYPE.stay;
    },
    departmentSlug() {
      return this.$store.getters.currentDepartment.slug;
    },
    getDisabledHours() {
      let disabledHours = [];
      for (let i = 0; i < 24; i++) {
        let item = i;
        if (item < new Date().getHours()) {
          if (item.toString().length === 1) item = `0${i}`;
          disabledHours.push(item.toString());
        }
      }
      return disabledHours;
    },
    readonly() {
      return this.order.status.is_confirmed;
    },
  },
  watch: {
    order() {
      if (this.order.contacts.phone) {
        if (this.order.contacts.phone.substr(0, 2) == "+7") {
          let cleaned = ("" + this.order.contacts.phone).replace(/\D/g, "");
          let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

          if (match) {
            this.rawPhone =
              "+7(" +
              match[2] +
              ")" +
              match[3] +
              "-" +
              match[4] +
              "-" +
              match[5];
          }
        } else {
          this.rawPhone = this.order.contacts.phone;
        }
      }
      if (this.order.address.formatted_address) {
        this.zoneAddress =
          this.order.address.street + ", " + this.order.address.house;
      }
    },
    rawPhone(newValue, oldValue) {
      if (this.rawPhone.substr(0, 2) === "+7") {
        if (this.rawPhone.length === 12 && newValue.length > oldValue.length) {
          let cleaned = ("" + this.rawPhone).replace(/\D/g, "");
          let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

          if (match) {
            this.rawPhone =
              "+7(" +
              match[2] +
              ")" +
              match[3] +
              "-" +
              match[4] +
              "-" +
              match[5];
          }
          return;
        }
        if (this.rawPhone.length === 2 && newValue.length > oldValue.length) {
          this.rawPhone += "(";
        }
        if (this.rawPhone.length === 6 && newValue.length > oldValue.length) {
          this.rawPhone += ")";
        }
        if (this.rawPhone.length === 10 && newValue.length > oldValue.length) {
          this.rawPhone += "-";
        }
        if (this.rawPhone.length === 13 && newValue.length > oldValue.length) {
          this.rawPhone += "-";
        }
        if (this.rawPhone.length > 16) {
          this.rawPhone = this.rawPhone.substr(0, 16);
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.auto {
  margin: 0 0 0 4px;
}
.comment {
  display: block !important;
  textarea {
    min-height: 6rem !important;
    overflow: auto;
  }
}
.date {
  background: #e6ecf6;
  border-radius: 15px;
  //overflow: hidden;
  margin: 0 0.25rem;
  padding: 0.25rem 15px;

  .label {
    font-size: 11px !important;
    color: #b8bec7;
    font-weight: 600 !important;
  }
  .inputs {
    label {
      left: 0;
      top: 6px;
    }
    // input {
    //   height: 4.25rem !important;
    // }
  }
  input {
    background-color: #e6ecf6 !important;
    border: none !important;
    padding: 0 !important;
  }
}
</style>

<style lang="sass">
@import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker'
#comment
  resize: none
.field-label
  display: none !important
</style>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"
.zone-address
  width: 100%
  position: relative
  .zone-error
    text-align: center
    color: red
    font-size: 13px
  .zone-suggest
    position: absolute
    top: 80px
    left: 0
    width: 100%
    background-color: #fff
    font-size: 13px
    padding: 16px
    font-weight: 700
    max-height: 400px
    overflow: auto
    border-radius: 16px
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1)
    &::-webkit-scrollbar
        width: 0.25rem
    &::-webkit-scrollbar-thumb
      background: $brand-color
      border-top-right-radius: 0.25rem
      border-bottom-right-radius: 0.25rem
    &::-webkit-scrollbar-track
      background: #fff
      border-top-right-radius: 0.25rem
      border-bottom-right-radius: 0.25rem
    div
      cursor: pointer
      margin: 0 0 8px 0

.order
    &__form
        &--padded
            padding-top: $vertical-padding-default

    &__phone-name
        display: flex

        & > .fancy-input
            flex-grow: 1
            flex-basis: 0

    &__address
        display: flex
        flex-wrap: wrap

        .fancy-input
            min-width: 50%

    &__to-time
        display: flex
        flex-direction: column

        &-label
            padding-left: 4px
            color: $secondary-text-color
            font-size: 14px

        &-inputs
            display: flex

    &__suggested-addresses
        li
            list-style: none
        &-row
            border-bottom: 1px dashed #333
            color: #333
            cursor: pointer
            padding-top: 1rem
            display: inline-block
            &:hover
                border-bottom: none

    &__additional-info
        display: flex
        align-items: center

        &-comment
            min-width: 50%
</style>
