<template>
  <div
    class="order__discounts-discount btn"
    :class="isCurrentOrderDiscount ? 'btn--primary' : 'btn--secondary'"
    @click="setOrderDiscount()"
  >
    {{ this.discount.percent }}%
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderDiscount",
  props: {
    discount: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setOrderDiscount() {
      let discount = 0;
      if (this.isCurrentOrderDiscount) {
        discount = 0;
      } else {
        discount = this.discount.percent;
      }
      let payload = {
        field: "payment_info.discount_percent",
        value: discount,
      };
      this.$store.dispatch("setOrderField", payload);
    },
  },
  computed: {
    ...mapGetters(["order"]),
    isCurrentOrderDiscount() {
      return this.order.payment_info.discount_percent === this.discount.percent;
    },
  },
};
</script>

<style lang="sass" scoped>
.order__discounts-discount
    padding: 10px 25px
</style>
