import { Model, API, APIFormData } from './index'

class Promo extends Model {
  async list(cityId) {
    let params = {
      city: cityId
    }
    let path = `/v1/promos/`
    const resp = await API.get(path, { params })
    return resp
  }

  async getOne(slug) {
    let citySlug = localStorage.getItem("citySlug")
    let path = `/v2/cities/${citySlug}/promos/${slug}/`
    const resp = await API.get(path)
    return resp
  }

  async create(payload) {
    let citySlug = localStorage.getItem("citySlug")
    let path = `/v2/cities/${citySlug}/promos/`
    const resp = await APIFormData.post(path, payload)
    return resp
  }

  async update(slug, payload) {
    let citySlug = localStorage.getItem("citySlug")
    let path = `/v2/cities/${citySlug}/promos/${slug}/`
    const resp = await API.patch(path, payload)
    return resp
  }

  async delete(slug) {
    let citySlug = localStorage.getItem("citySlug")
    let path = `/v2/cities/${citySlug}/promos/${slug}/`
    const resp = await API.delete(path)
    return resp
  }

  async addImage(slug, payload) {
    let path = `/v2/promos/${slug}/images/`
    const resp = await APIFormData.post(path, payload)
    return resp
  }
  async deleteImage(slug, id) {
    let path = `/v2/promos/${slug}/images/${id}/`
    const resp = await API.delete(path)
    return resp
  }
  async moveStory({city, slug, index}) {
    let path = `/v2/cities/${city}/promos/${slug}/move/`
    const resp = await API.post(path, { index })
    return resp
  }
}

export default new Promo()
