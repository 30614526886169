<template>
  <header class="header">
    <div class="wrapper">
      <div class="header-logo">
        <router-link :to="{ name: 'roles' }"
          ><img src="@/assets/img/logo.svg" alt="logo"
        /></router-link>
      </div>
      <OrdersLocationSelect />
    </div>
    <LogoutButton />
  </header>
</template>

<script>
import OrdersLocationSelect from "../common/OrdersLocationSelect";
import LogoutButton from "@/components/auth/LogoutButton";

export default {
  name: "PageHeader",
  components: { OrdersLocationSelect, LogoutButton },
};
</script>

<style scoped lang="sass">
.header
    padding: 36px 30px 30px 0
    display: flex
    justify-content: space-between

    .wrapper
        display: flex
        flex-flow: row nowrap
        align-items: center
        position: relative

    &-logo
        min-width: 37px

    &-location
        display: inline-block
        padding-left: 18px
        position: relative
        font-size: 14px
        line-height: 15px
        color: #424242

        &::after
            content: ''
            position: absolute
            bottom: 4px
            left: 0
            width: 11px
            height: 15px
            background: url('~@/assets/img/icons/location.svg') no-repeat center
            background-size: contain

    &-exit
        position: absolute
        top: 50%
        right: 45px
        transform: translate(0, -50%)
        display: block
        width: 22px
        height: 26px
        background: url('~@/assets/img/icons/logout.svg') no-repeat center
        background-size: contain
</style>
