<template>
  <div :class="$style.container">
    <template v-if="orders.length > 0 && orders.length <= 7">
      <div v-for="order in orders" :key="order.id">
        <OrdersListItem :order="order" :now="now" />
      </div>
    </template>
    <div
      :class="$style.moreThen"
      v-else-if="orders.length > 7 && orders.length <= 14"
    >
      <div>
        <div v-for="(order, index) in orders" :key="order.id">
          <div v-if="index <= 6">
            <OrdersListItem :order="order" :now="now" />
          </div>
        </div>
      </div>
      <div>
        <div v-for="(order, index) in orders" :key="order.id">
          <div v-if="index >= 7">
            <OrdersListItem :order="order" :now="now" />
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.moreThen" v-else-if="orders.length > 14">
      <div>
        <div v-for="(order, index) in orders" :key="order.id">
          <div v-if="index <= 6">
            <OrdersListItem :order="order" :now="now" />
          </div>
        </div>
      </div>
      <div>
        <div v-for="(order, index) in orders" :key="order.id">
          <div v-if="index >= 7 && index <= 13">
            <OrdersListItem :order="order" :now="now" />
          </div>
        </div>
      </div>
      <div>
        <div v-for="(order, index) in orders" :key="order.id">
          <div v-if="index > 13">
            <OrdersListItem :order="order" :now="now" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersListItem from "./OrdersListItem";
export default {
  name: "OrdersList",
  components: {
    OrdersListItem,
  },
  props: {
    orders: {
      type: Array,
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      now: Date.now(),
    };
  },

  created() {
    setInterval(() => {
      this.now = Date.now();
    }, 15000);
  },
};
</script>

<style lang="scss" module>
.container {
  text-align: center;
  padding: 2rem 0 0 0;
  font-size: 7.5vh;
  .moreThen {
    display: flex;
    justify-content: center;
    gap: 10rem;
  }
}
</style>
