import { Model, API } from './index'

class Department extends Model {
    path = '/v1/departments';

    get MODULES() {
        return {
            cooking: '1',
            packing: '2',
            delivery: '3',
            restaurantOrderStatusScreen: '4',
            orderCreation: '5',
            dailySummary: '6',
            extendedAnalytics: '7',
            blacklist: '8',
            privateComments: '9',
            orderEditing: '10',
            receipts: '11',
            orderCancellation: '12'
        }
    }

    list(cityId, takeAwayOnly) {
        let params = {
            city: cityId,
        };
        if (takeAwayOnly) {
            params['has_takeaway_service'] = takeAwayOnly
        }

        return this._list(params)
    }

    getOne(slug) {
        return API.get(`${this.path}/${slug}/`).then(response => {
            return response.data
        })
    }
}

export default new Department()
