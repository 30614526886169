import {Model, API} from './index'

class City extends Model {
    path = '/v1/cities'

    async patch (slug, payload) {
        const path = `${this.path}/${slug}/`
        const resp = await API.patch(path, payload)
        return resp.data
    }

    async get (slug) {
        const path = `${this.path}/${slug}/`
        const resp = await API.get(path)
        return resp.data
    }
}

export default new City()
