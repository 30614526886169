<template>
  <div class="order__finalizing-buttons">
    <button
      class="btn btn--danger order__finalizing-buttons-button"
      @click="cancelOrder"
    >
      <span v-if="order.status.status === Order.STATUS.cart">Отмена</span>
      <span v-else>Отказ</span>
    </button>
    <!-- <img
      src="@/assets/img/loader-pacman.svg"
      alt=""
      class="order__finalizing-buttons-loader"
      :style="loadingForm ? 'opacity: 1' : 'opacity: 0'"
    /> -->
    <button
      class="btn btn--primary order__finalizing-buttons-button"
      :class="{
        'save-btn-loading': isLoading,
        readonly: order.status.status === 'done',
      }"
      @click="saveOrder"
    >
      <span>Сохранить</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Order from "@/api/order";
import printReceipt from "@/utils/receipt";
import Department from "@/api/department";
import {
  SET_ORDER_FIELD,
  SET_SAVE_LOADING,
} from "@/store/modules/order-form/mutation-types";

export default {
  name: "OrderSaveCancelButtons",
  data() {
    return {
      Order: Order,
      modules: Department.MODULES,
      isLoading: false,
    };
  },
  methods: {
    async saveOrder() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
      if (this.order.status.is_confirmed) {
        this.$router.push({ name: "operator" });
        return;
      }
      if (
        !this.order.department &&
        this.order.status.is_confirmed &&
        (this.order.source === "call_center" || this.order.source === "site")
      ) {
        let payload = {
          field: "department",
          value: this.$store.getters.user.department.slug,
          orderId: this.order.id,
          isCheck: true,
        };
        await this.$store.dispatch("setOrderField", payload);
        this.$router.push({ name: "operator" });
        return;
      }
      this.$store.commit("setIsRestError", false);
      let price = 0;
      price = this.order.payment_info.total_price;
      if (
        this.order.payment_info.rest_from != "0.00" &&
        Number(this.order.payment_info.rest_from) < price
      ) {
        this.$store.commit("setIsRestError", true);
      } else {
        let checkout = false;
        if (this.order.status.status === Order.STATUS.cart) {
          checkout = true;
        }

        const success = await this.$store.dispatch("saveOrder", {
          confirm: true,
          checkout,
          isSave: true,
        });
        if (!success) {
          this.$store.commit(SET_ORDER_FIELD, {
            field: "status.is_confirmed",
            value: false,
          });
        }

        const department = await Department.getOne(
          this.$store.getters.user.department.slug
        );
        if (
          success &&
          this.hasModule(this.modules.receipts) &&
          this.printReceipt
        ) {
          printReceipt({
            order: this.order,
            router: this.$router,
            store: this.$store,
          });
        } else if (
          success &&
          this.hasModule(this.modules.receipts) &&
          !this.printReceipt &&
          department.print_a_slip_check_anyway
        ) {
          printReceipt({
            order: this.order,
            router: this.$router,
            store: this.$store,
            isNotFiscal: true,
          });
        } else if (success) {
          this.$router.push({ name: "operator" });
          this.$store.commit(SET_SAVE_LOADING, false);
        }
      }
    },
    cancelOrder() {
      if (this.order.status.status === Order.STATUS.cart) {
        this.$router.push({ name: "operator" });
      } else {
        this.$router.push({
          name: "order-cancel-page",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
  computed: {
    ...mapGetters(["order", "loadingForm", "hasModule", "printReceipt"]),
  },
};
</script>

<style lang="sass" scoped>
.save-btn-loading
  opacity: 0.7
  pointer-events: none
.order__finalizing-buttons
    display: flex
    justify-content: space-between

    &-button
        padding: 10px 25px

    &-loader
        width: 50px
</style>
