import { render, staticRenderFns } from "./SettingsTabs.vue?vue&type=template&id=9df89cac&scoped=true&"
import script from "./SettingsTabs.vue?vue&type=script&lang=js&"
export * from "./SettingsTabs.vue?vue&type=script&lang=js&"
import style0 from "./SettingsTabs.vue?vue&type=style&index=0&id=9df89cac&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9df89cac",
  null
  
)

export default component.exports