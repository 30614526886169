<template>
  <div>
    <div class="alert alert-danger" v-if="error">{{ error }}</div>
    <form @submit="onSubmit" :class="{ 'is-loading': isLoading }">
      <div class="field">
        <label>Номер телефона</label>
        <input
          v-model="user.username"
          :placeholder="currentCountry.phone_code"
          type="text"
        />
      </div>

      <div class="field">
        <label>Пароль</label>
        <input v-model="user.password" type="text" />
      </div>

      <div class="field field-checklist">
        <label>Должность</label>
        <div class="checkbox">
          <input type="checkbox" value="courier" v-model="user.groups" />
          <span></span>
          <p>Курьер</p>
        </div>
        <div class="checkbox">
          <input type="checkbox" value="cook" v-model="user.groups" />
          <span></span>
          <p>Повар</p>
        </div>
        <div class="checkbox">
          <input type="checkbox" value="packer" v-model="user.groups" />
          <span></span>
          <p>Упаковщик</p>
        </div>
        <div class="checkbox">
          <input type="checkbox" value="operator" v-model="user.groups" />
          <span></span>
          <p>Оператор</p>
        </div>
      </div>

      <div class="field">
        <label>Имя</label>
        <input
          v-model="user.first_name"
          type="text"
          :class="{ [$style.error]: errors.first_name }"
          @focus="errors.first_name = false"
        />
      </div>

      <div class="field">
        <label>Фамилия</label>
        <input
          v-model="user.last_name"
          type="text"
          :class="{ [$style.error]: errors.last_name }"
          @focus="errors.last_name = false"
        />
      </div>

      <div class="field">
        <input type="submit" value="Сохранить" class="btn btn--primary" />
      </div>
    </form>
  </div>
</template>

<script>
import User from "@/api/user";
import { mapGetters } from "vuex";

export default {
  name: "UserForm",
  props: {
    userData: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      errors: {
        first_name: false,
        last_name: false,
      },
      user: {
        username: null,
        first_name: null,
        last_name: null,
        password: null,
        groups: ["courier"],
        department: this.$store.getters.currentDepartment.slug,
      },
    };
  },
  async mounted() {
    if (this.userData) {
      Object.keys(this.user).forEach((field) => {
        this.user[field] = this.userData[field];
      });
    }
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      if (!this.user.first_name) {
        this.errors.first_name = true;
        return;
      }
      if (!this.user.last_name) {
        this.errors.last_name = true;
        return;
      }
      this.isLoading = true;
      this.error = null;
      if (this.userData) {
        let slug = this.user.department.slug;
        this.user.department = slug;
        const response = await User.update(
          this.$store.getters.currentDepartment.slug,
          this.$route.params.id,
          this.user
        );
        if (response.type === "ValidationError") {
          this.error = response.fallback_message;
        } else {
          await this.$router.go(-1);
        }
      } else {
        const resp = await User.create(
          this.$store.getters.currentDepartment.slug,
          this.user
        );
        if (resp.status === 201) {
          await this.$router.push({ name: "users-list" });
        } else if (resp.status === 400) {
          this.error = resp.data.fallback_message;
        } else {
          this.error = "Unhandled error";
        }
      }
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["currentCountry"]),
    username: {
      get() {
        return this.user.username || this.currentCountry.phone_code;
      },
      set(value) {
        this.user.username = value;
      },
    },
  },
};
</script>

<style scoped lang="sass">
.field
  &-checklist
    display: flex
    flex-direction: column
  label
    font-weight: bold
    margin-bottom: 0.1rem
  padding: 0.7rem 0
</style>

<style lang="sass" module>
.error
  border: 1px solid red
</style>
