<template>
  <div v-if="isLoading">loading...</div>
  <div v-else>
    <span>Кол-во доставленных заказов (всего / наличка):</span>
    <div
      v-for="courier in analytics.couriers"
      :key="`courier-stat-${courier.courier.id}`"
    >
      <div>
        {{ courier.courier.first_name }} {{ courier.courier.last_name }}:
        <strong class="contrast">
          {{ sumCourierOrders(courier) }} / {{ courier.cash_amount | money }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CouriersStat",
  props: {
    analytics: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
  },
  methods: {
    sumCourierOrders(courier) {
      return courier.pos_count + courier.online_count + courier.cash_count;
    },
  },
};
</script>
