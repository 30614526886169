import Vue from 'vue'
import Vuex from 'vuex'
import location from './modules/location'
import auth from './modules/auth'
import orderList from './modules/orders-list'
import orderForm from './modules/order-form'
import orderUser from './modules/order-user'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {location, auth, orderList, orderForm, orderUser},
    strict: process.env.NODE_ENV !== 'production'
})