import {Model} from './index'

class Banknote extends Model {
    path = '/v1/banknotes';

    list(countryId) {
        let params = {
            country: countryId,
        };
        return this._list(params)
    }
}

export default new Banknote()
