<template>
  <div
    class="category-items__item"
    :class="{ readonly: !isShow || order.is_receipt_printed }"
    :style="'background:' + category.dashboard_bg"
    @click="add"
  >
    {{ item.title }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["addItemToOrder"]),
    add() {
      let isMin = false;
      this.item.modifier_groups.forEach((i) => {
        if (i.min_amount > 0) {
          isMin = true;
          this.$store.commit("setToppingsModal", {
            isOpen: true,
            item: this.item,
            type: "post",
          });
        }
      });
      if (!isMin) {
        this.addItemToOrder({
          itemId: this.item.slug,
          modifier_groups: [],
          item: this.item,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["category", "isPaid", "order"]),
    isShow() {
      let result = true;

      for (let i = 0; i < this.order.items.length; i++) {
        if (this.item.slug === this.order.items[i].slug) {
          if (this.order.items[i].qty === 100) {
            result = false;
          } else {
            result = true;
          }
        }
      }

      if (this.isPaid) {
        this.item.price == 0 ? (result = true) : (result = false);
      }

      return result;
    },
  },
};
</script>

<style lang="sass" scoped>
.category-items__item
    background: lightgrey
    margin: 0 3px 6px 3px
    padding: 15px 2px
    text-align: center
    border-radius: 5px
    color: #fff
    cursor: pointer
    font-weight: bold
    width: calc(50% - 6px)
</style>
