<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-3">
            <div class="tab-wrap">
              <settings-tabs />
              <div class="wrap-local">
                <div class="main-block">
                  <div
                    v-if="schedule"
                    class="schedule-wrapper"
                    :class="{ 'is-loading': isLoading }"
                  >
                    <div
                      v-for="day in schedule"
                      :key="day.weekday"
                      class="schedule-day"
                    >
                      <div class="schedule-day__weekday">
                        {{ getVerboseWeekday(day.weekday) }}
                      </div>
                      <div class="schedule-day__field fancy-input">
                        <label>Открытие</label>
                        <input type="text" v-model="day.open_from" />
                      </div>
                      <div class="schedule-day__field fancy-input">
                        <label>Закрытие</label>
                        <input type="text" v-model="day.open_to" />
                      </div>
                      <div class="schedule-day__field fancy-input">
                        <label>Прием заказов до</label>
                        <input type="text" v-model="day.accept_orders_to" />
                      </div>
                    </div>
                    <button
                      @click="onSubmit"
                      class="btn btn--primary btn--size-default"
                    >
                      Сохранить
                    </button>
                    <div class="switch-field">
                      <span class="label">Прием заказов</span>
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="city.is_accepting_orders"
                          :true-value="true"
                          :false-value="false"
                          :disabled="isLoading"
                          @change="
                            toggleCityAcceptingOrders(
                              city.slug,
                              !city.is_accepting_orders
                            )
                          "
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="switch-field">
                      <span class="label">Высокая загруженность</span>
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="city.is_high_workload"
                          :true-value="true"
                          :false-value="false"
                          :disabled="isLoading"
                          @change="
                            toggleCityHighWorkload(
                              city.slug,
                              !city.is_high_workload
                            )
                          "
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import City from "@/api/city";
import SettingsTabs from "@/components/SettingsTabs";

export default {
  name: "SchedulePage",
  components: { SettingsTabs, PageTabs, PageHeader },
  data() {
    return {
      schedule: null,
      city: null,
      isLoading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.city = await City.get(localStorage.getItem("citySlug"));
      this.schedule = JSON.parse(JSON.stringify(this.city.business_hours));
    },
    getVerboseWeekday(weekday) {
      const days = [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье",
      ];
      return days[weekday];
    },
    async onSubmit() {
      this.isLoading = true;
      const city = await City.patch(localStorage.getItem("citySlug"), {
        business_hours: this.schedule,
      });
      this.schedule = city.business_hours;
      this.isLoading = false;
    },
    async toggleCityAcceptingOrders(citySlug, value) {
      this.isLoading = true;
      this.city = await City.patch(citySlug, { is_accepting_orders: value });
      this.isLoading = false;
    },
    async toggleCityHighWorkload(citySlug, value) {
      this.isLoading = true;
      this.city = await City.patch(citySlug, { is_high_workload: value });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="sass">
.schedule-day
  display: flex
  padding: 1rem 0
  border-bottom: 1px dashed rgba(128, 128, 128, 0.11)
  &__weekday
    min-width: 140px
    font-weight: bold
  &__field
    padding: 0 0.7rem

.switch-field
  margin-top: 2rem
</style>
