var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order__container"},[(_vm.isModalOpen)?_c('div',{staticClass:"modal-window",on:{"click":function($event){return _vm.toggleModal(false)}}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveComment.apply(null, arguments)},"click":function($event){$event.stopPropagation();}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/close.svg"),"alt":""},on:{"click":function($event){return _vm.toggleModal(false)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],attrs:{"placeholder":"Введите комментарий"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}}),_c('button',{on:{"click":_vm.saveComment}},[_vm._v("Сохранить")])])]):_vm._e(),_c('div',{staticClass:"order__item"},[_c('div',{staticClass:"order__item-title"},[_vm._v(" "+_vm._s(_vm.item.title)+" "),(!_vm.item.added_by_promo_code)?_c('div',[_c('img',{attrs:{"src":require("@/assets/img/icons/comment.png"),"alt":""},on:{"click":function($event){return _vm.toggleModal(true)}}})]):_vm._e()]),_c('div',{staticClass:"order__item-qty",class:{ readonly: _vm.isPaid || _vm.order.is_receipt_printed }},[_c('span',{class:{
          readonly:
            _vm.order.is_receipt_printed ||
            _vm.item.qty === 1 ||
            (_vm.order.status.status === 'open' &&
              _vm.item.price_per_item != 0 &&
              !_vm.department.can_full_edit_confirmed_orders),
        },on:{"click":function($event){return _vm.changeQty('decrement', 1)}}},[_c('svg-icon',{staticClass:"order__item-qty-icon",attrs:{"name":"minus"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.qty),expression:"item.qty"}],staticClass:"order__item-qty-number",class:_vm.$style.inputNumber,attrs:{"type":"number"},domProps:{"value":(_vm.item.qty)},on:{"keypress":function($event){return _vm.typ($event)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.item, "qty", $event.target.value)},function($event){return _vm.changeValueQty($event, _vm.item.qty)}],"change":_vm.changeQty}}),_c('span',{class:{ readonly: _vm.item.qty === 100 },on:{"click":function($event){return _vm.changeQty('increment', 1)}}},[_c('svg-icon',{staticClass:"order__item-qty-icon",attrs:{"name":"plus"}})],1)]),_c('div',{staticClass:"order__item-price",class:{ readonly: _vm.isPaid }},[_vm._v(" "+_vm._s(_vm.order.source === "aggregator" ? _vm.item.aggregator_price_per_item : _vm.item.price_per_item)+" ")]),_c('div',{staticClass:"order__item-actions",class:{
        readonly:
          _vm.isPaid ||
          _vm.order.is_receipt_printed ||
          (_vm.order.status.status === 'open' &&
            _vm.item.price_per_item != 0 &&
            !_vm.department.can_full_edit_confirmed_orders),
      }},[_c('div',{on:{"click":function($event){return _vm.remove(_vm.item.id)}}},[_c('svg-icon',{staticClass:"order__item-qty-icon",attrs:{"name":"remove"}})],1)])]),(_vm.item.comment)?_c('div',{staticClass:"order__item-toppings-add"},[_vm._v(" "+_vm._s(_vm.item.comment)+" ")]):_vm._e(),(_vm.item.modifier_groups.length > 0)?_c('div',{style:({
      opacity: '0.5',
      fontSize: '13px',
      margin: '8px 0 0 0',
    })},_vm._l((_vm.item.modifier_groups),function(i){return _c('div',{key:i.id},[_vm._v(" "+_vm._s(i.title)+": "),_vm._l((i.modifiers),function(j,index){return _c('span',{key:j.id},[_vm._v(" "+_vm._s(j.title + " " + j.qty + "X")+_vm._s(index !== i.modifiers.length - 1 ? "," : ".")+" ")])})],2)}),0):_vm._e(),(_vm.item.are_exists_modifier_groups)?_c('div',{staticClass:"open-toppings",on:{"click":function($event){return _vm.$store.commit('setToppingsModal', {
        isOpen: true,
        item: _vm.item,
        type: 'patch',
      })}}},[_vm._v(" "+_vm._s(_vm.item.modifier_groups.length > 0 ? "Изменить" : "Добавить")+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }