<template>
  <modal
    name="orderLocation"
    @before-open="beforeOpen"
    width="800px"
    height="500px"
  >
    <div v-if="lon && lat">
      <GmapMap
        :center="{ lat, lng: lon }"
        :zoom="16"
        style="width: 800px; height: 500px"
      >
        <GmapMarker :position="{ lat, lng: lon }" />
      </GmapMap>
    </div>
    <div class="errorGetting" v-else>
      К сожалению координаты курьера неизвестны :(
    </div>
  </modal>
</template>

<script>
import Order from "@/api/order";

export default {
  name: "ModalOrderLocation",
  data() {
    return {
      orderId: null,
      lat: null,
      lon: null,
      isLoading: false,
      interval: "",
    };
  },
  methods: {
    async beforeOpen(event) {
      window.console.log(event.params.orderId);
      this.orderId = event.params.orderId;
      await this.loadData();
    },
    async loadData() {
      const location = await Order.getLocation(this.orderId);

      if (
        location.fallback_message ===
        "Вы можете получить локацию заказа только если он ещё не выполнен"
      ) {
        this.$modal.hide("orderLocation");
      }
      if (location.latitude && location.longitude) {
        this.lat = location.latitude;
        this.lon = location.longitude;
      } else {
        this.lat = null;
        this.lon = null;
        clearInterval(this.interval);
        return;
      }

      this.interval = setInterval(async () => {
        const location = await Order.getLocation(this.orderId);
        if (
          location.fallback_message ===
          "Вы можете получить локацию заказа только если он ещё не выполнен"
        ) {
          this.$modal.hide("orderLocation");
        }
        if (location.latitude && location.longitude) {
          this.lat = location.latitude;
          this.lon = location.longitude;
        } else {
          this.lat = null;
          this.lon = null;
          clearInterval(this.interval);
          return;
        }
      }, 15000);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.errorGetting {
  text-align: center;
  padding: 4rem 0 0 0;
}
</style>
