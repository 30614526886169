<template>
  <div
    class="order__delivery-types-item"
    :class="{ 'order__delivery-types-item--active': isActive }"
    @click="setOrderDeliveryType()"
  >
    <img :src="iconPath" alt="" /> {{ deliveryType.title }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_ORDER_FIELD } from "@/store/modules/order-form/mutation-types";

export default {
  name: "OrderDeliveryTypeItem",
  props: {
    deliveryType: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setOrderDeliveryType() {
      this.$store.commit("resetFields");
      let payload = {
        field: "delivery_type",
        value: this.deliveryType.id,
      };
      this.$store.commit(SET_ORDER_FIELD, payload);
    },
  },
  computed: {
    ...mapGetters(["order"]),
    isActive() {
      return this.deliveryType.id === this.order.delivery_type;
    },
    iconPath() {
      if (this.isActive) {
        return require(`@/assets/img/icons/${this.deliveryType.iconName}--no-circle--red.svg`);
      } else {
        return require(`@/assets/img/icons/${this.deliveryType.iconName}--no-circle.svg`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.order__delivery-types-item
    flex-grow: 1
    flex-basis: 0
    padding: 10px
    color: $blue-dark
    border-bottom: 2px solid $blue-dark
    cursor: pointer
    font-weight: bold

    &--active
        color: $brand-color
        border-bottom-color: $brand-color

    &:first-child
        margin-right: $horizontal-siblings-padding
    &:last-child
        margin-left: $horizontal-siblings-padding

    & > img
        height: 35px
        margin-right: 16px
</style>
