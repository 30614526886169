var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible && _vm.isItemsHas)?_c('div',{staticClass:"order-display__col",on:{"click":_vm.markStepAsDone}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"loader-wheel"}),_c('div',{staticClass:"order-display__card card",class:{
      'card--delayed': _vm.isDelayed,
      'card--warning': _vm.isLate,
      'card--success': !_vm.isLate,
    }},[_c('div',{staticClass:"card__content",class:[{ 'card__content--loading': _vm.isLoading }]},[_c('div',{staticClass:"order-display__card-body card__body"},[_c('div',{staticClass:"order-display__list"},[_vm._l((_vm.order.items),function(item){return _c('div',{key:item.id,staticClass:"order-display__list-item"},[_c('div',{staticClass:"order-display__list-text"},[_c('div',{class:{ [_vm.$style.done]: item.is_cooked }},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.modifier_groups.length > 0)?_c('div',{style:({
                  opacity: '0.5',
                  fontSize: '12px',
                })},_vm._l((item.modifier_groups),function(i){return _c('div',{key:i.id},[_vm._v(" "+_vm._s(i.title)+": "),_vm._l((i.modifiers),function(j,index){return _c('span',{key:j.id},[_vm._v(" "+_vm._s(j.title + " " + j.qty + "X")+_vm._s(index !== i.modifiers.length - 1 ? "," : ".")+" ")])})],2)}),0):_vm._e(),(item.comment)?_c('div',{style:({
                  fontSize: '12px',
                })},[_vm._v(" Комментарий: "+_vm._s(item.comment)+" ")]):_vm._e()]),(item.qty > 1)?_c('div',{staticClass:"order-display__list-count order-display__list-count--highlighted"},[_vm._v(" x "+_vm._s(item.qty)+" ")]):_vm._e()])}),_c('div',{staticClass:"order-display__list-item"},[_c('div',{staticClass:"order-display__list-comment"},[_vm._v(_vm._s(_vm.order.comment))])]),(_vm.order.contacts.table_number)?_c('div',{staticClass:"order-display__list-item"},[_c('div',{staticClass:"order-display__list-comment"},[_vm._v(" Номер стола: "+_vm._s(_vm.order.contacts.table_number)+" ")])]):_vm._e()],2)]),_c('div',{staticClass:"order-display__card-footer card__footer"},[_c('div',{staticClass:"order-display__card-row order-display__card-row--footer"},[_c('div',{staticClass:"order-display__card-col col"},[_c('div',{staticClass:"order-display__order"},[_c('div',{staticClass:"order-display__order-number"},[_vm._v(" "+_vm._s(_vm.order.number)+" ")]),_c('div',{staticClass:"order-display__order-type"},[_c('img',{staticClass:"order-display__order-icon",attrs:{"src":_vm.deliveryIcon,"widht":"24","height":"24","alt":""}})])])]),_c('div',{staticClass:"order-display__card-col col"},[_c('div',{staticClass:"order-display__counter"},[_vm._v(_vm._s(_vm.time))])])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }