<template>
  <div class="main-col col-50 main-col__small">
    <div class="main-col__row">
      <div class="row row-no">
        <div class="main-col__row-text">
          <p>
            Выполнено заказов сегодня:<b>{{
              summaryToday.done_count || "0"
            }}</b>
          </p>
          <p class="color-red">
            Отказов сегодня:<b>{{ summaryToday.canceled_count || "0" }}</b>
          </p>
        </div>
        <div class="main-col__row-list">
          <div class="main-col__pay">
            <div
              class="main-col__pay-row"
              :style="barStyle(summaryToday.cash_amount)"
            >
              <div class="main-col__pay-wrap">
                <div>
                  <svg-icon name="payment-cash"></svg-icon>
                </div>
                <span>{{ summaryToday.cash_count || "0" }}</span>
              </div>
            </div>
            <p class="summary-today">
              {{ summaryToday.cash_amount || "0" }} {{ currencySymbol }}
            </p>
          </div>
          <div class="main-col__pay">
            <div
              class="main-col__pay-row"
              :style="barStyle(summaryToday.online_amount)"
            >
              <div class="main-col__pay-wrap">
                <div>
                  <svg-icon name="payment-online"></svg-icon>
                </div>
                <span>{{ summaryToday.online_count || "0" }}</span>
              </div>
            </div>
            <p class="summary-today">
              {{ summaryToday.online_amount || "0" }} {{ currencySymbol }}
            </p>
          </div>
          <div class="main-col__pay">
            <div
              class="main-col__pay-row"
              :style="barStyle(summaryToday.pos_amount)"
            >
              <div class="main-col__pay-wrap">
                <div>
                  <svg-icon name="payment-pos"></svg-icon>
                </div>
                <span>{{ summaryToday.pos_count || "0" }}</span>
              </div>
            </div>
            <p class="summary-today">
              {{ summaryToday.pos_amount || "0" }} {{ currencySymbol }}
            </p>
          </div>
          <div class="main-col__pay">
            <div
              class="main-col__pay-row"
              :style="barStyle(summaryToday.points_used_amount)"
            >
              <div class="main-col__pay-wrap">
                <div>
                  <svg-icon name="points-coins"></svg-icon>
                </div>
                <span>{{ summaryToday.points_used_count || "0" }}</span>
              </div>
            </div>
            <p class="summary-today">
              {{ summaryToday.points_used_amount || "0" }} {{ currencySymbol }}
            </p>
          </div>
          <div class="main-col__pay">
            <div
              class="main-col__pay-row"
              :style="barStyle(summaryToday.discount_amount)"
            >
              <div class="main-col__pay-wrap">
                <div>
                  <svg-icon name="coupon"></svg-icon>
                </div>
                <span>{{ summaryToday.discount_count || "0" }}</span>
              </div>
            </div>
            <p class="summary-today">
              {{ summaryToday.discount_amount || "0" }} {{ currencySymbol }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import Auth from "@/store/modules/auth/index";

export default {
  name: "PaymentTypeBlock",
  components: { SvgIcon },
  props: {
    summaryToday: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencySymbol: "",
    };
  },
  mounted() {
    this.currencySymbol = Auth.state.user.country.currency_symbol;
  },
  methods: {
    barStyle(value) {
      return `width: ${((value / this.totalToday) * 100).toFixed(0)}%`;
    },
  },
  computed: {
    totalToday() {
      return (
        parseFloat(this.summaryToday.cash_amount) +
        parseFloat(this.summaryToday.pos_amount) +
        parseFloat(this.summaryToday.online_amount)
      );
    },
  },
};
</script>

<style scoped>
.main-col__pay {
  position: relative;
}

.summary-today {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
