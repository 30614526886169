<template>
  <div class="page-wrapper">
    <header class="header">
      <div class="header__top">
        <div>
          <!-- left -->
          <div class="header__logo-location">
            <router-link :to="{ name: 'roles' }"
              ><img src="@/assets/img/logo.svg" alt="logo"
            /></router-link>
            <OrdersLocationSelect />
            <div class="header__datepicker"><OrdersDateSelect /></div>
            <select :class="$style.select" v-model="selectValue">
              <option value="all">Все заказы</option>
              <option value="takeaway">Только самовывоз</option>
              <option value="delivery">Только доставка</option>
              <option value="stay">Только зал</option>
              <option value="aggregator">Только из агрегаторов</option>
              <option value="!aggregator">Только без агрегаторов</option>
              <option value="canceled">Только отмененные</option>
              <option value="discount_percent">Только со скидкой</option>
              <option value="only_for_time">Только ко времени</option>
              <option value="!only_for_time">Только текущие</option>
            </select>
          </div>
        </div>
        <div>
          <!-- right -->
          <LogoutButton />
        </div>
      </div>
      <div class="header__bottom">
        <div class="header__stats">
          <!-- left -->
          <OrdersStats
            :analytics="analytics"
            :isLoading="isLoading"
            v-if="hasModule(modules.dailySummary) && analytics"
          />
        </div>
      </div>
    </header>

    <NoRealtimeAlert :class="{ deleteZIndex: getLoading }" />
    <OrdersList :orders-filter="ordersFilter" />
    <CouriersStat
      :analytics="analytics"
      :isLoading="isLoading"
      v-if="
        hasModule(modules.dailySummary) &&
        hasModule(modules.delivery) &&
        analytics
      "
    />
    <ModalBlacklist />
    <ModalPrivateComment />
    <ModalOrderLocation />
    <ChangeDelieveryType />
    <notifications position="bottom right" />
    <div class="wrapper" :class="{ pageWrapperLoading: getLoading }"></div>
  </div>
</template>

<script>
import OrdersList from "@/components/management/OrdersList";
import OrdersLocationSelect from "../common/OrdersLocationSelect";
import OrdersDateSelect from "@/components/management/OrdersDateSelect";
import OrdersStats from "@/components/management/OrdersStats";
import LogoutButton from "../auth/LogoutButton";
import ModalBlacklist from "@/components/management/ModalBlacklist";
import ModalPrivateComment from "@/components/management/ModalPrivateComment";
import ModalOrderLocation from "@/components/management/ModalOrderLocation";
import NoRealtimeAlert from "@/components/orders-list/NoRealtimeAlert";
import CouriersStat from "@/components/management/CouriersStat";
import { mapGetters } from "vuex";
import Department from "@/api/department";
import DetailedLocationRequired from "@/mixins/DetailedLocationRequired";
import Analytics from "@/api/analytics";
import { SET_STATS } from "@/store/modules/orders-list/mutation-types";
import ChangeDelieveryType from "@/components/ChangeDelieveryType.vue";

export default {
  name: "OrdersListDatePage",
  mixins: [DetailedLocationRequired],
  components: {
    CouriersStat,
    NoRealtimeAlert,
    ModalOrderLocation,
    ModalPrivateComment,
    ModalBlacklist,
    OrdersList,
    OrdersLocationSelect,
    OrdersDateSelect,
    OrdersStats,
    LogoutButton,
    ChangeDelieveryType,
  },
  data() {
    return {
      modules: Department.MODULES,
      selectValue: "all",
      isLoading: false,
      isNotUpdate: true,
    };
  },
  created() {
    localStorage.removeItem("filter");
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const stats = await Analytics.dailyOrdersSummary({
        date: this.date,
        departmentSlug: this.$store.getters.currentDepartment.slug,
        filter: this.ordersFilter,
      });
      this.$store.commit(SET_STATS, { stats });
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["hasModule", "getLoading"]),
    ordersFilter() {
      let filter = {
        department: this.$store.getters.currentDepartment.slug,
        date: this.$route.params.date,
      };
      if (
        this.selectValue === "takeaway" ||
        this.selectValue === "delivery" ||
        this.selectValue === "stay"
      ) {
        filter.delivery_type = this.selectValue;
      } else if (this.selectValue === "canceled") {
        filter.status = this.selectValue;
      } else if (
        this.selectValue === "aggregator" ||
        this.selectValue === "!aggregator"
      ) {
        filter.source = this.selectValue;
      } else if (this.selectValue === "discount_percent") {
        filter.discount_percent = "!0";
      } else if (
        this.selectValue === "only_for_time" ||
        this.selectValue === "!only_for_time"
      ) {
        filter.only_for_time = this.selectValue;
      }

      return filter;
    },
    date() {
      let now = new Date();
      return (
        this.$route.params.date ||
        `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
      );
    },
    analytics() {
      return this.$store.getters.ordersStats;
    },
  },
  watch: {
    date() {
      this.loadData();
    },
    ordersFilter() {
      this.loadData();
    },
  },
};
</script>

<style lang="sass">
@import "../../assets/sass/variables"

.contrast
    color: $contrast-color
</style>

<style lang="sass" scoped>
@import "../../assets/sass/variables"

.page-wrapper
    padding: 0 20px
    background: $page-bg

.header
    display: flex
    justify-content: space-between
    flex-direction: column
    padding: 40px 0
    &__top, &__bottom
        display: flex
        justify-content: space-between
        align-items: center
    &__top
        padding-bottom: 30px
    &__stats
        display: flex
        align-items: center
    &__datepicker
        padding-left: 30px
    &__logo-location
        display: flex
        align-items: center
</style>

<style lang="scss">
.pageWrapperLoading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 50 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;

  .loading {
    position: fixed;
  }
}
</style>

<style lang="sass" module>
.select
    width: 13rem
    font-size: 1rem
    margin: 0 0 0 2rem
    padding: 0 0 0 1rem
    height: 2.25rem
    color: #000
    border: 1px solid rgba(0, 0, 0, 0.5)
</style>
