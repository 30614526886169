<template>
  <div class="alert--norealtime">
    <p>
      Обновление списка заказов приостановлено.
      <router-link :to="{ name: 'operator' }">Нажмите сюда</router-link>, чтобы
      продолжить следить за новыми заказами.
    </p>
    <p>
      Статистика показана за смену, которая началась с указаной даты. Список
      заказов составлен из заказов, которые либо оформлены, либо
      выполнены/доставлены в указаную дату.
    </p>
  </div>
</template>

<script>
export default {
  name: "NoRealtimeAlert",
};
</script>

<style scoped lang="sass">
.alert--norealtime
    text-align: center
    padding: 20px 0
    border: 1px solid
    margin: 30px 0
    border-radius: 5px
    background-color: #fcf8e3
    border-color: #faebcc
    color: #8a6d3b
    a
        color: #66512c
        text-decoration: underline
        &:hover
            text-decoration: none
</style>
