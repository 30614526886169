<template>
  <div class="main-block">
    <div class="main-row">
      <div class="col-25 col-dh">
        <span class="main-col__subtitle">Работа доставки:</span>
        <ul>
          <li>
            Доля просроченных заказов:<b>
              {{ lateOrdersPercent || "0" | percent }}% ({{
                lateOrdersCount || "0"
              }})</b
            >
          </li>
          <li>
            Курьеры в смене:<b> {{ couriersCount }}</b>
          </li>
        </ul>
      </div>
      <div class="col-25 col-dh">
        <span class="main-col__subtitle">Кол-во доставленных заказов:</span>
        <ul>
          <li v-for="courier in summaryToday.couriers" :key="courier.id">
            {{ courier.courier.first_name }}:
            <b
              >{{ sumCourierOrders(courier) }} /
              {{ courier.cash_amount | money }}</b
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryPerformanceBlock",
  props: {
    summaryToday: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sumCourierOrders(courier) {
      return courier.pos_count + courier.online_count + courier.cash_count;
    },
  },
  computed: {
    couriersCount() {
      if (this.summaryToday.couriers) {
        return this.summaryToday.couriers.length;
      } else {
        return 0;
      }
    },
    lateOrdersCount() {
      return (
        this.summaryToday.late_kitchen_n_delivery_count +
        this.summaryToday.late_delivery_count
      );
    },
    lateOrdersPercent() {
      return (this.lateOrdersCount / this.summaryToday.done_count) * 100;
    },
  },
};
</script>

<style scoped></style>
