<template>
  <div>
    <page-header />
    <!-- start main -->
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active tab-performance" id="tab-4">
            <div class="performance-top">
              <h2 class="performance-title">Производительность</h2>
              <p>за последних 7 дней</p>
            </div>
            <div v-if="departmentsPerformance" class="table table-big">
              <div class="table-top">
                <div class="table-row">
                  <div class="table-col table-col__1">
                    <p>Место</p>
                  </div>
                  <div class="table-col table-col__2">
                    <p>Название</p>
                  </div>
                  <div class="table-col table-col__3">
                    <p>Скорость %</p>
                  </div>
                  <div class="table-col table-col__4">
                    <p>Кол-во. заказов</p>
                  </div>
                  <div class="table-col table-col__5">
                    <p>Самовывоз</p>
                  </div>
                  <div class="table-col table-col__6">
                    <p>Доставка</p>
                  </div>
                </div>
              </div>
              <div
                class="table-row"
                v-for="(department, index) in departmentsPerformance"
                :key="department.title"
              >
                <div class="table-col table-col__1">
                  <p>
                    <b>{{ index + 1 }}</b>
                  </p>
                </div>
                <div class="table-col table-col__2">
                  <p>{{ department.department.title }}</p>
                </div>
                <div class="table-col table-col__3">
                  <p>{{ department.success_rate }}</p>
                </div>
                <div class="table-col table-col__4">
                  <p>{{ department.done_count }}</p>
                </div>
                <div class="table-col table-col__5">
                  <p>{{ department.takeaway_count }}</p>
                </div>
                <div class="table-col table-col__6">
                  <p>{{ department.delivery_count }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import Analytics from "@/api/analytics";

export default {
  name: "DepartmentsPerformancePage",
  components: { PageHeader, PageTabs },
  data() {
    return {
      departmentsPerformance: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);
      const weekAgoFormatted = weekAgo.toJSON().slice(0, 10);
      this.departmentsPerformance = await Analytics.departmentsPerformance(
        weekAgoFormatted
      );
    },
  },
};
</script>

<style scoped></style>
