<template>
  <div class="wrapper">
    <div class="order">
      <div>
        <OrderDeliveryTypesList
          :class="{
            readonly: readonly,
          }"
          :isTakeawayService="isTakeawayService"
        />
        <div class="order__section">
          <OrderAlerts
            :class="{
              readonly: readonly,
            }"
          />
          <OrderDepartmentList
            :class="{
              readonly: readonly,
            }"
            :departments="departments"
          />
          <OrderForm />
          <OrderItemsList />
          <div
            class="order__row-points"
            :class="[
              {
                readonly: readonly,
              },
              $style.wrap,
            ]"
          >
            <OrderPaymentTypeList />
            <OrderUserPoints />
          </div>
          <OrderDiscountsList
            :class="{
              readonly: readonly,
            }"
          />
          <div
            v-if="order.payment_info.payment_type === 'cash'"
            :class="{
              readonly: readonlyItems,
            }"
          >
            <div>Внесено наличными:</div>
            <OrderRestFromList />
          </div>
        </div>
        <div class="order__section">
          <OrderMoney
            :class="{
              readonly: readonly,
            }"
          />
          <OrderSaveCancelButtons
            :class="{
              readonly: readonlyItems,
            }"
          />
          <OrderCheckboxes
            :class="{
              readonly: readonly && order.is_receipt_printed,
            }"
          />
        </div>
      </div>
    </div>
    <transition name="bounce">
      <CategoriesList
        v-if="showCategoriesList"
        :class="{
          readonly: readonlyItems,
        }"
      />
    </transition>
    <MenuItemList
      :class="{
        readonly: readonlyItems,
      }"
    />
    <notifications position="bottom right" />
    <ToppingsModal v-if="$store.state.orderForm.toppingsModal.isOpen" />
    <FormLoader v-if="isSaveLoading" />
  </div>
</template>

<script>
import CategoriesList from "./categories/CategoriesList";
import MenuItemList from "./category-menu-items/MenuItemList";
import OrderItemsList from "./order-items/OrderItemsList";
import OrderForm from "@/components/order/order-form/OrderForm";
import OrderDiscountsList from "@/components/order/order-discounts/OrderDiscountsList";
import OrderMoney from "@/components/order/OrderMoney";
import OrderSaveCancelButtons from "@/components/order/OrderSaveCancelButtons";
import OrderPaymentTypeList from "@/components/order/order-payment-types/OrderPaymentTypeList";
import OrderRestFromList from "@/components/order/order-rest/OrderRestFromList";
import OrderDepartmentList from "@/components/order/order-departments/OrderDepartmentList";
import OrderDeliveryTypesList from "@/components/order/order-delivery-type/OrderDeliveryTypesList";
import OrderUserPoints from "@/components/order/order-user-points/OrderUserPoints";
import { RESET_STATE } from "@/store/modules/order-form/mutation-types";
import OrderAlerts from "@/components/order/OrderAlerts";
import { mapGetters } from "vuex";
import Order from "@/api/order";
import OrderCheckboxes from "@/components/order/OrderCheckboxes";
import ToppingsModal from "./ToppingsModal";
import FormLoader from "./FormLoader";
import Department from "@/api/department";

export default {
  name: "OrderEditPage",
  components: {
    OrderCheckboxes,
    OrderAlerts,
    OrderUserPoints,
    OrderDeliveryTypesList,
    OrderDepartmentList,
    OrderRestFromList,
    OrderPaymentTypeList,
    OrderSaveCancelButtons,
    OrderMoney,
    OrderDiscountsList,
    OrderForm,
    CategoriesList,
    MenuItemList,
    OrderItemsList,
    ToppingsModal,
    FormLoader,
  },
  data() {
    return {
      showCategoriesList: true,
      Order: Order,
      departments: [],
      isTakeawayService: true,
    };
  },
  created() {
    this.$store.commit(RESET_STATE);
    this.fetchData();
    //window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    // async beforeWindowUnload(e) {
    //   await this.closePage();
    //   // Cancel the event
    //   e.preventDefault();

    //   // Chrome requires returnValue to be set
    //   e.returnValue = "";
    // },
    // async closePage() {
    //   if (this.$store.getters.order.id) {
    //     await this.$store.dispatch("setOrderField", {
    //       field: "is_confirming_in_dostavix",
    //       value: false,
    //       orderId: this.$store.getters.order.id,
    //       isCheck: true,
    //     });
    //   }
    // },
    async fetchData() {
      let id;

      let slug = localStorage.getItem("citySlug");

      this.department
        ? (id = this.department.id)
        : (id = this.$store.getters.user.department.id);

      this.departments = await Department.list(slug, false);
      this.departments.sort((a) => {
        if (a.id === id) {
          return -1;
        }
      });
      let count = 0;
      this.departments.forEach((i) => {
        if (!i.has_takeaway_service) {
          count++;
        }
      });
      if (count === this.departments.length) {
        this.isTakeawayService = false;
      }
      if (this.$route.params.id) {
        await this.$store.dispatch("setOrderById", this.$route.params.id);
        await this.$store.dispatch("fetchDepartment");
        // await this.$store.dispatch("setOrderField", {
        //   field: "is_confirming_in_dostavix",
        //   value: true,
        //   orderId: this.$route.params.id,
        //   isCheck: true,
        // });
      } else {
        await this.$store.dispatch("setOrderDefaults");
      }
    },
    onOrderDivClick() {
      if (!this.order.id) {
        this.showCategoriesList = false;
        const that = this;
        setTimeout(() => {
          that.showCategoriesList = true;
        }, 350);
      }
    },
  },
  computed: {
    ...mapGetters(["order", "loadingForm", "isSaveLoading", "department"]),
    readonlyItems() {
      return (
        this.order.status.is_confirmed === "done" ||
        (this.order.delivery_type === "delivery" &&
          this.order.status.is_packed &&
          this.order.courier)
      );
    },
    readonly() {
      return this.order.status.is_confirmed;
    },
  },
  // beforeDestroy() {
  //   window.removeEventListener("beforeunload", this.beforeWindowUnload);
  //   this.closePage();
  // },
};
</script>

<style lang="sass" scoped>
@import "../../assets/sass/variables"
@import "../../assets/sass/mixins"

.wrapper
  display: flex
  margin: 20px
  padding: 20px

.order
  width: 40%
  padding: 10px

  &__section
    @include section
    padding-left: 15px
    padding-right: 15px
    font-weight: bold

  &__row-points
    display: flex
    justify-content: space-between

input.switch[type=checkbox]
  height: 0
  width: 0
  visibility: hidden

label.switch
  cursor: pointer
  text-indent: -9999px
  width: 40px
  height: 20px
  background: grey
  display: inline-block
  border-radius: 100px
  position: relative

  &:after
    content: ''
    position: absolute
    top: 2px
    left: 3px
    width: 16px
    height: 16px
    background: #fff
    border-radius: 90px
    transition: 0.3s

input.switch:checked + label
  background: #bada55

  &:after
    left: calc(100% - 3px)
    transform: translateX(-100%)

label.switch:active:after
  width: 30px
</style>

<style lang="scss" module>
.wrap {
  flex-wrap: wrap;
}
</style>
