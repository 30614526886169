<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-3">
            <div class="tab-wrap">
              <settings-tabs />
              <div class="wrap-local" :class="{ 'is-loading': isLoading }">
                <div class="section">
                  <div class="table table-big">
                    <div class="table-actions">
                      <router-link
                        :to="{ name: 'stories-create' }"
                        class="btn btn--primary btn--size-default"
                        :class="$style.add"
                      >
                        <svg-icon name="plus-alt--no-color" />
                        <span>Добавить</span>
                      </router-link>
                    </div>
                    <div v-if="stories.length" class="table-top">
                      <div class="table-row">
                        <div class="table-col table-col__1">
                          <p>Фото</p>
                        </div>
                        <div class="table-col table-col__2">
                          <p>Название</p>
                        </div>
                        <div class="table-col table-col__5">
                          <p>Статус</p>
                        </div>
                        <div class="table-col table-col__5">
                          <p>Изменить порядок</p>
                        </div>
                      </div>
                    </div>
                    <template>
                      <div
                        class="table-row"
                        :style="{ cursor: 'pointer' }"
                        v-for="(item, index) in stories"
                        :key="item.id"
                        @drop="onDrop(index)"
                        @dragover.prevent
                        @dragenter.prevent
                        @dragstart="onDragStart(index)"
                        :draggable="true"
                      >
                        <div class="table-col table-col__1">
                          <p :class="$style.image">
                            <img
                              v-if="item.images.length"
                              :src="item.images[0].image"
                              alt=""
                            />
                            <img
                              v-else
                              src="@/assets/img/placeholder.svg"
                              alt=""
                            />
                          </p>
                        </div>
                        <div class="table-col table-col__2">
                          <p>{{ item.title }}</p>
                        </div>
                        <div
                          class="table-col table-col__5 col-actions"
                          :class="$style.tableCol"
                        >
                          <div
                            v-if="item.cities.length === 1"
                            :class="$style.edit"
                            @click="
                              $router.push({
                                name: 'stories-edit',
                                params: { id: item.id },
                              })
                            "
                          >
                            <svg-icon name="edit" />
                          </div>
                          <div v-else :style="{ width: '24px' }"></div>
                          <label class="switch">
                            <input
                              type="checkbox"
                              :checked="item.is_active"
                              :true-value="true"
                              :false-value="false"
                              :data-item-id="item.id"
                              @change="setIsActive"
                            />
                            <span class="slider round"></span>
                          </label>

                          <button
                            @click="deleteItem(item.id)"
                            :class="$style.noRemove"
                          >
                            <svg-icon name="remove-alt"></svg-icon>
                          </button>
                        </div>
                        <div
                          :style="{ textAlign: 'center' }"
                          class="table-col table-col__2"
                        >
                          <img src="@/assets/img/drag.svg" alt="" />
                        </div></div
                    ></template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import SettingsTabs from "@/components/SettingsTabs";
import SvgIcon from "@/components/SvgIcon";
import debounce from "v-debounce";
import Promo from "@/api/promo";
export default {
  components: {
    PageHeader,
    PageTabs,
    SettingsTabs,
    SvgIcon,
  },
  directives: { debounce },
  data() {
    return {
      stories: [],
      isLoading: false,
      draggedItemIndex: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const resp = await Promo.list(localStorage.getItem("citySlug"));
      this.stories = resp.data.results;
      this.isLoading = false;
    },
    async setIsActive(event) {
      this.isLoading = true;
      await Promo.update(event.target.dataset.itemId, {
        is_active: event.target.checked,
      });
      this.isLoading = false;
    },
    async deleteItem(id) {
      this.isLoading = true;
      await Promo.delete(id);
      this.fetchData();
      this.isLoading = false;
    },
    onDragStart(index) {
      this.draggedItemIndex = index;
    },
    async onDrop(index) {
      let prevItem = this.stories[index - 1];
      if (prevItem) {
        prevItem = prevItem.order + 1;
      }
      const draggedItem = this.stories.splice(this.draggedItemIndex, 1)[0];
      this.stories.splice(index, 0, draggedItem);
      this.draggedItemIndex = "";
      await Promo.moveStory({
        city: localStorage.getItem("citySlug"),
        slug: draggedItem.id,
        index: index ? prevItem : 0,
      });
    },
  },
};
</script>

<style lang="scss" module>
.tableCol {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.noRemove {
  font-size: 22px;
  color: #b8bec7;
  &:focus {
    outline: none;
  }

  &:hover {
    color: #ff2537;
  }
}
.noRemoveLabel {
  width: 50px;
}

.image {
  width: 5rem;
  height: 9rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.add {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    color: #fff;
  }
}
.edit {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
