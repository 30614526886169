<template>
  <router-link :to="{ name: 'orders-list-date', params: { date: today } }"
    ><button class="order-new">
      Выполненные и отмененные заказы скрыты. Нажмите, чтобы посмотреть все за
      сегодня.
    </button></router-link
  >
</template>

<script>
import { format } from "date-fns";

export default {
  name: "OrdersShowAll",
  computed: {
    today() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../assets/sass/variables"

.order-new
    width: 100%
    padding: 30px
    text-align: center
    background: $grey-lighter
    border-radius: 0 0 8px 8px
    font-size: 20px
    font-weight: bold
    color: white
    letter-spacing: 2px
    cursor: pointer
    border: none
    outline: none
</style>
