<template>
  <div class="page-wrapper">
    <header class="header">
      <div class="header__top">
        <div>
          <!-- left -->
          <div class="header__logo-location">
            <router-link :to="{ name: 'roles' }"
              ><img src="@/assets/img/logo.svg" alt="logo"
            /></router-link>
            <OrdersLocationSelect />
            <div class="header__datepicker"><OrdersDateSelect /></div>
            <select :class="$style.select" v-model="selectValue">
              <option value="all">Все заказы</option>
              <option value="only_for_time">Только ко времени</option>
              <option value="!only_for_time">Только текущие</option>
            </select>
          </div>
        </div>
        <div>
          <!-- right -->
          <LogoutButton />
        </div>
      </div>
      <div class="header__bottom">
        <div class="header__stats">
          <!-- left -->
          <OrdersStats
            :analytics="analytics"
            :isLoading="isLoading"
            v-if="hasModule(modules.dailySummary)"
          />
        </div>
        <div>
          <!-- right -->
          <OrderCreateNew v-if="hasModule(modules.orderCreation)" />
        </div>
      </div>
    </header>
    <OrdersList :orders-filter="ordersFilter" />
    <CouriersStat
      :analytics="analytics"
      :isLoading="isLoading"
      v-if="hasModule(modules.dailySummary) && hasModule(modules.delivery)"
    />
    <OrdersShowAll />
    <ModalBlacklist />
    <ModalPrivateComment />
    <ModalOrderLocation />
    <ModalWelcome />
    <notifications position="bottom right" />
    <ChangeDelieveryType />
  </div>
</template>

<script>
import OrderCreateNew from "./OrderCreateNew";
import OrdersList from "./OrdersList";
import OrdersLocationSelect from "../common/OrdersLocationSelect";
import OrdersDateSelect from "./OrdersDateSelect";
import OrdersStats from "./OrdersStats";
import LogoutButton from "../auth/LogoutButton";
import ModalBlacklist from "@/components/management/ModalBlacklist";
import ModalPrivateComment from "@/components/management/ModalPrivateComment";
import OrdersShowAll from "@/components/management/OrdersShowAll";
import OrderPushHandlerMixin from "@/mixins/OrderPushHandlerMixin";
import ModalOrderLocation from "@/components/management/ModalOrderLocation";
import Order from "@/api/order";
import Department from "@/api/department";
import ModalWelcome from "@/components/common/ModalWelcome";
import { SEEN_WELCOME_POPUP } from "@/store/modules/orders-list/mutation-types";
import DetailedLocationRequired from "@/mixins/DetailedLocationRequired";
import { mapGetters } from "vuex";
import CouriersStat from "@/components/management/CouriersStat";
import ChangeDelieveryType from "@/components/ChangeDelieveryType.vue";
import Analytics from "@/api/analytics";
import { SET_STATS } from "@/store/modules/orders-list/mutation-types";
import User from "@/api/user";

export default {
  name: "OrdersManagementPage",
  mixins: [OrderPushHandlerMixin, DetailedLocationRequired],
  components: {
    CouriersStat,
    ModalWelcome,
    ModalOrderLocation,
    OrdersShowAll,
    ModalPrivateComment,
    ModalBlacklist,
    OrderCreateNew,
    OrdersList,
    OrdersLocationSelect,
    OrdersDateSelect,
    OrdersStats,
    LogoutButton,
    ChangeDelieveryType,
  },
  data() {
    return {
      modules: Department.MODULES,
      isLoading: false,
      selectValue: "",
    };
  },
  mounted() {
    const filter = localStorage.getItem("filter") || "all";
    this.selectValue = filter;
    if (!this.$store.state.orderList.welcomePopupSeen) {
      this.$modal.show("welcome");
      this.$store.commit(SEEN_WELCOME_POPUP);
    }
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const stats = await Analytics.dailyOrdersSummary({
        date: this.date,
        departmentSlug: this.$store.getters.currentDepartment.slug,
      });
      this.$store.commit(SET_STATS, { stats });
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["hasModule"]),
    ordersFilter() {
      let filter = {
        department: this.$store.getters.currentDepartment.slug,
        status: Order.STATUS.open,
        stage: "managing",
      };
      if (
        this.selectValue === "only_for_time" ||
        this.selectValue === "!only_for_time"
      ) {
        filter.only_for_time = this.selectValue;
      }
      return filter;
    },
    date() {
      let now = new Date();
      return (
        this.$route.params.date ||
        `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
      );
    },
    analytics() {
      return this.$store.getters.ordersStats;
    },
  },
  watch: {
    date() {
      this.loadData();
    },
    ordersFilter() {
      this.loadData();
    },
    selectValue() {
      localStorage.setItem("filter", this.selectValue);
    },
  },
  async beforeDestroy() {
    if (this.$store.getters.FCMtoken) {
      await User.deleteFCMToken(this.$store.getters.FCMtoken);
      this.$store.commit("setFSMtoken", null);
    }
  },
};
</script>

<style lang="sass">
@import "../../assets/sass/variables"

.contrast
    color: $contrast-color
</style>

<style lang="sass" scoped>
@import "../../assets/sass/variables"

.page-wrapper
    padding: 0 20px
    background: $page-bg
    position: relative

.header
    display: flex
    justify-content: space-between
    flex-direction: column
    padding: 40px 0
    &__top, &__bottom
        display: flex
        justify-content: space-between
        align-items: center
    &__top
        padding-bottom: 30px
    &__stats
        display: flex
        align-items: center
    &__datepicker
        padding-left: 30px
</style>

<style lang="sass" module>
.select
    width: 13rem
    font-size: 1rem
    margin: 0 0 0 2rem
    padding: 0 0 0 1rem
    height: 2.25rem
    position: relative
    top: 0.25rem
    color: #000
    border: 1px solid rgba(0, 0, 0, 0.5)
</style>
