import Vue from 'vue'
import axios from 'axios'
import { getHashCode } from '@/utils/hash'

export const API = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'X-Api-Key': process.env.VUE_APP_API_KEY
    }
});

export const APIFormData = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'X-Api-Key': process.env.VUE_APP_API_KEY,
        "Content-Type": "multipart/form-data"
    }
});

function parseApiErrorDetails(error) {
    let body = '';
    const joinSymbol = '<br/>';
    for (const [key, value] of Object.entries(error.detail)) {
        if (key === 'non_field_errors') {
            body += `${value.join(joinSymbol)} ${joinSymbol}`
        } else if (value.constructor === Object) {
            for (const [nestedKey, nestedValue] of Object.entries(value)) {
                body += `${nestedKey}: ${nestedValue.join(joinSymbol)} ${joinSymbol}`
            }
        } else {
            body += `${key}: ${value.join(joinSymbol)} ${joinSymbol}`
        }
    }
    if (body.length < 1) {
        return error.fallback_message
    }
    return body
}

API.interceptors.response.use(
    function (response) { return response; },
    function (error) {
        // handle error
        if (error.response && error.response.data.fallback_message !== "Вы можете получить локацию заказа только если он ещё не выполнен") {
            Vue.notify({
                type: 'error',
                duration: 10000,
                title: 'Ошибка при запросе к серверу',
                text: parseApiErrorDetails(error.response.data)
            })
        }
        return error.response
    });

let _cache = {};

export const CACHE = {
    _buildKey(path, params) {
        return getHashCode(path + JSON.stringify(params));
    },

    has(path, params) {
        let key = this._buildKey(path, params);
        return key in _cache
    },
    get(path, params) {
        let key = this._buildKey(path, params);
        return _cache[key];
    },
    set(path, params, val) {
        let key = this._buildKey(path, params);
        _cache[key] = val;
    }
};

export class Model {
    path = '/v1/';

    get(objId, isFilter, usingCache = false) {
        return this._get(objId, isFilter, usingCache)
    }

    _list(params, usingCache = true) {
        if (usingCache && CACHE.has(this.path, params)) {
            return new Promise(resolve => {
                return resolve(CACHE.get(this.path, params))
            })
        } else {
            return API.get(`${this.path}/`, { params: params })
                .then(response => {
                    if (usingCache) {
                        CACHE.set(this.path, params, response.data.results);
                    }
                    return response.data.results
                })
                .catch((error) => {
                    alert(error.response.data.detail);
                    return []
                })
        }
    }

    _get(objId, isFilter, usingCache = true) {

        if (usingCache && CACHE.has(this.path, objId)) {
            return new Promise(resolve => {
                return resolve(CACHE.get(this.path, objId))
            })
        } else {
            let path = `${this.path}/${objId}/`
            if (isFilter && isFilter === true) {
                path = `${this.path}/${objId}/?is_filter_by_responsible_categories=${isFilter}`
            }
            return API.get(path)
                .then(response => {
                    if (usingCache) {
                        CACHE.set(this.path, objId, response.data);
                    }
                    return response.data
                })
                .catch((error) => {
                    alert(error.response.data.detail);
                    return []
                })
        }
    }

    // _post(payload, objId=null) {
    //     let path = this.path;
    //     if (objId) {
    //         path += objId
    //     }
    // }
}