var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.modal},[_c('div',{class:_vm.$style.overlay,on:{"click":function($event){return _vm.$store.commit('setToppingsModal', {
        isOpen: false,
        item: null,
        type: '',
      })}}}),_c('div',{class:_vm.$style.container,on:{"click":function($event){$event.stopPropagation();}}},[(_vm.toppings.length)?_c('div',{class:_vm.$style.toppings},[_vm._l((_vm.toppings),function(group){return _c('div',{key:group.id,class:_vm.$style.topping},[_c('div',{class:_vm.$style.toppingTitle},[_vm._v(" "+_vm._s(group.title)+" "),(group.min_amount)?_c('p',[_vm._v(" Минимальное значение: "+_vm._s(group.min_amount)+" ")]):_vm._e(),(group.max_amount)?_c('p',[_vm._v(" Максимальное значение: "+_vm._s(group.max_amount)+" ")]):_vm._e(),(group.free_amount)?_c('p',[_vm._v(" Бесплатное кол-во: "+_vm._s(group.free_amount)+" ")]):_vm._e()]),_vm._l((group.modifiers),function(item){return _c('div',{key:item.id,class:_vm.$style.toppingItem},[_c('div',{class:[
              _vm.$style.minus,
              {
                [_vm.$style.disabled]:
                  item.qty < 1 || item.qty === item.min_amount,
              },
            ],on:{"click":function($event){return _vm.changeQty('minus', item.id, group.id)}}},[_vm._v(" - ")]),_c('div',{class:_vm.$style.itemTitle},[_c('span',[_vm._v(_vm._s(item.qty + " X"))]),_vm._v(_vm._s(item.title)+" "),_c('div',[_vm._v(_vm._s(item.price))])]),_c('div',{class:[
              _vm.$style.plus,
              {
                [_vm.$style.disabled]:
                  item.qty === item.max_amount || group.plusDisabled,
              },
            ],on:{"click":function($event){return _vm.changeQty('plus', item.id, group.id)}}},[_vm._v(" + ")])])})],2)}),_c('div',{class:_vm.$style.button,on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Добавить")])],2):_c('div',{class:_vm.$style.noToppings},[_vm._v(" У этого товара нет модификаторов ")]),_c('div',{class:_vm.$style.close},[_c('img',{attrs:{"src":require("@/assets/img/icons/close.svg"),"alt":""},on:{"click":function($event){return _vm.$store.commit('setToppingsModal', {
            isOpen: false,
            item: null,
            type: '',
          })}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }