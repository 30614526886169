import Order from "@/api/order";
import {
  SET_STATS,
  UPDATE_ORDERS_ORDER,
} from "@/store/modules/orders-list/mutation-types";
import firebase from "firebase/app";
import "firebase/messaging";
import User from "@/api/user";

export default {
  async created() {
    let firebaseConfig;
    let vapidKey;
    if (
      process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "development"
    ) {
      firebaseConfig = {
        apiKey: "AIzaSyA8jjmeFyN_UwYiQLDqEY3EUiXjIzxHq8k",
        authDomain: "dostavix.firebaseapp.com",
        projectId: "dostavix",
        storageBucket: "dostavix.appspot.com",
        messagingSenderId: "839238344406",
        appId: "1:839238344406:web:5fb6abf3bbad2550007377",
        measurementId: "G-036LTLDW1V",
      };
      vapidKey =
        "BNZiN6YQLZ_oUX6cPrC9MGZ71At-L6zY1EJxxRstO1f8nOJM0uteNR-LR5T5BctwAXS3nXqFaG-pq6LT4CvoXF8";
    } else {
      firebaseConfig = {
        apiKey: "AIzaSyA8jjmeFyN_UwYiQLDqEY3EUiXjIzxHq8k",
        authDomain: "dostavix.firebaseapp.com",
        projectId: "dostavix",
        storageBucket: "dostavix.appspot.com",
        messagingSenderId: "839238344406",
        appId: "1:839238344406:web:5fb6abf3bbad2550007377",
        measurementId: "G-036LTLDW1V",
      };
      vapidKey =
        "BNZiN6YQLZ_oUX6cPrC9MGZ71At-L6zY1EJxxRstO1f8nOJM0uteNR-LR5T5BctwAXS3nXqFaG-pq6LT4CvoXF8";
    }
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    const messaging = firebase.messaging();
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        //window.console.log('Notification permission granted.');
        messaging.getToken({ vapidKey }).then((token) => {
          const added = User.addFCMToken(token);
          if (!added) {
            alert(
              "Не удалось добавить FCM ключ. Фоновое обновление заказов может не работать."
            );
          }
          else {
            this.$store.commit("setFSMtoken", token);
          }
        });
      } else {
        alert(
          "Очень нужны права на отправку уведомлений. Без них не работает обновление заказов в фоне."
        );
      }
    });
    // messaging.onMessage((payload) => {
    //   //this.handlePush(payload.data);
    //   window.console.log(payload)
    // });

    navigator.serviceWorker.addEventListener("message", (payload) => {
      // const result = payload.data.data
      // if (result.object) {
      //   window.console.log(JSON.parse(result.object))
      // }
      // else if (result.object_ref) {
      //   window.console.log("ref", result.object_ref)
      // }
      this.handlePush(payload.data.data);
    });
  },

  methods: {
    async handlePush(pushData) {
      if (!pushData) {
        return;
      }

      if (pushData.model === "Order") {
        let order;
        if ("object" in pushData) {
          order = JSON.parse(pushData.object);
        }
        else if ("object_ref" in pushData) {
          if (this.isCookingPage) {
            let isFilter = true
            order = await Order.get(pushData.object_ref, isFilter);
          }
          else {
            order = await Order.get(pushData.object_ref);
          }

        }
        this.updateOrder(order);
      } else if (pushData.model === "OrdersDaily") {
        const stats = JSON.parse(pushData.object);
        this.$store.commit(SET_STATS, { stats });
      }
    },
    updateOrder(order) {
      let isNotUpdate = false
      if (this.isNotUpdate && this.isNotUpdate === true) {
        isNotUpdate = true
      }
      let isNotification = true
      // if (order.payment_info.payment_type === "online" &&
      //   order.payment_info.total_paid < order.payment_info.total_price) {
      //   isNotification = false
      // }
      if (order.items.length == 0) {
        isNotification = false
      }
      this.$store.commit(UPDATE_ORDERS_ORDER, { order, isNotification, isNotUpdate });
    },
  },
};
