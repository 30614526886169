<template>
  <router-link :to="{ name: 'order-add-page' }">
    <button class="order-new">
      <svg-icon name="plus-alt"></svg-icon>
      <span>Новый заказ</span>
    </button>
  </router-link>
</template>

<script>
import SvgIcon from "../SvgIcon";

export default {
  name: "OrderCreateNew",
  components: { SvgIcon },
};
</script>

<style lang="sass" scoped>
@import "../../assets/sass/variables"

.order-new
    display: flex
    align-items: center
    padding: 10px 20px
    text-align: center
    background: $contrast-color
    box-shadow: $primary-box-shadow
    border-radius: $border-radius-large
    font-size: 20px
    font-weight: bold
    color: white
    letter-spacing: 2px
    cursor: pointer
    border: none
    outline: none
    span
        margin-left: 15px
</style>
