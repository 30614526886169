<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = `Dostavix ${to.meta.title || ""}`;
      },
    },
  },
};
</script>
