<template>
  <div class="wrapper">
    <header><h1>Причина отказа</h1></header>
    <form class="cancellation-form" @submit.prevent="cancel">
      <p v-if="isError" :class="$style.errorMes">Выберите причину</p>
      <div
        class="fancy-input fancy-input--medium"
        :class="{ [$style.error]: isError }"
      >
        <label>Причина</label>
        <select v-model="reason" @focus="reset">
          <option
            v-for="reason in reasonsList"
            :key="reason.id"
            :value="reason.id"
          >
            {{ reason.title }}
          </option>
        </select>
      </div>
      <div class="fancy-input fancy-input--medium">
        <label>Комментарий</label>
        <textarea v-model="comment"></textarea>
      </div>
      <button
        type="submit"
        class="btn btn--primary btn--size-default"
        :class="{ 'is-loading': loadingForm, readonly: isLoading }"
      >
        Отменить заказ
      </button>
    </form>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CancellationReason from "@/api/cancellation-reason";
import printReceipt from "@/utils/receipt";

export default {
  name: "OrderCancelPage",
  data() {
    return {
      reasonsList: [],
      reason: null,
      comment: null,
      isLoading: false,
      isError: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.reasonsList = await CancellationReason.list();
    },
    reset() {
      this.isError = false;
    },
    async cancel() {
      if (this.reason === null) {
        this.isError = true;
      } else {
        this.isLoading = true;
        this.isError = false;
        await this.$store.dispatch("setOrderById", this.$route.params.id);
        if (this.order.is_receipt_printed) {
          printReceipt({
            order: this.order,
            router: this.$router,
            store: this.$store,
            isCanceled: true,
            reasonId: this.reason,
            comment: this.comment,
          });
        } else {
          const payload = {
            orderId: this.$route.params.id,
            reasonId: this.reason,
            comment: this.comment,
          };
          await this.$store.dispatch("cancelOrder", payload);
        }
        this.isLoading = false;

        this.$router.push({ name: "operator" });
      }
    },
  },
  computed: {
    ...mapGetters(["loadingForm", "order"]),
  },
};
</script>

<style scoped lang="sass">
@import "../../assets/sass/variables"

.wrapper
    display: flex
    flex-direction: column
    max-width: 500px
    margin: 50px auto
    padding: 30px
    border-radius: $border-radius-default
    background-color: #fff

.cancellation-form
    div
        border-radius: 16px
        margin: 14px 0

.fancy-input
    padding: 0 !important
</style>

<style lang="scss" module>
.error {
  border-radius: 16px;
  border: 1px solid red;
}
.errorMes {
  margin: 0 0 -6px 0;
  color: red;
}
</style>
