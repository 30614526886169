<template>
  <ul class="main-list tab-list">
    <li><router-link :to="{ name: 'analytics-daily' }">День</router-link></li>
    <li>
      <router-link :to="{ name: 'analytics-monthly' }">Месяц</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'analytics-reports-revenue' }"
        >Отчеты</router-link
      >
    </li>
    <li>
      <router-link :to="{ name: 'analytics-departments-performance' }"
        >Метрики</router-link
      >
    </li>
    <li><router-link :to="{ name: 'users-list' }">Управление</router-link></li>
  </ul>
</template>

<script>
export default {
  name: "PageTabs",
};
</script>

<style lang="sass" scoped>
.main-list.tab-list
    margin-bottom: 0
</style>
