<template>
  <div class="wrapper">
    <div
      class="main-col__statistics"
      :class="{ 'bg-red': this.target > this.successRate }"
    >
      <div
        class="main-col__statistics-row"
        :style="`width: ${barSize.successRate}%`"
      >
        <span><span>Июнь 2021</span></span>
        <p>
          <span>{{ successRate | percent }}</span>
        </p>
      </div>
    </div>
    <div class="main-col__statistics">
      <div
        class="main-col__statistics-row"
        :style="`width: ${barSize.target}%`"
      >
        <span><span>Цель</span></span>
        <p>
          <span>{{ target }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessRateBars",
  props: {
    summaryCurrent: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
  computed: {
    successRate() {
      const late_count =
        this.summaryCurrent.late_kitchen_n_delivery_count +
        this.summaryCurrent["late_" + this.keyword + "_count"];
      return (100 * late_count) / this.summaryCurrent.total_count;
    },
    target() {
      return this.$store.getters.currentDepartment[
        this.keyword + "_success_rate_target"
      ];
    },
    barSize() {
      let sizes = {
        target: 0,
        successRate: 0,
      };
      if (this.target > this.successRate) {
        sizes.target = 100;
        sizes.successRate = (this.successRate / this.target) * 100;
      } else {
        sizes.successRate = 100;
        sizes.target = (this.target / this.successRate) * 100;
      }
      return sizes;
    },
  },
};
</script>

<style scoped lang="sass">
.wrapper
  display: inline-block
  width: 100%
  padding: 0
</style>
