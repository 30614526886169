import { Model, API } from "./index";

const removeEmpty = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      newObj[key] = removeEmpty(obj[key]); // recurse
    } else if (obj[key] != null && obj[key] !== "") {
      newObj[key] = obj[key]; // copy value
    }
  });

  return newObj;
};

const cleanOrder = (obj) => {
  const newObj = removeEmpty(obj);
  Object.keys(newObj).forEach((key) => {
    if (Object.keys(newObj[key]).length === 0) {
      delete newObj[key];
    }
  });
  return newObj;
};

class Order extends Model {
  path = "/v1/orders";

  get STATUS() {
    return {
      cart: "cart",
      open: "open",
      done: "done",
      canceled: "canceled",
    };
  }

  get DELIVERY_TYPE() {
    return {
      takeaway: "takeaway",
      delivery: "delivery",
      stay: "stay",
    };
  }

  get PAYMENT_TYPE() {
    return {
      cash: "cash",
      online: "online",
      pos: "pos",
    };
  }

  get SOURCE() {
    return {
      dashboard: "dashboard",
      mobile: "app",
      website: "site",
      aggregator: "aggregator",
      call_center: "call_center",
    };
  }

  list(params) {
    return this._list(params, false);
  }

  create(order) {
    return API.post(`${this.path}/`, order).then((response) => {
      return response.data;
    });
  }

  changeState({ orderId, state, items }) {
    let path = `${this.path}/${orderId}/change-state/`;
    return API.post(path, { state: state, items: items }).then((response) => {
      return response.data;
    });
  }

  checkout(order) {
    const orderClear = cleanOrder(order);
    orderClear.items = order.items;
    let path = `${this.path}/${order.id}/checkout/`;
    return API.post(path, orderClear).then((response) => {
      return response.data;
    });
  }

  cancel(orderId, reasonId, comment) {
    let path = `${this.path}/${orderId}/cancel/`;
    return API.post(path, { reason: reasonId, comment }).then((response) => {
      return response.data;
    });
  }

  accomplish(orderId) {
    let path = `${this.path}/${orderId}/accomplish/`;
    return API.post(path).then((response) => {
      return response.data;
    });
  }

  update(order) {
    const orderClear = cleanOrder(order);
    orderClear.items = order.items;
    if (!order.noEditing) {
      if (order.deliver_at == null) {
        orderClear.deliver_at = null;
      }
    }
    if (order.courier === "remove") {
      orderClear.courier = null;
    }

    let path = `${this.path}/${order.id}/`;
    return API.patch(path, orderClear).then((response) => {
      return response.data;
    });
  }

  addItem(orderId, item, modifier_groups) {
    let path = `/v2/orders/${orderId}/items/`;
    let payload = { item: item.id, modifier_groups: modifier_groups };
    return API.post(path, payload).then((response) => {
      return response.data;
    });
  }

  removeItem(itemId, orderId) {
    let path = `/v2/orders/${orderId}/items/${itemId}/`;
    return API.delete(path).then((response) => {
      return response.data;
    });
  }

  changeItemQty(itemId, orderId, qty) {
    let path = `/v2/orders/${orderId}/items/${itemId}/`;
    let payload = { qty: qty };
    return API.patch(path, payload).then((response) => {
      return response.data;
    });
  }
  addComment(itemId, orderId, comment) {
    let path = `/v2/orders/${orderId}/items/${itemId}/`;
    let payload = { comment: comment };
    return API.patch(path, payload).then((response) => {
      return response.data;
    });
  }

  addItemTopping(itemId, orderId, modifier_groups) {
    let path = `/v2/orders/${orderId}/items/${itemId}/`;
    let payload = { modifier_groups };
    return API.patch(path, payload).then((response) => {
      return response.data;
    });
  }
  removeItemTopping(itemId, orderId, toppingId) {
    let path = `${this.path}/${orderId}/items/${itemId}/toppings/${toppingId}/`;
    return API.delete(path).then((response) => {
      return response.data;
    });
  }

  setField(field, value, orderId) {
    let path = `${this.path}/${orderId}/`;
    let payload = {};
    if (field.split(".").length > 1) {
      // e.g. 'payment_info.payment_type'
      payload[field.split(".")[0]] = {};
      payload[field.split(".")[0]][field.split(".")[1]] = value;
    } else {
      payload[field] = value;
    }
    return API.patch(path, payload).then((response) => {
      return response.data;
    });
  }
  changePaymentType(field, value, orderId) {
    let path = `${this.path}/${orderId}/change-payment-type/`;
    let payload = {};
    if (field.split(".").length > 1) {
      // e.g. 'payment_info.payment_type'
      payload[field.split(".")[0]] = {};
      payload[field.split(".")[0]][field.split(".")[1]] = value;
    } else {
      payload[field] = value;
    }
    //window.console.log(payload.payment_info.payment_type)
    let temp = { payment_type: payload.payment_info.payment_type };
    return API.post(path, temp).then((response) => {
      return response.data;
    });
  }

  changeDeliveryType(field, value, orderId, address, contacts, comment) {
    let path = `${this.path}/${orderId}/change-delivery-type/`;
    let temp = {};
    if (value === "takeaway") {
      temp = {
        delivery_type: value,
        comment: comment,
      };
    } else {
      temp = {
        delivery_type: value,
        address: address,
        contacts: contacts,
        comment: comment,
      };
    }
    return API.post(path, temp).then((response) => {
      return response.data;
    });
  }

  applyUserPoints(orderId, code) {
    let path = `${this.path}/${orderId}/apply-user-points/`;
    let payload = {
      code: code,
    };
    return API.post(path, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        alert(error.response.data.detail);
      });
  }

  changeUserPoints(orderId, points) {
    let path = `${this.path}/${orderId}/apply-user-points/`;
    let payload = {
      custom_points_used: points,
    };
    return API.patch(path, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        alert(error.response.data.detail);
      });
  }


  async getLocation(orderId) {
    let path = `${this.path}/${orderId}/location/`;
    const resp = await API.get(path);
    return resp.data;
  }

  getSuggest(input, city) {
    const params = {
      input: input,
      city: city,
    };
    return API.get(`/v1/geo/suggest/`, { params });
  }

  getGeo({ id, address, city }) {
    const params = {
      id: id,
      address: address,
      city: city,
    };
    return API.get(`/v1/geo/geocode/direct/`, { params });
  }

  checkGeo(city, coordinates) {
    const params = {
      delivery_coordinates: coordinates,
      city: city,
    };
    return API.get(`/v1/departments/`, { params });
  }
}

export default new Order();
