<template>
  <div class="date-wrapper">
    <svg-icon name="date"></svg-icon>
    <VueCtkDateTimePicker
      color="#000"
      button-color="#FF2537"
      locale="ru"
      :only-date="true"
      :auto-close="true"
      format="YYYY-MM-DD"
      input-size="sm"
      :label="formattedDate"
      @input="onDateSelect"
      ><button class="datepicker"></button>
    </VueCtkDateTimePicker>
    <div v-if="getLoading() === true" :class="$style.loading">Загрузка...</div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import { format } from "date-fns";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "DateSelect",
  components: {
    SvgIcon,
    VueCtkDateTimePicker,
  },
  methods: {
    ...mapGetters({ getLoading: "getLoading" }),
    ...mapMutations({ changeLoading: "changeLoading" }),
    onDateSelect(value) {
      this.$store.commit("changeLoading", true);
      let path = value;
      if (this.$route.params.date !== path)
        this.$router.push({
          name: "orders-list-date",
          params: { date: value },
        });
      else {
        this.$store.commit("changeLoading", false);
      }
    },
  },
  computed: {
    formattedDate() {
      let formatted = null;
      if (this.$route.params.date) {
        const raw = this.$route.params.date.split("-");
        formatted = `${raw[2]}.${raw[1]}.${raw[0]}`;
      } else {
        formatted = format(new Date(), "dd.MM.yyyy");
      }
      return formatted;
    },
  },
};
</script>

<style scoped lang="sass">
@import "../../assets/sass/variables"

.date-wrapper
    display: flex
    align-items: center

.datepicker
    max-width: 100px
    margin-left: 10px
    background: inherit
    outline: none
    border: 0
    font-size: 16px
    font-weight: bold
    color: $contrast-color
    cursor: pointer
</style>

<style lang="scss" module>
.loading {
  padding: 3rem;
  font-size: 18px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  z-index: 100;
}
</style>
