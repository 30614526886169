<template>
  <div class="order__departments" v-show="visible">
    <order-department
      v-for="department in departments"
      :key="department.id"
      :department="department"
    ></order-department>
  </div>
</template>

<script>
import Order from "@/api/order";
import OrderDepartment from "@/components/order/order-departments/OrderDepartment";
import { mapGetters } from "vuex";

export default {
  name: "OrderDepartmentList",
  components: { OrderDepartment },
  props: {
    departments: Array,
  },

  computed: {
    ...mapGetters(["order", "department"]),
    visible() {
      return this.order.delivery_type === Order.DELIVERY_TYPE.takeaway;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.order__departments
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 5px
    padding: $vertical-padding-default 0
</style>
