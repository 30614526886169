<template v-if="blacklistComment || privateComment">
  <div class="alert-wrap">
    <div class="alert alert-danger" v-if="blacklistComment">
      <font-awesome-icon icon="exclamation-triangle" />
      <strong>Черный список:</strong> <span>{{ blacklistComment }}</span>
    </div>
    <div class="alert alert-info" v-if="privateComment">
      <font-awesome-icon icon="comment" /> <strong>О клиенте:</strong>
      <span>{{ privateComment }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderAlerts",
  computed: {
    ...mapGetters(["order"]),
    blacklistComment() {
      if (this.order.user && this.order.user.blacklist_comment) {
        return this.order.user.blacklist_comment;
      } else {
        return false;
      }
    },
    privateComment() {
      if (this.order.user && this.order.user.private_comment) {
        return this.order.user.private_comment;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
