import { API } from "./index";

export default {
    async obtainUserData(username, password) {
        const response = await API.post('/v1/auth/by-password/login/', { username, password });
        return response.data;
    },
    async setField(field, value, userId) {
        let path = `/v1/users/${userId}/`;
        let payload = {};
        payload[field] = value;
        try {
            const response = await API.patch(path, payload);
            return response.data;
        } catch (error) {
            alert(error.response.data.detail);
        }
    },
    async setUserField(field, value, userId, departmentSlug) {
        let path = `/v1/departments/${departmentSlug}/employees/${userId}/`;
        let payload = {};
        payload[field] = value;
        try {
            const response = await API.patch(path, payload);
            return response.data;
        } catch (error) {
            alert(error.response.data.detail);
        }
    },
    async getLastAddresses(phone) {
        const resp = await API.get(`/v1/users/${phone}/last-addresses/`)
        return resp.data
    },
    async addFCMToken(token) {
        const resp = await API.post('/v1/users/me/fcm-tokens/', { token })
        return resp.status === 201
    },
    async deleteFCMToken(token) {
        await API.delete(`/v1/users/me/fcm-tokens/${token}/`)
    },
    async getAllDepartments() {
        const resp = await API.get('/v1/departments/')
        return resp.data.results
    },

    async changeUserDepartment(department) {
        let resp = await API.patch('/v1/users/me/', { department })
        return resp.data
    },
    async list(departmentSlug, groups) {
        const params = {
            groups,
        }
        const resp = await API.get(`/v1/departments/${departmentSlug}/employees/`, { params })
        return resp.data.results
    },
    async get(departmentSlug, userId) {
        const resp = await API.get(`/v1/departments/${departmentSlug}/employees/${userId}/`)
        return resp.data
    },
    async update(departmentSlug, userId, payload) {
        const resp = await API.patch(`/v1/departments/${departmentSlug}/employees/${userId}/`, payload)
        return resp.data
    },
    async delete(departmentSlug, userId) {
        return await API.delete(`/v1/departments/${departmentSlug}/employees/${userId}/`)
    },
    async create(departmentSlug, payload) {
        return await API.post(`/v1/departments/${departmentSlug}/employees/`, payload)
    },
    async getUser() {
        return await API.get(`/v1/users/me/`)
    }
}