<template>
  <div class="alert-wrap" :class="$style.wrap">
    <div
      class="alert alert-danger"
      :class="$style.alert"
      v-if="blacklistComment"
    >
      <font-awesome-icon icon="exclamation-triangle" />
      <strong
        >Черный список: <span>{{ blacklistComment }}</span></strong
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderListItemsAlerts",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    blacklistComment() {
      if (this.order.user && this.order.user.blacklist_comment) {
        return this.order.user.blacklist_comment;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
}

.alert {
  display: flex;
  margin: 0 0 0 1rem !important;

  strong {
    span {
      margin-left: 0.5rem;
      word-break: break-all;
    }
  }
}
</style>
