import { SET_USER, CLEAR_USER } from "./mutation-types";

export const USER_DATA_KEY = 'userData';

export default {
    state: {
        user: localStorage[USER_DATA_KEY] ? JSON.parse(localStorage[USER_DATA_KEY]) : {},
        FCMtoken: null
    },
    getters: {
        user: state => {
            return state.user
        },
        isAuthenticated() {
            return Boolean(localStorage[USER_DATA_KEY])
        },
        FCMtoken(state) {
            return state.FCMtoken
        }
    },
    mutations: {
        [SET_USER](state, { user }) {
            //window.console.log(JSON.parse(localStorage[USER_DATA_KEY]))
            localStorage[USER_DATA_KEY] = JSON.stringify(user);
            state.user = user
        },
        [CLEAR_USER](state) {
            localStorage.removeItem(USER_DATA_KEY);
            state.user = {}
        },
        setFSMtoken(state, token) {
            state.FCMtoken = token
        },
        setUserDepartment(state, department) {
            state.user.department = department
        },

    },
}