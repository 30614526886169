import { API } from "./index";

export default {
  async countriesOrdersSummary(date) {
    let path = `/v1/analytics/countries/summary/?data__gte=${date}`;
    const resp = await API.get(path, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (resp.status === 200) {
      return resp.data;
    } else {
      return {};
    }
  },
  async departmentsOrdersSummary(date) {
    let path = `/v1/analytics/departments/summary/?data__gte=${date}`;
    const resp = await API.get(path, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (resp.status === 200) {
      return resp.data;
    } else {
      return {};
    }
  },
  async dailyOrdersSummary({ date, departmentSlug, period, filter }) {
    if (!period) {
      period = "daily";
    }
    let path = `/v1/analytics/orders/${period}-summary/${date}/?department=${departmentSlug}`;
    if (filter && filter.status) {
      path = `/v1/analytics/orders/${period}-summary/${date}/?department=${departmentSlug}&status=${filter.status}`;
    }
    if (filter && filter.delivery_type) {
      path = `/v1/analytics/orders/${period}-summary/${date}/?department=${departmentSlug}&delivery_type=${filter.delivery_type}`;
    }
    if (filter && filter.source) {
      path = `/v1/analytics/orders/${period}-summary/${date}/?department=${departmentSlug}&source=${filter.source}`;
    }
    if (filter && filter.discount_percent) {
      path = `/v1/analytics/orders/${period}-summary/${date}/?department=${departmentSlug}&discount_percent=${filter.discount_percent}`;
    }
    if(filter && filter.only_for_time) {
      path = `/v1/analytics/orders/${period}-summary/${date}/?department=${departmentSlug}&only_for_time=${filter.only_for_time}`;
    }

    const resp = await API.get(path, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (resp.status === 200) {
      return resp.data;
    } else {
      return {};
    }
  },

  async monthlyOrdersSummary({ startDate, endDate, departmentSlug, period }) {
    let path = `/v1/analytics/orders/${period}-summary/?department=${departmentSlug}&date__gte=${startDate}`;
    if (endDate) {
      path += `&date__lte=${endDate}`;
    }
    const resp = await API.get(path, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (resp.status === 200) {
      return resp.data.results;
    } else {
      return {};
    }
  },

  async departmentsPerformance(startDate) {
    let path = `/v1/analytics/departments/performance/?date__gte=${startDate}`;
    const resp = await API.get(path, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (resp.status === 200) {
      return resp.data.results;
    } else {
      return {};
    }
  },

  async ordersByStep(departmentSlug) {
    let path = `/v1/analytics/departments/${departmentSlug}/orders/by-steps/`;
    const resp = await API.get(path, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (resp.status === 200) {
      return resp.data;
    } else {
      return {};
    }
  },

  async report({
    startDate,
    endDate,
    deliveryTypes,
    sources,
    paymentTypes,
    department,
    reportType,
    categories,
  }) {
    const q = new URLSearchParams();
    q.append("closed_at__gte", startDate);
    q.append("closed_at__lte", endDate);
    q.append("department", department);
    deliveryTypes.forEach((dtype) => {
      q.append("delivery_type", dtype);
    });
    sources.forEach((source) => {
      q.append("source", source);
    });
    paymentTypes.forEach((pType) => {
      q.append("payment_type", pType);
    });
    categories.forEach((cat) => {
      q.append("category", cat);
    });
    const path = `/v1/analytics/reports/${reportType}/?${q}`;
    const resp = await API.get(path);
    return resp.data;
  },
};
