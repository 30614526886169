<template>
  <div :class="$style.modal">
    <div
      :class="$style.overlay"
      @click="
        $store.commit('setToppingsModal', {
          isOpen: false,
          item: null,
          type: '',
        })
      "
    ></div>
    <div :class="$style.container" @click.stop>
      <div :class="$style.toppings" v-if="toppings.length">
        <div v-for="group in toppings" :key="group.id" :class="$style.topping">
          <div :class="$style.toppingTitle">
            {{ group.title }}
            <p v-if="group.min_amount">
              Минимальное значение: {{ group.min_amount }}
            </p>
            <p v-if="group.max_amount">
              Максимальное значение: {{ group.max_amount }}
            </p>
            <p v-if="group.free_amount">
              Бесплатное кол-во: {{ group.free_amount }}
            </p>
          </div>
          <div
            v-for="item in group.modifiers"
            :key="item.id"
            :class="$style.toppingItem"
          >
            <div
              :class="[
                $style.minus,
                {
                  [$style.disabled]:
                    item.qty < 1 || item.qty === item.min_amount,
                },
              ]"
              @click="changeQty('minus', item.id, group.id)"
            >
              -
            </div>
            <div :class="$style.itemTitle">
              <span>{{ item.qty + " X" }}</span
              >{{ item.title }}
              <div>{{ item.price }}</div>
            </div>
            <div
              :class="[
                $style.plus,
                {
                  [$style.disabled]:
                    item.qty === item.max_amount || group.plusDisabled,
                },
              ]"
              @click="changeQty('plus', item.id, group.id)"
            >
              +
            </div>
          </div>
        </div>
        <div :class="$style.button" @click="addItem()">Добавить</div>
      </div>
      <div :class="$style.noToppings" v-else>
        У этого товара нет модификаторов
      </div>
      <div :class="$style.close">
        <img
          @click="
            $store.commit('setToppingsModal', {
              isOpen: false,
              item: null,
              type: '',
            })
          "
          src="@/assets/img/icons/close.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/api/menu-item";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      item: null,
      toppings: [],
    };
  },
  async mounted() {
    const item = await MenuItem.getOne(this.addedItem.slug);
    this.item = item.data;
    this.toppings = this.item.modifier_groups;

    this.toppings.forEach((i) => {
      this.$set(i, "plusDisabled", false);
      i.modifiers.forEach((j) => {
        this.$set(j, "qty", 0);
      });
    });

    if (
      typeof this.addedItem.id === "string" &&
      this.addedItem.modifier_groups.length > 0
    ) {
      this.addedItem.modifier_groups.forEach((group) => {
        const item = this.toppings.find((item) => item.title === group.title);
        group.modifiers.forEach((mod) => {
          if (mod.qty > 0) {
            item.modifiers.find((i) => i.title === mod.title).qty = mod.qty;
          }
        });
      });
    } else {
      this.toppings.forEach((i) => {
        if (i.default_amount > 0) {
          let minQty = null;
          i.modifiers.forEach((j) => {
            if (j.default_amount) {
              j.qty = j.default_amount;
              minQty += j.qty;
            }
          });
          if (minQty < i.default_amount) {
            i.modifiers.forEach((j) => {
              if (j.max_amount > j.qty && minQty < i.default_amount) {
                j.qty++;
                minQty++;
              }
            });
          }
        }
      });
    }
    this.toppings.forEach((group) => {
      if (group.free_amount > 0) {
        group.modifiers.forEach((j) => {
          if (j.free_amount > 0) {
            j.price = "0.00";
          }
        });
      }
      group.modifiers.sort((a, b) => a.price - b.price);
    });
  },
  methods: {
    ...mapActions(["addItemToOrder", "addOrderItemTopping"]),
    changeQty(type, itemId, groupId) {
      this.toppings.forEach((i) => {
        if (i.id === groupId) {
          let sumQty = null;
          i.modifiers.forEach((j) => {
            if (j.id === itemId) {
              type === "minus" ? j.qty-- : j.qty++;
            }
            sumQty = j.qty;
          });
          if (i.free_amount > 0) {
            i.modifiers.forEach((j) => {
              if (j.id === itemId && j.free_amount) {
                j.price = "0.00";
              }
            });
          }
          i.max_amount === sumQty
            ? (i.plusDisabled = true)
            : (i.plusDisabled = false);
        }
      });
    },
    async addItem() {
      let toppings = [];
      this.toppings.forEach((i) => {
        let isNew = true;
        let topping = null;
        i.modifiers.forEach((j) => {
          if (j.qty > 0) {
            if (isNew) {
              topping = { modifier_group: i.id, modifiers: [] };
              isNew = false;
            }
            topping.modifiers.push({ modifier: j.id, qty: j.qty });
          }
        });
        if (topping) {
          toppings.push(topping);
          topping = null;
        }

        isNew = true;
      });

      let response = true;

      if (this.$store.state.orderForm.toppingsModal.type === "post") {
        response = await this.addItemToOrder({
          itemId: this.item.slug,
          modifier_groups: toppings,
          item: this.item,
        });
      } else if (this.$store.state.orderForm.toppingsModal.type === "patch") {
        response = await this.addOrderItemTopping({
          itemId: this.$store.state.orderForm.toppingsModal.item.id,
          modifier_groups: toppings,
        });
      }

      if (response) {
        this.$store.commit("setToppingsModal", {
          isOpen: false,
          item: null,
          type: "",
        });
      }
    },
  },
  computed: {
    addedItem() {
      return this.$store.state.orderForm.toppingsModal.item;
    },
  },
};
</script>

<style lang="scss" module>
@keyframes expand {
  from {
    transform: scale(0.7);
    opacity: 0;
    background: #5470b0;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 70;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1.25rem);
    transition: opacity 0.3ms;
    z-index: 70;
    animation: expand 0.2s ease-in-out;
  }
  .container {
    overflow: auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 6.5rem);
    max-width: 500px;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
    z-index: 70;
    animation: expand 0.2s ease-in-out;
    .close {
      position: absolute;
      top: 0;
      right: 0.25rem;
      cursor: pointer;
      z-index: 75;
    }
    .toppings {
      .topping {
        .toppingTitle {
          background-color: rgba(185, 190, 198, 1);
          min-height: 3.5rem;
          padding: 0.25rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-weight: 700;
          p {
            font-size: 0.75rem;
            margin: 0;
          }
        }
        .toppingItem {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .itemTitle {
            border-bottom: 1px solid rgba(185, 190, 198, 1);
            height: 3.5rem;
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-weight: 700;

            span {
              color: rgba(235, 64, 65, 1);
            }
            div {
              font-weight: 400;
            }
          }
          .minus,
          .plus {
            width: 3.5rem;
            flex: 0 0 3.5rem;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: rgba(235, 64, 65, 1);
            background-color: rgba(231, 236, 245, 1);
            border-bottom: 1px solid rgba(185, 190, 198, 1);
            font-size: 1.5rem;
            &.disabled {
              color: rgba(185, 190, 198, 1);
              background-color: white;
              cursor: auto;
              user-select: none;
              pointer-events: none;
            }
          }
          .minus {
            border-right: 1px solid rgba(185, 190, 198, 1);
            font-size: 2rem;
          }
          .plus {
            border-left: 1px solid rgba(185, 190, 198, 1);
          }
        }
      }
    }
    .button {
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(235, 64, 65, 1);
      color: white;
      cursor: pointer;
      font-weight: 700;
    }
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffbfbf;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &::-webkit-scrollbar-track {
      background: white;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    .noToppings {
      padding: 2rem;
      text-align: center;
    }
  }
}
</style>
