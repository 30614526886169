<template>
  <div>
    <form @submit="onSubmit" :class="{ 'is-loading': isLoading }">
      <div :class="$style.input">
        <h4>Название</h4>
        <input v-model="story.title" type="text" />
      </div>
      <div :class="$style.input">
        <h4>Файлы:</h4>
        <div v-if="story.images" :class="$style.images">
          <div v-for="i in story.images" :key="i.id">
            <span
              v-if="storyData && story.images.length > 1"
              @click="removeImage(i.id)"
            >
              <img src="@/assets/img/icons/close.svg" alt="" />
            </span>
            <img :src="i.image" alt="" />
          </div>
        </div>
        <div
          :class="[$style.file, { [$style.selected]: isFile }]"
          class="btn btn--primary btn--size-default"
        >
          <input @change="setFile($event)" type="file" />
          {{ isFile ? "Файл добавлен" : "Добавить файл" }}
        </div>
      </div>

      <div :class="$style.input">
        <h4>Акция действует до:</h4>
        <div :class="[$style.date]">
          <div :class="[$style.from]" class="from">
            <p v-if="story.expiry_date">{{ story.expiry_date }}</p>
            <VueCtkDateTimePicker
              id="from"
              ref="from"
              v-model="story.expiry_date"
              autoClose
              locale="ru"
              label="Дата"
              inputSize="lg"
              color="#34c759"
              minute-interval="1"
              :no-button-now="true"
              format="YYYY-MM-DD HH:mm"
              @is-shown="openPicker"
              :min-date="formatData"
            />
          </div>
        </div>
      </div>
      <div :class="$style.input">
        <h4>Акционный товар</h4>
        <select :class="$style.select" v-model="story.item_slug">
          <option
            v-for="item in related_items"
            :key="item.id"
            :value="item.slug"
          >
            <span>{{ item.title }}</span>
          </option>
        </select>
      </div>
      <div>
        <input
          type="submit"
          value="Сохранить"
          class="btn btn--primary"
          :class="$style.button"
        />
      </div>
    </form>
  </div>
</template>

<script>
import MenuItem from "@/api/menu-item";
import Promo from "@/api/promo";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
export default {
  name: "UserForm",
  props: {
    storyData: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  components: {
    VueCtkDateTimePicker,
  },
  data() {
    return {
      isLoading: false,
      story: {
        title: "",
        item_slug: null,
        expiry_date: null,
        promo_image: null,
        images: [],
      },
      related_items: [],
      from: "",
      formatData: "",
      isFile: false,
    };
  },
  async mounted() {
    this.related_items = await MenuItem.filter({
      city: localStorage.getItem("citySlug"),
    });
  },
  watch: {
    storyData() {
      if (this.storyData) {
        Object.keys(this.story).forEach((field) => {
          this.story[field] = this.storyData[field];
        });
        this.story.expiry_date = this.story.expiry_date
          .slice(0, -1)
          .split("T")
          .join(" ")
          .substr(0, 16);
      }
    },
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      if (this.storyData) {
        window.console.log(this.storyData);
        await Promo.update(this.$route.params.id, this.story);
        await this.$router.go(-1);
      } else {
        const formData = new FormData();
        formData.append("title", this.story.title);
        formData.append("expiry_date", this.story.expiry_date);
        if (this.story.item_slug) {
          formData.append("item_slug", this.story.item_slug);
        }
        formData.append("promo_images", this.story.promo_image);
        const resp = await Promo.create(formData);
        if (resp.data && resp.data.slug) {
          this.$router.push("/stories");
        }
      }
      this.isLoading = false;
    },
    async setFile(e) {
      // const formData = new FormData();
      // formData.set("promo_image", e.target.files[0]);
      if (!this.storyData) {
        this.story.promo_image = e.target.files[0];
        this.isFile = true;
      } else {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const resp = await Promo.addImage(this.$route.params.id, formData);
        window.console.log(resp.data);
        this.story.images.push(resp.data);
      }
    },
    async removeImage(id) {
      await Promo.deleteImage(this.$route.params.id, id);
      this.story.images = this.story.images.filter((i) => i.id != id);
    },
    openPicker() {
      function padTo2Digits(num) {
        return num.toString().padStart(2, "0");
      }

      function formatDate(date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join("-") +
          " " +
          [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(
            ":"
          )
        );
      }

      this.formatData = formatDate(new Date());
    },
  },
};
</script>

<style lang="scss" module>
.button {
  margin: 1.25rem 0 0 0;
  display: inline-block;
}
.input {
  margin: 0 0 1rem 0;
}
.select {
  width: 15.25rem;
}
.file {
  position: relative;
  display: inline-block;
  &.selected {
    background: #000;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.date {
  .from {
    border: 1px solid #e6ecf6;
    width: 15.25rem;
    height: 3rem;
    border-radius: 0.5rem;
    position: relative;
    p {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
    }
    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 5;
    }
    label {
      display: none;
    }
  }
}
.images {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 1.5rem;
  margin: 1rem 0 1.5rem 0;
  div {
    min-height: 10rem;
    position: relative;
    span {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
