<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-3">
            <div class="tab-wrap">
              <settings-tabs />
              <div class="wrap-local">
                <div v-if="user" class="main-block">
                  <user-form :user-data="user" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import User from "@/api/user";
import UserForm from "@/components/users/UserForm";
import SettingsTabs from "@/components/SettingsTabs";

export default {
  name: "UsersEditPage",
  components: { SettingsTabs, UserForm, PageTabs, PageHeader },
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.user = await User.get(
        this.$store.getters.currentDepartment.slug,
        this.$route.params.id
      );
    },
  },
};
</script>

<style scoped></style>
