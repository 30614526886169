import { render, staticRenderFns } from "./OrderCancelPage.vue?vue&type=template&id=10f5e728&scoped=true&"
import script from "./OrderCancelPage.vue?vue&type=script&lang=js&"
export * from "./OrderCancelPage.vue?vue&type=script&lang=js&"
import style0 from "./OrderCancelPage.vue?vue&type=style&index=0&id=10f5e728&prod&scoped=true&lang=sass&"
import style1 from "./OrderCancelPage.vue?vue&type=style&index=1&id=10f5e728&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "10f5e728",
  null
  
)

export default component.exports