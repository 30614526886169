<template>
  <div class="order-display">
    <div class="order-display__row">
      <order-list-item
        v-for="order in orders"
        :order="order"
        :key="order.id"
        :now="now"
        :late-minutes="lateMinutes"
        :step-field="stepField"
        :city="city"
      ></order-list-item>
    </div>
    <ModalWelcome />
  </div>
</template>

<script>
import OrderListItem from "./OrdersListItem";
import Order from "@/api/order";
import City from "@/api/city";
import OrderPushHandlerMixin from "@/mixins/OrderPushHandlerMixin";
import ModalWelcome from "@/components/common/ModalWelcome";
import { differenceInSeconds, sub } from "date-fns";
import { mapGetters } from "vuex";
import NewOrderNotificationMixin from "@/mixins/NewOrderNotificationMixin";

export default {
  name: "OrdersList",
  mixins: [OrderPushHandlerMixin, NewOrderNotificationMixin],
  components: { ModalWelcome, OrderListItem },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    lateMinutes: {
      required: true,
      type: Number,
      default: 0,
    },
    stepField: {
      required: true,
      type: String,
    },
    prevStepField: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      now: Date.now(),
      baseOrdersFilter: {
        department: this.$store.getters.currentDepartment.slug,
      },
      interval: "",
      orders: [],
      isCookingPage: false,
      city: {},
    };
  },
  async created() {
    this.fetchData();
    let path = localStorage.getItem("citySlug");
    this.city = await City.get(path);
    let that = this;
    setInterval(() => {
      that.now = Date.now();
    }, 15000);
    if (this.$route.path === "/cooking") {
      this.isCookingPage = true;
    }
  },
  mounted() {
    this.$modal.show("welcome");
  },
  methods: {
    setOrders() {
      let orders = [];

      if (this.stepField === "is_packed") {
        let allOrders = this.$store.getters.orders.filter(
          (order) =>
            order.department === this.$store.getters.currentDepartment.slug &&
            order.status.status === Order.STATUS.open &&
            order.status[this.stepField] === false
        );
        allOrders.forEach((item) => {
          let isPush = false;
          item.items.forEach((subitem) => {
            if (subitem.is_cooked === true) {
              isPush = true;
            }
          });
          if (isPush) {
            orders.push(item);
            isPush = false;
          }
        });
      } else {
        orders = this.$store.getters.orders.filter(
          (order) =>
            order.status[this.stepField] === false &&
            order.status[this.prevStepField] === true &&
            order.department === this.$store.getters.currentDepartment.slug &&
            order.status.status === Order.STATUS.open
        );
      }

      //создание массива со временем подтвержения заказов для дальнейшей сортировки заказов
      let ordersTimes = [];
      for (let i = 0; i < orders.length; i++) {
        let result;
        if (orders[i].deliver_at) {
          const deliverAt = new Date(orders[i].deliver_at);
          result = sub(deliverAt, {
            minutes: this.currentCity.total_order_processing_duration_minutes,
          });
        } else {
          result = new Date(orders[i].confirmed_at);
        }
        ordersTimes.push(differenceInSeconds(this.now, result));
      }

      //сортировка заказов по времени подтверждения
      for (let i = 0, endI = ordersTimes.length - 1; i < endI; i++) {
        for (let j = 0, endJ = endI - i; j < endJ; j++) {
          if (ordersTimes[j] < ordersTimes[j + 1]) {
            let tempOrders = ordersTimes[j];
            let tempTimes = orders[j];
            ordersTimes[j] = ordersTimes[j + 1];
            orders[j] = orders[j + 1];
            orders[j + 1] = tempTimes;
            ordersTimes[j + 1] = tempOrders;
          }
        }
      }

      //выделение заказов ко времени в отдельный массив
      let delOrders = [];
      for (let i = 0; i < orders.length; i++) {
        let result;
        if (orders[i].deliver_at) {
          const deliverAt = new Date(orders[i].deliver_at);
          result = sub(deliverAt, {
            minutes: this.currentCity.total_order_processing_duration_minutes,
          });
        }
        if (differenceInSeconds(this.now, result) >= 0) {
          delOrders.push(orders[i]);
        }
      }

      //выделение обычных заказов
      let commonOrders = orders.filter((item) => !delOrders.includes(item));

      delOrders.sort(function (a, b) {
        //почему number
        return a.deliver_at - b.deliver_at;
      });

      //объедениение заказов ко времени и обычных
      this.orders = delOrders.concat(commonOrders);
    },
    isNewOnScreen(order) {
      if (this.stepField === "is_cooked") {
        return (
          order.status[this.prevStepField] &&
          order.is_receipt_printed &&
          !order.status[this.stepField]
        );
      } else {
        return (
          order.status[this.prevStepField] && !order.status[this.stepField]
        );
      }
    },
    fetchData() {
      let flatFilter = {};
      for (const [key, value] of Object.entries(this.ordersFilter)) {
        flatFilter[key.split(".").splice(-1, 1)] = value;
      }
      this.$store.dispatch("setOrders", {
        filter: flatFilter,
      });

      this.interval = setInterval(() => {
        let flatFilter = {};
        for (const [key, value] of Object.entries(this.ordersFilter)) {
          flatFilter[key.split(".").splice(-1, 1)] = value;
        }
        this.$store.dispatch("setOrders", {
          filter: flatFilter,
        });
      }, 15000);
    },
  },
  computed: {
    ...mapGetters(["currentCity"]),
    ordersFilter() {
      return { ...this.baseOrdersFilter, ...this.filter };
    },

    getOrders() {
      return this.$store.getters.orders;
    },
  },
  watch: {
    getOrders() {
      this.setOrders();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.isCookingPage = false;
  },
};
</script>

<style scoped>
.order-display__row {
  display: flex;
  margin: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
</style>
