var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-col col-50"},[_c('div',{staticClass:"main-col__row"},[_c('h3',{staticClass:"main-col__row-title color-red"},[_vm._v(_vm._s(_vm.header))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"main-col__row-wrap"},[_c('div',{staticClass:"main-col__row-info"},[_c('p',[_vm._v(_vm._s(_vm.subheader)+" за "+_vm._s(_vm.month)+" "+_vm._s(new Date().getFullYear()))]),_c('p',[_vm._v(" "+_vm._s(_vm.subheader)+" за "+_vm._s(_vm.month)+" "+_vm._s(new Date().getFullYear() - 1)+" ")])]),_c('span',{staticClass:"main-col__subtitle"},[_vm._v("Доставка "),_c('b',{class:{
              'color-red': _vm.barSize.deliveryPercentDiff.startsWith('-'),
            }},[_vm._v(_vm._s(_vm.barSize.deliveryPercentDiff))])]),_c('div',{staticClass:"main-col__statistics main-col__statistics-big"},[_c('div',{staticClass:"main-col__statistics-row",style:(`width: ${_vm.barSize.currentDelivery}%`)},[_c('span',[_vm._v(_vm._s(_vm.month)+" "+_vm._s(new Date().getFullYear()))]),_c('p',[_vm._v(_vm._s(_vm._f("money")(_vm.summaryCurrent["delivery_" + _vm.keyword])))])])]),_c('div',{staticClass:"main-col__statistics"},[_c('div',{staticClass:"main-col__statistics-row",style:(`width: ${_vm.barSize.yearAgoDelivery}%`)},[_c('span',[_vm._v(_vm._s(_vm.month)+" "+_vm._s(new Date().getFullYear() - 1))]),_c('p',[_vm._v(_vm._s(_vm._f("money")(_vm.summaryYearAgo["delivery_" + _vm.keyword])))])])])]),_c('div',{staticClass:"main-col__row-wrap"},[_c('div',{staticClass:"main-col__row-info"},[_c('p',[_vm._v(" "+_vm._s(_vm.summaryCurrent["total_" + _vm.keyword])+" "),_c('b',{class:{
                'color-red': _vm.barSize.revenuePercentDiff.startsWith('-'),
              }},[_vm._v(_vm._s(_vm.barSize.revenuePercentDiff))])]),_c('p',[_vm._v(_vm._s(_vm.summaryYearAgo["total_" + _vm.keyword]))])]),_c('span',{staticClass:"main-col__subtitle"},[_vm._v("Самовывоз "),_c('b',{class:{
              'color-red': _vm.barSize.takeawayPercentDiff.startsWith('-'),
            }},[_vm._v(_vm._s(_vm.barSize.takeawayPercentDiff))])]),_c('div',{staticClass:"main-col__statistics main-col__statistics-big bg-accent"},[_c('div',{staticClass:"main-col__statistics-row",style:(`width: ${_vm.barSize.currentTakeAway}%`)},[_c('span',[_vm._v(_vm._s(_vm.month)+" "+_vm._s(new Date().getFullYear()))]),_c('p',[_vm._v(_vm._s(_vm._f("money")(_vm.summaryCurrent["takeaway_" + _vm.keyword])))])])]),_c('div',{staticClass:"main-col__statistics bg-accent"},[_c('div',{staticClass:"main-col__statistics-row",style:(`width: ${_vm.barSize.yearAgoTakeAway}%`)},[_c('span',[_vm._v(_vm._s(_vm.month)+" "+_vm._s(new Date().getFullYear() - 1))]),_c('p',[_vm._v(_vm._s(_vm._f("money")(_vm.summaryYearAgo["takeaway_" + _vm.keyword])))])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }