<template>
  <div class="order__checkboxes">
    <div class="checkbox" @click="togglePrintReceipt">
      <p>Печатать фискальный чек</p>
      <span :class="{ checked: printReceipt }"></span>
    </div>
    <br />
    <div
      v-if="order.source === 'dashboard' || order.source === 'aggregator'"
      class="checkbox"
      @click="toggleAggregatorOrderSource"
    >
      <p>Заказ из агрегатора</p>
      <span :class="{ checked: isAggregatorOrder }"></span>
    </div>
  </div>
</template>

<script>
import { TOGGLE_PRINT_RECEIPT } from "@/store/modules/order-form/mutation-types";
import Order from "@/api/order";
import { mapGetters } from "vuex";

export default {
  name: "OrderCheckboxes",
  methods: {
    toggleAggregatorOrderSource() {
      let value;
      if (this.isAggregatorOrder) {
        value = Order.SOURCE.dashboard;
      } else {
        value = Order.SOURCE.aggregator;
        this.$store.commit("setPrintReceipt", false);
      }
      let payload = {
        field: "source",
        value: value,
      };
      this.$store.dispatch("setOrderField", payload);
    },
    togglePrintReceipt() {
      this.$store.commit(TOGGLE_PRINT_RECEIPT);
      if (this.isAggregatorOrder) {
        let payload = {
          field: "source",
          value: Order.SOURCE.dashboard,
        };
        this.$store.dispatch("setOrderField", payload);
      }
    },
  },
  computed: {
    ...mapGetters(["printReceipt", "order"]),
    isAggregatorOrder() {
      return this.$store.getters.order.source === Order.SOURCE.aggregator;
    },
  },
};
</script>

<style scoped lang="sass">
.order
    &__checkboxes
        display: flex
        flex-direction: column
        align-items: flex-end
        padding-top: 1.5rem
</style>
