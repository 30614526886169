<template>
  <chart-block
    header="Скорость, %"
    :values="values"
    :labels="labels"
    :width="490"
    :height="490"
  />
</template>

<script>
import ChartBlock from "@/components/analytics/monthly/ChartBlock";
export default {
  name: "SuccessRateChart",
  components: { ChartBlock },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    summary: {
      type: Array,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
  },
  methods: {
    successRate(month) {
      const late_count =
        month.late_kitchen_n_delivery_count +
        month["late_" + this.keyword + "_count"];
      return (100 * late_count) / month.total_count;
    },
  },
  computed: {
    values() {
      const result = [];
      this.summary.forEach((month) => {
        result.push(this.successRate(month));
      });
      return result;
    },
  },
};
</script>

<style scoped></style>
