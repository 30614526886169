<template>
  <ul class="tab-record__list">
    <li>
      <router-link :to="{ name: 'menu-items-list' }">
        <svg-icon name="menu" />
        <span>Меню</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'users-list' }">
        <svg-icon name="users" />
        <span>Персонал</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'schedule' }">
        <svg-icon name="schedule" />
        <span>График работы</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'stories' }">
        <svg-icon name="stories" />
        <span>Сторисы</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
export default {
  name: "SettingsTabs",
  components: { SvgIcon },
};
</script>

<style scoped lang="sass">
.tab-record__list
    min-width: 250px
    padding-right: 0
</style>
