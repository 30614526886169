<template>
  <div
    v-if="isModalOpen"
    :class="$style.wrapper"
    @click="changeIsModalOpen(false), resetForm()"
  >
    <form
      v-if="!loading"
      id="form"
      :class="$style.form"
      @click.stop
      @submit.prevent="submit"
    >
      <img
        @click="changeIsModalOpen(false), resetForm()"
        :class="$style.close"
        src="@/assets/img/icons/close.svg"
        alt=""
      />
      <p>Введите данные для доставки</p>
      <div v-if="zoneError" :class="$style.zoneError">
        {{ zoneError }}
      </div>
      <input
        v-model="firstName"
        :class="{ [$style.error]: errors.firstName }"
        @focus="resetError('firstName')"
        type="text"
        placeholder="Имя"
      />
      <input
        v-model="phone"
        :class="{ [$style.error]: errors.phone }"
        @focus="resetError('phone')"
        type="text"
        placeholder="Телефон"
      />
      <template v-if="!isZone">
        <input
          v-model="street"
          :class="{ [$style.error]: errors.street }"
          @focus="resetError('street')"
          type="text"
          placeholder="Улица"
        />
        <input
          v-model="house"
          :class="{ [$style.error]: errors.house }"
          @focus="resetError('house')"
          type="text"
          placeholder="Дом"
        />
      </template>

      <template v-else>
        <div :class="$style.input">
          <input
            v-model="zoneAddress"
            :class="{ [$style.error]: errors.zoneAddress }"
            @focus="resetError('zoneAddress')"
            type="text"
            placeholder="Улица, дом"
            @input="getSuggest"
            @keyup.enter="setSuggest(null, zoneAddress)"
          />
          <div :class="$style.suggest" v-if="suggest.length">
            <div
              v-for="(item, idx) in suggest"
              :key="idx"
              @click="setSuggest(item.id, item.formatted_address)"
            >
              {{ item.formatted_address }}
            </div>
          </div>
        </div>
      </template>

      <input v-model="porch" type="text" placeholder="Подъезд" />
      <input v-model="door_code" type="text" placeholder="Домофон" />
      <input v-model="floor" type="text" placeholder="Этаж" />
      <input v-model="apartment" type="text" placeholder="Квартира" />
      <input
        v-model="comment"
        :class="{ [$style.error]: errors.comment }"
        @focus="resetError('comment')"
        type="text"
        placeholder="Комментарий"
      />
      <button @click="submit">Сохранить</button>
    </form>
    <div v-if="isLoading" :class="$style.loading">Загрузка...</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import debounce from "debounce";
import City from "@/api/city";
export default {
  data() {
    return {
      comment: null,
      street: "",
      apartment: null,
      floor: null,
      house: null,
      porch: null,
      loading: false,
      firstName: null,
      phone: null,
      door_code: null,
      formatted_address: null,
      errors: {
        street: false,
        house: false,
        firstName: false,
        phone: false,
        zoneAddress: false,
      },
      error: false,
      isZone: "",
      suggest: [],
      zoneError: null,
      zoneAddress: "",
      latitude: null,
      longitude: null,
    };
  },
  created() {
    document.body.appendChild("form");
  },
  async mounted() {
    let path = localStorage.getItem("citySlug");
    const city = await City.get(path);
    this.isZone = city.are_delivery_zones_enabled;
  },
  methods: {
    ...mapMutations(["changeIsModalOpen"]),
    getSuggest: debounce(async function () {
      if (this.zoneAddress) {
        const resp = await this.$store.dispatch("getSuggest", {
          input: localStorage.getItem("cityTitle") + ", " + this.zoneAddress,
          city: localStorage.getItem("citySlug"),
        });
        this.suggest = resp;
      }
    }, 300),
    async setSuggest(id, address) {
      const resp = await this.$store.dispatch("getGeo", {
        id: id,
        address: localStorage.getItem("citySlug") + ", " + address,
        city: localStorage.getItem("citySlug"),
      });

      this.suggest = [];
      this.zoneAddress = resp.components.street + ", " + resp.components.house;
      this.latitude = resp.coordinates.latitude;
      this.longitude = resp.coordinates.longitude;

      const count = await this.$store.dispatch("checkGeo", {
        city: localStorage.getItem("citySlug"),
        coordinates:
          parseFloat(this.latitude) + ", " + parseFloat(this.longitude),
      });

      if (count < 1) {
        this.zoneError = "Адрес не входит в зону доставки или не существует";
      } else {
        this.zoneError = "";
        this.street = this.zoneAddress.split(",")[0].trimStart();
        this.house = this.zoneAddress.split(",")[1].trimStart();
        this.formatted_address = this.zoneAddress;
      }
    },
    submit() {
      if (this.isZone) {
        if (!this.zoneAddress) {
          this.errors.zoneAddress = true;
          this.error = true;
        }
      } else {
        if (!this.street) {
          this.errors.street = true;
          this.error = true;
        }
        if (!this.house) {
          this.errors.house = true;
          this.error = true;
        }
      }

      if (!this.firstName) {
        this.errors.firstName = true;
        this.error = true;
      }
      if (!this.phone) {
        this.errors.phone = true;
        this.error = true;
      }
      if (!this.error && !this.zoneError) {
        this.formatted_address =
          localStorage.getItem("cityTitle") +
          ", улица " +
          this.street +
          ", " +
          this.house;
        let payload = {
          comment: this.comment,
          field: "delivery_type",
          value: "delivery",
          orderId: this.orderId,
          contacts: {
            first_name: this.firstName,
            phone: this.phone,
          },
          address: {
            comment: this.comment,
            street: this.street,
            apartment: this.apartment,
            floor: this.floor,
            house: this.house,
            porch: this.porch,
            door_code: this.door_code,
            formatted_address: this.formatted_address,
            latitude: this.latitude,
            longitude: this.longitude,
          },
        };
        this.$store.dispatch("changeDeliveryType", payload);
        this.resetForm();
      }
    },
    resetError(field) {
      this.errors[field] = false;
      this.error = false;
    },
    resetForm() {
      this.street = "";
      this.apartment = null;
      this.floor = null;
      this.house = null;
      this.porch = null;
      this.errors.street = false;
      this.errors.house = false;
      this.errors.phone = false;
      this.errors.firstName = false;
      this.error = false;
    },
  },
  computed: {
    ...mapGetters(["isModalOpen", "orderId", "isLoading", "order"]),
  },
  watch: {
    isLoading() {
      if (this.isLoading === false) {
        this.changeIsModalOpen(false);
      }
      if (this.isModalOpen === true && this.isLoading === true) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    order() {
      this.firstName = this.order.contacts.first_name;
      this.phone = this.order.contacts.phone;
      this.comment = this.order.comment;
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.3);
  .loading {
    padding: 3rem;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 1rem;
    z-index: 100;
  }
  .form {
    padding: 3rem 2rem 1rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 1rem;
    overflow: auto;
    max-height: calc(100vh - 2rem);
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
      background: #ff2537;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    .zoneError {
      text-align: center;
      color: red;
      font-size: 14px;
      margin: 0 0 0.5rem 0;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    p {
      font-size: 1.5rem;
    }
    input {
      outline: none;
      border: 1px solid transparent;
      background-color: #e6ecf6;
      border-radius: 1rem;
      font-size: 14px;
      margin: 0 0 0.5rem 0;
      width: 20rem;
      padding: 1rem 0.75rem;
      height: 2.75rem;
      &.error {
        border: 1px solid#ff2537;
      }
    }
    .input {
      margin: 0 0 0.5rem 0;
      position: relative;
      input {
        margin: 0;
      }
      .suggest {
        position: absolute;
        top: 3rem;
        left: 0;
        width: 100%;
        z-index: 5;
        background-color: #fff;
        border-radius: 0.625rem;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 700;
        box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
        &::-webkit-scrollbar {
          width: 0.25rem;
        }
        &::-webkit-scrollbar-thumb {
          background: #ff2537;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
        &::-webkit-scrollbar-track {
          background: #fff;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
        > div {
          margin: 0 0 1.25rem 0;
          cursor: pointer;
          &:last-child {
            margin: 0;
          }
        }
        p {
          font-size: 0.875rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.48);
        }
      }
    }
    button {
      outline: 0;
      border: none;
      background-color: #ff2537;
      border-radius: 1rem;
      font-size: 16px;
      padding: 0 0.75rem;
      height: 2.75rem;
      line-height: 18px;
      color: #fff;
      &:active,
      &:focus {
        outline: none;
      }
      &::-moz-focus-inner {
        border: 0;
      }
    }
  }
}
</style>
