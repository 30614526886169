<template>
  <modal name="welcome" height="auto">
    <div class="modal__wrapper">
      <p>
        Проверьте громкость на компьютере, убедитесь в чистоте рабочего места
      </p>
    </div>
    <div class="modal__buttons">
      <button
        type="submit"
        class="btn btn--primary btn--size-default"
        @click="$modal.hide('welcome')"
      >
        ОК
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalWelcome",
};
</script>
