<template>
  <div>
    <div v-if="isLoading">loading...</div>
    <div v-else class="orders-stats">
      <div class="orders-stats__item">
        <svg-icon
          name="checklist"
          class-name="orders-stats__item-icon"
        ></svg-icon>
        <span class="contrast">{{ analytics.done_count || 0 }}</span> /
        <span class="contrast">{{ analytics.canceled_count }}</span>
      </div>
      <div class="orders-stats__item">
        <svg-icon name="flames" class-name="orders-stats__item-icon"></svg-icon>
        <span class="contrast">{{ analytics.late_count || 0 }}</span>
      </div>
      <div class="orders-stats__item">
        <svg-icon
          name="payment-cash"
          class-name="orders-stats__item-icon"
        ></svg-icon>
        <span class="contrast">{{ analytics.cash_amount || 0 }}</span>
      </div>
      <div class="orders-stats__item">
        <svg-icon
          name="payment-pos"
          class-name="orders-stats__item-icon"
        ></svg-icon>
        <span class="contrast">{{ analytics.pos_amount || 0 }}</span>
      </div>
      <div class="orders-stats__item">
        <svg-icon
          name="payment-online"
          class-name="orders-stats__item-icon"
        ></svg-icon>
        <span class="contrast">{{ analytics.online_amount || 0 }}</span>
      </div>
      <div
        @mouseover="isOpen = true"
        @mouseleave="isOpen = false"
        class="orders-stats__item pointer"
      >
        <!-- <svg-icon
          name="payment-cash"
          class-name="orders-stats__item-icon"
        ></svg-icon> -->
        <span class="contrast">{{ analytics.total_amount || 0 }}</span>
        <img class="more-icon" src="@/assets/img/more.svg" alt="" />
        <div class="more-content" v-if="isOpen">
          <div>
            Заказы в работе:
            {{ analytics.open_count }}шт. /
            {{ analytics.open_amount }}
          </div>
          <div>
            Выполненные заказы: {{ analytics.total_count }}шт. /
            {{ parseFloat(analytics.total_amount) }}
          </div>
          <div>
            Общая выручка: {{ analytics.total_count + analytics.open_count }}шт.
            /
            {{
              parseFloat(analytics.open_amount) +
              parseFloat(analytics.total_amount)
            }}
          </div>
        </div>
      </div>
      <div class="orders-stats__item">
        <svg-icon
          name="points-coins"
          class-name="orders-stats__item-icon"
        ></svg-icon>
        <span class="contrast">{{ analytics.points_used_amount || 0 }}</span> /
        <span class="contrast">{{ analytics.points_used_count || 0 }}</span>
      </div>
      <div class="orders-stats__item">
        <svg-icon name="coupon" class-name="orders-stats__item-icon"></svg-icon>
        <span class="contrast">{{ analytics.discount_amount || 0 }}</span> /
        <span class="contrast">{{ analytics.discount_count || 0 }}</span>
      </div>
      <div class="orders-stats__item">
        <svg-icon name="coupon" class-name="orders-stats__item-icon"></svg-icon
        >[pc]
        <span class="contrast">{{ analytics.promo_code_amount || 0 }}</span> /
        <span class="contrast">{{ analytics.promo_code_count || 0 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon";

export default {
  name: "OrdersStats",
  components: {
    SvgIcon,
  },
  props: {
    analytics: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.pointer
  cursor: pointer
.orders-stats
    display: flex
    font-weight: bold
    align-items: center
    line-height: 16px

    &__item
        padding: 0 15px
        display: flex
        align-items: center
        position: relative
        .more-content
          position: absolute
          top: 24px
          left: 16px
          width: 320px
          background-color: #E6ECF6
          padding: 8px
          border-radius: 8px
          cursor: auto
          div
            padding: 8px 0
        .more-icon
          margin: 0 0 0 8px

        &-icon
            margin-right: 15px
</style>
