<template>
  <div>
    <page-header />
    <!-- start main -->
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-1">
            <div class="main-row">
              <revenue-block
                v-if="dailySummaryToday && dailySummaryWeekAgo"
                :summary-today="dailySummaryToday"
                :summary-week-ago="dailySummaryWeekAgo"
              />
              <payment-type-block
                v-if="dailySummaryToday"
                :summary-today="dailySummaryToday"
              />
            </div>
            <line-performance-block
              v-if="dailySummaryToday && ordersByStep"
              :summary-today="dailySummaryToday"
              :orders-by-step="ordersByStep"
            />
            <delivery-performance-block
              v-if="dailySummaryToday"
              :summary-today="dailySummaryToday"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- end main -->
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import RevenueBlock from "@/components/analytics/daily/RevenueBlock";
import PaymentTypeBlock from "@/components/analytics/daily/PaymentTypeBlock";
import Analytics from "@/api/analytics";
import LinePerformanceBlock from "@/components/analytics/daily/LinePerformanceBlock";
import DeliveryPerformanceBlock from "@/components/analytics/daily/DeliveryPerformanceBlock";

export default {
  name: "AnalyticsDailyPage",
  components: {
    DeliveryPerformanceBlock,
    LinePerformanceBlock,
    PaymentTypeBlock,
    RevenueBlock,
    PageTabs,
    PageHeader,
  },
  data() {
    return {
      dailySummaryToday: null,
      dailySummaryWeekAgo: null,
      ordersByStep: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const today = new Date().toJSON().slice(0, 10);
      const weekAgo = new Date();
      weekAgo.setDate(weekAgo.getDate() - 7);
      const weekAgoFormatted = weekAgo.toJSON().slice(0, 10);
      this.dailySummaryToday = await Analytics.dailyOrdersSummary({
        date: today,
        departmentSlug: this.$store.getters.currentDepartment.slug,
      });
      this.dailySummaryWeekAgo = await Analytics.dailyOrdersSummary({
        date: weekAgoFormatted,
        departmentSlug: this.$store.getters.currentDepartment.slug,
      });
      this.ordersByStep = await Analytics.ordersByStep(
        this.$store.getters.currentDepartment.slug
      );
    },
  },
};
</script>

<style lang="sass">
@import "../../../assets/sass/variables"
@import "../../../assets/sass/mixins"

$colorMain: #ff9b9b
$colorAccent: #bbd5ff


*
	margin: 0
	padding: 0
	box-sizing: border-box
	outline: none

a, a:hover, a:focus, a:active, a:visited
	text-decoration: none
	transition: .3s linear

li
	list-style: none

img
	max-width: 100%
	height: auto

button
	cursor: pointer
	transition: .3s linear
	border: none
	background: transparent

input,select
	display: block
	min-width: 120px
	height: 45px
	background-color: #fff
	border: 1px solid $blue-light
	border-radius: 8px
	padding: 0 10px
	font-size: 18px
	font-weight: bold

select
	appearance: none
	font-weight: normal
	width: 100%
	padding-left: 20px
	/*background: url(../img/double-arrow.svg) #f0f0f0 no-repeat 96% center*/
	background-size: 10px

.wrapper
	padding: 0 45px

.main-btn,.second-btn, .light-btn
	display: inline-block
	text-align: center
	min-width: 160px
	border-radius: $border-radius-large
	padding: 0 10px
	font-size: 18px
	line-height: 45px
	color: #fff

.main-btn
	background: $colorMain

.second-btn
	background: $colorAccent

.checkbox
	display: inline-block
	position: relative
	padding: 0 0 0 36px
	margin-bottom: 10px
	span
		display: block
		position: absolute
		top: 0
		left: 0
		border: 5px solid #f0f0f0
		background-repeat: no-repeat
		background-position: center
		transition: .2s linear
		width: 24px
		height: 24px
		border-radius: 50%
		background: #f0f0f0
	p
		font-size: 18px
	input
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		opacity: 0
		cursor: pointer
		z-index: 2
		&:checked
			& + span
				background: $colorMain

/* -- main -- */
.row
	display: flex
	flex-flow: row wrap
	width: 100%
	&-no
		flex-flow: row nowrap

.wrap-local
    width: 100%

.col-dh
	padding: 0 25px

.col-50
	width: 50%
.col-25
	width: 25%

.main
	&-list
		display: flex
		flex-flow: row nowrap
		li
			padding-right: 10px
			a
				display: block
				min-width: 136px
				text-align: center
				border-radius: 15px 15px 0 0
				padding: 0 10px
				font-size: 18px
				font-weight: bold
				line-height: 35px
				color: $blue-dark
				border-bottom: 2px solid $blue-dark
				&.active, &.router-link-exact-active
					color: $brand-color
					border-bottom-color: $brand-color
	&-row
		display: flex
		flex-flow: row wrap
		margin: 0 -23px
	&-block
		@include section
		padding: 30px 50px
	&-col
		padding: 0
		&__row
			@include section
			display: flex
			flex-flow: row wrap
			padding: 30px 25px
			margin-right: 40px
			&-col
				padding: 0 25px
			&-title
				display: block
				width: 100%
				padding-bottom: 15px
				font-size: 24px
				font-weight: bold
				min-height: 45px
			&-wrap
				width: 50%
				padding-right: 10px
			&-info
				padding-bottom: 30px
				p
					font-size: 21px
					font-weight: bold
					b
						padding-left: 10px
						color: $colorMain
		&__top
			min-height: 60px
			padding-bottom: 10px
		&__text
			display: block
			font-size: 21px
			line-height: 1
			font-weight: bold
			&-small
				display: block
				font-size: 21px
				font-weight: normal
		&__subtitle
			display: block
			font-size: 21px
			font-weight: bold
			padding-bottom: 15px
			b
				color: $colorMain
				&.color-red
					color: #ff6851
		&__statistics
			width: 100%
			margin-bottom: 13px
			background-color: rgba(#FADFDF, 0.4)
			color: $brand-color
			border-radius: 8px
			&-row
				width: 80%
				display: flex
				flex-flow: row nowrap
				align-items: center
				justify-content: space-between
				background-color: #FADFDF
				padding: 17px 13px 13px
				border-radius: 8px
			&.bg-red
				background-color: rgba(#ff6851, 0.5)
				.main-col__statistics-row
					background-color: #ff6851
			&.bg-accent
				background-color: rgba(#DAE8FE, 0.4)
				.main-col__statistics-row
					background-color: #DAE8FE
					color: $blue
			&-big
				.main-col__statistics-row
					width: 90%
			span,p
				font-size: 18px
				line-height: 15px
			p
				font-weight: bold
				padding-left: 5px
				white-space: nowrap
		&__row
			&-list
				width: 100%
				max-width: 400px
			&-text
				display: flex
				flex-flow: column wrap
				justify-content: space-between
				max-width: 210px
				min-width: 210px
				padding-bottom: 13px
				p
					width: 100%
					font-size: 21px
					line-height: 1.05
					font-weight: bold
					b
						display: block
						font-size: 31px
						padding-top: 10px
		&__pay
			border-radius: 8px
			margin-bottom: 13px
			background-color: rgba(230, 236, 246, 0.5)
			&-row
				display: flex
				flex-flow: row nowrap
				align-items: center
				justify-content: space-between
				width: 80%
				padding: 8px 15px 10px
				border-radius: 8px
				background-color: rgb(230, 236, 246)
				color: #B8BEC7
				font-weight: bold
			p
				white-space: nowrap
        font-size: 18px
				line-height: 1
				font-weight: bold
				color: #B8BEC7
			&-wrap
				display: flex
				flex-flow: row nowrap
				align-items: center
				div
					display: flex
					flex-flow: row wrap
					align-items: center
					width: 40px
					padding-right: 5px
					span
						font-size: 18px

.main-block__congestion
	padding-right: 150px

.section-title
	display: block
	width: 100%
	padding-bottom: 20px
	font-size: 24px
	font-weight: 800

.col-line
	border: 1px solid $blue-light
	border-radius: 8px
	text-align: center
	margin: 0 18px 18px 0
	padding: 12px 10px 10px
	max-width: 173px
	width: 100%
	min-height: 104px
	b
		font-weight: bold
		padding-bottom: 5px
	b,span
		display: block
		font-size: 18px
	span
		padding-bottom: 5px
	p
		font-size: 12px
		color: $blue
		span
			display: inline-block
			padding: 0
			font-size: 20px

.col-line__wrap
	width: 100%
	padding-bottom: 26px

.col-speed
	max-width: 300px
	min-width: 240px
	ul
		li
			display: flex
			flex-flow: row nowrap
			align-items: center
			justify-content: space-between
			font-size: 18px
			&.text-big
				font-size: 21px
				font-weight: bold

.table
	&-top
		color: $blue-dark
		.table-col
			p
				font-size: 21px
				line-height: 24px
				font-weight: bold
	&-row
		display: flex
		flex-flow: row nowrap
		align-items: center
		justify-content: space-between
		padding: 20px 60px
		border-bottom: 1px solid #ece9e9
	&-col
		width: 16.666%
		padding-top: 8px
		p
			font-size: 18px
			line-height: 23px
			span
				margin-left: 20px
			b
				font-size: 21px
				line-height: 23px
	&.tab-record__table
		.table
			&-row
				padding: 20px 30px
			&-col
				&__1
					width: 25%
				&__2
					width: 25%
				&__3
					width: 50%
	&.tab-record__table-2
		.table
			&-col
				&__1
					width: 45%
				&__2
					width: 25%
				&__3
					width: 30%

.arrow-up,.arrow-down
	position: relative
	padding-left: 22px
	font-weight: bold
	&::after
		content: ''
		position: absolute
		top: 50%
		left: 0
		transform: translate(0,-60%)
		width: 16px
		height: 16px
		background-size: contain

.arrow-up
	color: #bad341
	&::after
		/*background: url(../img/arrow-top.svg) no-repeat center*/

.arrow-down
	color: #ff6851
	&::after
		/*background: url(../img/arrow-bottom.svg) no-repeat center*/


.performance
	&-top
		display: flex
		flex-flow: row nowrap
		align-items: center
		justify-content: space-between
		padding: 0 60px 35px
		p
			font-size: 21px
	&-title
		font-size: 31px
		font-weight: bold

.color-red
	color: $contrast-color !important

.bg-accent
	background-color: $colorAccent

.section
    @include section

.tab
	display: none
	padding: 46px
	&.tab-performance
		padding: 35px 0
	&-wrap
		display: flex
		flex-flow: row nowrap
	&-record
		&__list
			min-width: 520px
			max-width: 520px
			padding-right: 140px
			li
				padding-bottom: 10px
				a
					display: flex
					align-items: center
					border-radius: 8px
					padding: 0 12px
					font-size: 18px
					font-weight: bold
					line-height: 45px
					color: $blue-dark
					fill: $blue-dark !important
					&.active, &.router-link-exact-active
						color: $brand-color
						fill: $brand-color
					span
						margin-left: 15px
		&__content
			display: none
			width: 100%
			&-active
				display: block
		&__top
			display: flex
			flex-flow: row nowrap
			align-items: center
			justify-content: space-between
			padding: 0 23px 0 0
		&__row
			display: flex
			flex-flow: row nowrap
			align-items: center
			padding-bottom: 26px
			&-wrap
				display: flex
				flex-lfow: row nowrap
			input
				text-align: center
				/*max-width: 120px*/
				font-size: 16px
				padding: 0 10px
				min-width: 120px
			select
				margin-right: 14px
				max-width: 208px
				min-width: 200px
			span
				display: block
				min-width: 22px
				width: 22px
				text-align: center
				font-size: 18px
				line-height: 45px
				font-weight: bold
		&__btn
			display: flex
			flex-flow: row nowrap
			padding-bottom: 26px
			li
				padding-left: 15px
			.main-btn,.second-btn
				min-width: 160px
		&__checkbox
			display: flex
			flex-flow: row wrap
			&-title
				display: block
				font-size: 18px
				line-height: 1.33
				font-weight: bold
				color: #494949
				padding-bottom: 20px
				min-height: 43px
			&-col
				min-width: 180px
				padding: 0 20px 33px 0
			.checkbox
				display: block
	&-month
		&__top
			display: flex
			flex-flow: row nowrap
			align-items: center
			justify-content: space-between
			padding-bottom: 25px
			&-calendar
				padding-left: 32px
				position: relative
				p
					font-size: 18px
					line-height: 25px
					font-weight: bold
					color: #ff6851
				&::after
					content: ''
					position: absolute
					top: 50%
					left: 0
					transform: translate(0,-50%)
					width: 25px
					height: 26px
					/*background: url(../img/date.svg) no-repeat center*/
					background-size: contain
			&-list
				display: flex
				flex-flow: row nowrap
				li
					padding-left: 10px
					a
						display: block
						min-width: 136px
						text-align: center
						border-radius: 8px
						background-color: #f0f0f0
						padding: 0 15px
						font-size: 18px
						line-height: 45px
						color: #494949
						&.active, &.router-link-exact-active
							background-color: $colorMain
							color: #fff
	&.tab-record
		padding: 25px 0 25px 50px
	&.tab-active
		display: block

/* -- modal -- */
.modal
	width: 96%
	max-width: 372px
	position: fixed
	top: 50%
	left: 50%
	background: #fff
	z-index: 99999
	border-radius: 15px
	box-shadow: 0 2px 4px 0 #ece9e9
	transform: translate(-50%,-50%) scale(0.2)
	visibility: hidden
	opacity: 0
	transition: all 0.3s
	overflow-y: auto
	padding: 58px 15px 42px
	&-content
		max-width: 256px
		margin: 0 auto
		text-align: center
	&-logo
		padding-bottom: 32px
	&-subtitle
		display: block
		font-size: 18px
		font-weight: bold
		padding-bottom: 25px
	&-list
		li
			padding-bottom: 15px
			a
				display: block
				width: 100%
				font-size: 14px
				line-height: 45px
				color: #494949
				background-color: #f0f0f0
				border-radius: 8px
				&:hover
					background-color: $colorAccent
					color: #fff
	&.open
		visibility: visible
		opacity: 1
		transform: translate(-50%,-50%) scale(1)

.modal-overlay
	position: fixed
	width: 100%
	height: 100%
	visibility: hidden
	opacity: 0
	top: 0
	left: 0
	z-index: 1000
	background: rgba(0,0,0,0.8)
	transition: all 0.3s
	&.open-overlay
		visibility: visible
		opacity: 1

@media only screen and (max-width : 1500px)
	.col-dh
		padding: 0 15px
	.wrapper
		padding: 0 25px
	.tab
		padding: 45px 25px
		&-record
			&__list
				min-width: 350px
				padding-right: 50px
	.main
		&-row
			margin: 0 -15px
		&-col
			padding: 0 15px
			&__row
				&-info
					p
						font-size: 17px

@media only screen and (max-width : 1300px)
	.main
		&-block
			padding: 30px 25px
		&-col
			&__row
				padding: 30px 15px
				&-col
					padding: 0 10px
	.tab
		&.tab-record
			padding-left: 0
		&-wrap
			display: block
		&-record
			&__list
				display: flex
				flex-flow: row nowrap
				max-width: 500px
				padding: 0 0 30px 20px
				text-align: center
				li
					padding: 0 15px 0 0
					width: 100%
			&__top,&__checkbox
				padding-left: 20px

@media only screen and (max-width : 1170px)
	.wrapper
		padding: 0 15px
	.col-25
		width: 50%
		padding: 15px
	.main
		&-col
			width: 100%
			&__small
				.main-col__row
					padding: 30px 25px
	.table
		&-row
			padding: 20px
		&-top
			.table-col
				p
					font-size: 18px
	.performance
		&-top
			padding: 0 20px 35px
			p
				font-size: 18px
		&-title
			font-size: 26px

@media only screen and (max-width : 900px)
	.table
		&-big
			overflow-x: auto
			.table-top,.table-row
				min-width: 950px
	.tab
		&-record
			&__top
				display: block
			&__btn
				li
					padding: 0 10px 0 0

@media only screen and (max-width : 767px)
	.header
		padding: 15px 0
	.tab
		padding: 35px 15px
	.main
		.wrapper
			padding: 0
		&-wrap
			border-radius: 0
		&-block
			&__congestion
				.row
					display: block
					.col-line__wrap
						margin-bottom: 20px
						.row
							display: flex
	.col-speed
		max-width: 300px
	.col-line
		margin: 0 5px 5px 0
		&__wrap
			padding: 0

@media only screen and (max-width : 600px)
	.main
		&-list
			li
				padding-right: 5px
				a
					min-width: 80px
					font-size: 16px
		&-block
			&__congestion
				padding: 30px 15px
		&-col
			&__row
				padding: 15px 0
				margin-bottom: 15px
				&-wrap
					width: 100%
					padding: 0 0 30px
				&-info
					padding-bottom: 15px
			&__top
				min-height: auto
			&__small
				.row
					display: block
				.main-col__row
					padding: 30px 15px
					&-text
						width: 100%
						max-width: 100%
						p
							padding-bottom: 15px
					&-list
						max-width: 100%
	.col-50,.col-25
		width: 100%
		padding: 15px 15px
	.performance
		&-top
			display: block
			padding-bottom: 20px
		&-title
			padding-bottom: 20px
	.tab
		&-record
			&__row
				display: block
				&-wrap
					padding-top: 20px
			&__table
				overflow-x: auto
				.table-row,.table-top
					min-width: 600px
		&-month
			.main-col__row
				padding: 20px 15px 0
			&__top
				display: block
				&-list
					padding-top: 20px
					li
						padding: 0 10px 0 0

@media only screen and (max-width : 400px)
	.main
		&-list
			li
				a
					min-width: 70px
	.tab-record__btn
		.main-btn, .second-btn
			min-width: 140px
</style>
