<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  name: "ChartBlock",
  props: {
    header: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            data: this.values,
            backgroundColor: "rgba(73, 142, 252, 0.1)",
            borderColor: "rgba(73, 142, 252)",
            label: this.header,
          },
        ],
        labels: this.labels,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData);
  },
};
</script>

<style scoped></style>
