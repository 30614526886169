<template>
  <div
    class="order__rest-from-banknote"
    :class="{ readonly: readonly }"
    @click="setRestValue"
  >
    {{ banknote.value }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderRestFromItem",
  props: {
    banknote: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async setRestValue() {
      let payload = {
        field: "payment_info.rest_from",
        value: this.banknote.value,
      };
      this.$store.dispatch("setOrderField", payload);
    },
  },
  computed: {
    ...mapGetters(["order"]),
    readonly() {
      return this.order.payment_info.total_price > this.banknote.value;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.order__rest-from-banknote
    margin: 0 15px
    font-weight: bold
    color: $contrast-color
    cursor: pointer
</style>
