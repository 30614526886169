<template>
  <div class="main-col col-50">
    <div class="main-col__row">
      <div class="main-col__row-col col-50">
        <div class="main-col__top">
          <span class="main-col__text color-red"
            >Выручка общая сейчас {{ totalToday }} {{ currencySymbol }}</span
          >
        </div>
        <span class="main-col__subtitle">Доставка</span>
        <div class="main-col__statistics">
          <div
            class="main-col__statistics-row"
            :style="`width: ${barSize.todayDelivery}%`"
          >
            <span>Сейчас</span>
            <p class="summary">{{ summaryToday.delivery_amount || "0" }}</p>
          </div>
        </div>
        <div class="main-col__statistics main-col__statistics-big">
          <div
            class="main-col__statistics-row"
            :style="`width: ${barSize.weekAgoDelivery}%`"
          >
            <span>Неделю назад</span>
            <p class="summary">{{ summaryWeekAgo.delivery_amount || "0" }}</p>
          </div>
        </div>
      </div>
      <div class="main-col__row-col col-50">
        <div class="main-col__top">
          <span class="main-col__text-small"
            >Неделю назад {{ totalWeekAgo }} {{ currencySymbol }}</span
          >
        </div>
        <span class="main-col__subtitle">Самовывоз</span>
        <div class="main-col__statistics bg-accent">
          <div
            class="main-col__statistics-row"
            :style="`width: ${barSize.todayTakeAway}%`"
          >
            <span>Сейчас</span>
            <p class="summary">{{ summaryToday.takeaway_amount || "0" }}</p>
          </div>
        </div>
        <div class="main-col__statistics main-col__statistics-big bg-accent">
          <div
            class="main-col__statistics-row"
            :style="`width: ${barSize.weekAgoTakeAway}%`"
          >
            <span>Неделю назад</span>
            <p class="summary">{{ summaryWeekAgo.takeaway_amount || "0" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/store/modules/auth/index";

export default {
  name: "RevenueBlock",
  props: {
    summaryToday: {
      type: Object,
      required: true,
    },
    summaryWeekAgo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencySymbol: "",
    };
  },
  mounted() {
    this.currencySymbol = Auth.state.user.country.currency_symbol;
  },
  computed: {
    totalToday() {
      const result =
        parseFloat(this.summaryToday.cash_amount) +
        parseFloat(this.summaryToday.pos_amount) +
        parseFloat(this.summaryToday.online_amount);
      if (isNaN(result)) {
        return "0";
      } else {
        return result;
      }
    },
    totalWeekAgo() {
      const result =
        parseFloat(this.summaryWeekAgo.cash_amount) +
        parseFloat(this.summaryWeekAgo.pos_amount) +
        parseFloat(this.summaryWeekAgo.online_amount);
      if (isNaN(result)) {
        return "0";
      } else {
        return result;
      }
    },
    barSize() {
      const sizes = {
        todayDelivery: null,
        weekAgoDelivery: null,
        todayTakeAway: null,
        weekAgoTakeAway: null,
      };
      if (
        parseFloat(this.summaryToday.delivery_amount) >
        parseFloat(this.summaryWeekAgo.delivery_amount)
      ) {
        sizes.todayDelivery = 100;
        sizes.weekAgoDelivery =
          (parseFloat(this.summaryWeekAgo.delivery_amount) /
            parseFloat(this.summaryToday.delivery_amount)) *
          100;
      } else {
        sizes.weekAgoDelivery = 100;
        sizes.todayDelivery =
          (parseFloat(this.summaryToday.delivery_amount) /
            parseFloat(this.summaryWeekAgo.delivery_amount)) *
          100;
      }
      if (
        parseFloat(this.summaryToday.takeaway_amount) >
        parseFloat(this.summaryWeekAgo.takeaway_amount)
      ) {
        sizes.todayTakeAway = 100;
        sizes.weekAgoTakeAway =
          (parseFloat(this.summaryWeekAgo.takeaway_amount) /
            parseFloat(this.summaryToday.takeaway_amount)) *
          100;
      } else {
        sizes.weekAgoTakeAway = 100;
        sizes.todayTakeAway =
          (parseFloat(this.summaryToday.takeaway_amount) /
            parseFloat(this.summaryWeekAgo.takeaway_amount)) *
          100;
      }
      return sizes;
    },
  },
};
</script>

<style scoped>
.summary {
  margin-bottom: 0;
}
</style>
