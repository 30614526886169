<template>
  <div class="location">
    <div
      v-if="
        (roles.includes('director') || roles.includes('content_manager')) &&
        path
      "
    >
      <select
        v-model="currentDepartmentSlug"
        @change="changeDepartment(currentDepartmentSlug)"
      >
        <option v-for="item in departments" :key="item.id" :value="item.slug">
          <svg-icon name="location"></svg-icon>
          <span>{{ item?.city?.title }}: {{ item.title }}</span>
        </option>
      </select>
    </div>
    <div v-else>
      <svg-icon name="location"></svg-icon>
      <span
        >{{ currentDepartment?.city?.title }}:
        {{ this.$store.getters.currentDepartment.title }}</span
      >
    </div>
    <div v-if="isLoad" :class="$style.loading">
      <div>Загрузка...</div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import User from "@/api/user";
import { SET_USER } from "@/store/modules/auth/mutation-types";

export default {
  name: "LocationSelect",
  components: {
    SvgIcon,
  },
  data() {
    return {
      departments: [],
      date: null,
      isOpen: false,
      currentDepartment: null,
      currentDepartmentSlug: "",
      isLoad: false,
    };
  },
  async mounted() {
    await this.setDate();
    await this.getDepartmens();
    this.currentDepartment = this.departments.find(
      (item) => item.slug === this.departmentSlug
    );
    this.currentDepartmentSlug = this.currentDepartment.slug;

    const city = this.departments.find(
      (item) => item.slug === this.departmentSlug
    ).city;

    this.$store.commit("setChosenSitySlug", city.slug);
    localStorage.setItem("cityTitle", city.title);
    localStorage.setItem("citySlug", city.slug);
    localStorage.setItem("countryId", city.country.id);
  },
  methods: {
    async getDepartmens() {
      this.departments = await User.getAllDepartments();
    },
    setDate() {
      let now = new Date();
      this.date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
    },
    async changeDepartment(currentDepartmentSlug) {
      this.isLoad = true;
      const result = await User.changeUserDepartment(currentDepartmentSlug);
      const user = await User.get(currentDepartmentSlug, result.id);

      this.$store.commit(SET_USER, {
        user: user,
      });
      const city = this.departments.find(
        (item) => item.slug === this.departmentSlug
      ).city;

      this.$store.commit("setChosenSitySlug", city.slug);
      localStorage.setItem("cityTitle", city.title);
      localStorage.setItem("citySlug", city.slug);
      localStorage.setItem("countryId", city.country.id);

      this.isLoad = false;
    },
  },
  computed: {
    departmentSlug() {
      return this.$store.getters.currentDepartment.slug;
    },
    roles() {
      return this.$store.getters.user.groups;
    },
    path() {
      return this.$route.fullPath === "/roles";
    },
  },
};
</script>

<style lang="sass" scoped>
.location
    padding-left: 25px
    font-weight: 600
    display: flex
    align-items: baseline
    span
        margin-left: 5px
select
    position: relative
    top: 10px
</style>
<style lang="scss" module>
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  div {
    padding: 3rem;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 1rem;
    z-index: 100;
    pointer-events: none;
  }
}
</style>
