import { render, staticRenderFns } from "./OrderPaymentType.vue?vue&type=template&id=49a89337&scoped=true&"
import script from "./OrderPaymentType.vue?vue&type=script&lang=js&"
export * from "./OrderPaymentType.vue?vue&type=script&lang=js&"
import style0 from "./OrderPaymentType.vue?vue&type=style&index=0&id=49a89337&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a89337",
  null
  
)

export default component.exports