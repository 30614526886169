<template>
  <div
    class="order-display__col"
    @click="markStepAsDone"
    v-if="isVisible && isItemsHas"
  >
    <div class="loader-wheel" v-show="isLoading"></div>
    <div
      class="order-display__card card"
      :class="{
        'card--delayed': isDelayed,
        'card--warning': isLate,
        'card--success': !isLate,
      }"
    >
      <div
        class="card__content"
        :class="[{ 'card__content--loading': isLoading }]"
      >
        <div class="order-display__card-body card__body">
          <div class="order-display__list">
            <div
              class="order-display__list-item"
              v-for="item in order.items"
              :key="item.id"
            >
              <div class="order-display__list-text">
                <div :class="{ [$style.done]: item.is_cooked }">
                  {{ item.title }}
                </div>
                <div
                  v-if="item.modifier_groups.length > 0"
                  :style="{
                    opacity: '0.5',
                    fontSize: '12px',
                  }"
                >
                  <div v-for="i in item.modifier_groups" :key="i.id">
                    {{ i.title }}:
                    <span v-for="(j, index) in i.modifiers" :key="j.id">
                      {{ j.title + " " + j.qty + "X"
                      }}{{ index !== i.modifiers.length - 1 ? "," : "." }}
                    </span>
                  </div>
                </div>
                <div
                  :style="{
                    fontSize: '12px',
                  }"
                  v-if="item.comment"
                >
                  Комментарий: {{ item.comment }}
                </div>
              </div>
              <div
                v-if="item.qty > 1"
                class="order-display__list-count order-display__list-count--highlighted"
              >
                x {{ item.qty }}
              </div>
            </div>
            <div class="order-display__list-item">
              <div class="order-display__list-comment">{{ order.comment }}</div>
            </div>
            <div
              v-if="order.contacts.table_number"
              class="order-display__list-item"
            >
              <div class="order-display__list-comment">
                Номер стола: {{ order.contacts.table_number }}
              </div>
            </div>
          </div>
        </div>
        <div class="order-display__card-footer card__footer">
          <div class="order-display__card-row order-display__card-row--footer">
            <div class="order-display__card-col col">
              <div class="order-display__order">
                <div class="order-display__order-number">
                  {{ order.number }}
                </div>
                <div class="order-display__order-type">
                  <img
                    class="order-display__order-icon"
                    :src="deliveryIcon"
                    widht="24"
                    height="24"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="order-display__card-col col">
              <div class="order-display__counter">{{ time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { differenceInMinutes, isBefore, formatDistanceStrict } from "date-fns";
import dateFnsRuLocale from "date-fns/locale/ru";
import Order from "@/api/order";
import OrderDateTimeToCountFrom from "@/mixins/OrderDateTimeToCountFrom";

export default {
  name: "OrdersListItem",
  mixins: [OrderDateTimeToCountFrom],
  props: {
    order: Object,
    now: Number,
    lateMinutes: Number,
    stepField: String,
    city: Object
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async markStepAsDone() {
      if (
        differenceInMinutes(this.now, this.dateTimeToCountFrom) &&
        differenceInMinutes(this.now, this.dateTimeToCountFrom) < 0 &&
        this.$route.path === "/cooking"
      ) {
        let result = confirm(
          "У этого заказа более 1 часа до времени доставки, вы точно именно его приготовили?"
        );
        if (result) {
          this.isLoading = true;
          let payload = {
            orderId: this.order.id,
            state: null,
            items: this.ordersIds,
          };
          if (this.stepField === "is_cooked") {
            payload.state = "cooked";
          }
          if (this.stepField === "is_packed") {
            payload.state = "packed";
          }
          await this.$store.dispatch("changeState", payload);
          this.$store.commit("changeState", {
            id: this.order.id,
            field: this.stepField,
          });
          this.isLoading = true;
        }
      } else {
        this.isLoading = true;
        let payload = {
          orderId: this.order.id,
          state: null,
          items: this.ordersIds,
        };
        if (this.stepField === "is_cooked") {
          payload.state = "cooked";
        }
        if (this.stepField === "is_packed") {
          payload.state = "packed";
        }

        await this.$store.dispatch("changeState", payload);
        this.$store.commit("changeState", {
          id: this.order.id,
          field: this.stepField,
        });
        this.isLoading = true;
      }
    },
  },
  computed: {
    transparent_orders_durations_minutes() {
      return this.city
        .transparent_orders_durations_minutes;
    },
    isVisible() {
      return (
        differenceInMinutes(this.now, this.dateTimeToCountFrom) >
        60 - this.transparent_orders_durations_minutes
      );
    },
    isItemsHas() {
      return this.order.items.length > 0;
    },
    isLate() {
      return (
        differenceInMinutes(this.now, this.dateTimeToCountFrom) >=
        this.lateMinutes
      );
    },
    isDelayed() {
      return isBefore(this.now, this.dateTimeToCountFrom);
    },
    time() {
      let distance = formatDistanceStrict(this.now, this.dateTimeToCountFrom, {
        locale: dateFnsRuLocale,
      });
      if (isBefore(this.now, this.dateTimeToCountFrom)) {
        distance = "- " + distance;
      }
      return distance;
    },
    deliveryIcon() {
      let icon = "";
      if (this.order.delivery_type === Order.DELIVERY_TYPE.delivery) {
        icon = "stage-delivering--white.svg";
      } else if (this.order.delivery_type === Order.DELIVERY_TYPE.stay) {
        icon = "stay-cooking.svg";
      } else {
        icon = "stage-taking-away--white.svg";
      }
      return require(`@/assets/img/icons/${icon}`);
    },
    ordersIds() {
      let ids = [];
      this.order.items.forEach((item) => ids.push(item.id));
      return ids;
    },
    isDone() {
      let result = true;
      if (this.$route.path === "/packing") {
        this.order.items.forEach((item) => {
          if (item.is_cooked === false) {
            result = false;
          }
        });
      }
      return result;
    },
  },
};
</script>

<style lang="sass" module>
.done
  text-decoration: line-through
  text-decoration-color: lightgray
  text-decoration-width: 2px
</style>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 35%;
  left: 40%;
}

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.card__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px;
  width: 100%;
}

.card__content--loading {
  opacity: 0.7;
  filter: blur(2px);
}

.card__body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: auto;
  width: 100%;
}

.card__footer {
  padding-top: 16px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: auto;
  width: 100%;
}

.card--success {
  background-color: #b6cc4e;
}

.card--warning {
  background-color: #ff6951 !important;
}

.card--delayed {
  opacity: 0.3;
}

.order-display__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1px;
  cursor: pointer;
  position: relative;
}

.order-display__card {
  min-width: 200px;
  width: 100%;
}

.order-display__card-row--footer {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order-display__card-row .order-display__card-col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.order-display__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.order-display__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.order-display__list-text {
  color: #010101;
  font-size: 14px;
  font-weight: 700;
}

.order-display__list-count {
  margin-left: 8px;
  color: #fff;
  font-weight: 400;
}

.order-display__list-count--highlighted {
  background: #6da4e4;
  border-radius: 15px;
  padding: 1px 10px;
  white-space: nowrap;
}

.order-display__list-comment {
  color: #fff;
  font-weight: 600;
}

.order-display__order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 32px;
}

.order-display__order-number {
  color: #fff;
  font-size: inherit;
  font-weight: 500;
  line-height: 1;
}

.order-display__order-type {
  margin-left: 8px;
}

.order-display__order-icon {
  display: block;
  height: 1em;
  width: 1em;
}

.order-display__counter {
  display: block;
  padding: 8px 16px;
  border-radius: 32px;
  text-align: center;
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
  min-width: 80px;
  background-color: #fff;
}

@media (min-width: 576px) {
  .order-display__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .order-display__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 1024px) {
  .order-display__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .order-display__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}
</style>
