import Order from "@/api/order";
//import OrderForm from "@/store/modules/order-form";
import { isApiError } from "@/utils/api";
import {
  SET_ORDERS,
  UPDATE_ORDERS_ORDER,
  SEEN_WELCOME_POPUP,
  SET_STATS,
} from "./mutation-types";

export default {
  state: {
    orders: [],
    stats: {},
    ordersFilter: {},
    welcomePopupSeen: false,
    loading: false,
    notification: false
  },
  getters: {
    getLoading: (state) => {
      return state.loading
    },
    orders: (state) => {
      return state.orders;
    },
    notification: (state) => {
      return state.notification
    },
    ordersStats: (state) => {
      return state.stats;
    },
    ordersFilter: (state) => {
      return state.ordersFilter;
    },
    ordersDone: (state) => {
      return state.orders.filter((order) => order.status === Order.STATUS.done);
    },
    orderById: (state) => (id) => {
      return state.orders.find((order) => order.id === id);
    },
  },
  mutations: {
    [SET_ORDERS](state, { orders }) {
      state.orders = orders;
    },
    [UPDATE_ORDERS_ORDER](state, { order, isNotification, isNotUpdate }) {
      let orderIndex = state.orders.findIndex((o) => o.id === order.id);
      if (orderIndex > -1) {
        let isVisiableOld = state.orders[orderIndex].payment_info.payment_type === "online"
          && state.orders[orderIndex].payment_info.total_paid < state.orders[orderIndex].payment_info.total_price

        let isVisiableNew = order.payment_info.payment_type === "online" &&
          order.payment_info.total_paid < order.payment_info.total_price

        if (isVisiableOld !== isVisiableNew) {
          state.notification = true
          setTimeout(() => {
            state.notification = false
          }, 350)
        }

        state.orders.splice(orderIndex, 1, order);
      } else {
        if (isNotUpdate === false) {
          if (isNotification) {
            state.notification = true
            setTimeout(() => {
              state.notification = false
            }, 350)
          }
          state.orders.unshift(order);
        }
      }
    },
    [SEEN_WELCOME_POPUP](state) {
      state.welcomePopupSeen = true;
    },
    [SET_STATS](state, { stats }) {
      state.stats = stats;
    },
    changeLoading(state, value) {
      state.loading = value
    },
    resetOrders(state) {
      state.orders = []
    },
    changeState(state, { id, field }) {
      state.orders = state.orders.map(item => {
        if (item.id == id) {
          item.status[field] = true
        }
        return item
      })
    }
  },
  actions: {
    async setOrders({ commit }, { filter, limit, offset, isMount }) {
      let params = Object.assign(filter, limit, offset)
      params.limit = limit
      params.offset = offset

      let orders = await Order.list(params);
      if (isMount) {
        commit(SET_ORDERS, { orders });
        commit('changeLoading', false)
      }

      if (orders.length === 0) {
        return { result: false }
      }
      commit('changeLoading', false)
      commit(SET_ORDERS, { orders });

      return { result: true }
    },

    async confirmListOrder({ commit, getters }, { orderId, noEditing }) {
      const payload = {
        id: orderId,
        status: { is_confirmed: true },
        department: getters.currentDepartment.slug,
        noEditing: noEditing
      };
      let order = await Order.update(payload);
      order = await Order.changeState({ orderId: orderId, state: 'confirmed' })
      const success = !isApiError(order);
      if (success) {
        commit(UPDATE_ORDERS_ORDER, { order });
      }
      return success;
    },
    async removeCourier({ commit }, { orderId }) {
      const payload = {
        id: orderId,
        courier: "remove",
      };
      let order = await Order.update(payload);
      const success = !isApiError(order);
      if (success) {
        commit(UPDATE_ORDERS_ORDER, { order });
      }
      return success;
    },
  },
};
