import { render, staticRenderFns } from "./UsersListPage.vue?vue&type=template&id=170d9764&scoped=true&"
import script from "./UsersListPage.vue?vue&type=script&lang=js&"
export * from "./UsersListPage.vue?vue&type=script&lang=js&"
import style0 from "./UsersListPage.vue?vue&type=style&index=0&id=170d9764&prod&scoped=true&lang=sass&"
import style1 from "./UsersListPage.vue?vue&type=style&index=1&id=170d9764&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "170d9764",
  null
  
)

export default component.exports