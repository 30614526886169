<template>
  <img
    :src="imgPath"
    @click="setOrderPaymentType"
    class="order__payment-types-type"
    alt=""
  />
</template>

<script>
import { SET_ORDER_FIELD } from "@/store/modules/order-form/mutation-types";

export default {
  name: "OrderPaymentType",
  props: {
    paymentType: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setOrderPaymentType() {
      let payload = {
        field: "payment_info.payment_type",
        value: this.paymentType.id,
      };
      this.$store.commit(SET_ORDER_FIELD, payload);
    },
  },
  computed: {
    imgPath() {
      let name = `payment-${this.paymentType.label}`;
      let ext = ".svg";
      if (
        this.$store.getters.order.payment_info.payment_type ===
        this.paymentType.id
      ) {
        name += "--done";
      } else {
        name += "--waiting";
      }
      name += ext;
      return require(`@/assets/img/icons/${name}`);
    },
  },
};
</script>

<style lang="sass" scoped>
.order__payment-types-type
    width: 45px
    height: 45px
    cursor: pointer
    margin: 0 10px
</style>
