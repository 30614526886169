<template>
  <div class="order__items-list" :class="{ readonly: loadingForm }">
    <div
      v-for="item in items"
      :key="item.id"
      :class="{
        readonly: readonly,
      }"
    >
      <order-item :item="item"></order-item>
    </div>
  </div>
</template>

<script>
import OrderItem from "./OrderItem";
import { mapGetters } from "vuex";

export default {
  name: "OrderItemsList",
  components: { OrderItem },
  computed: {
    ...mapGetters(["isPaid", "loadingForm"]),
    items() {
      return this.$store.getters.order.items;
    },
    order() {
      return this.$store.getters.order;
    },
    readonly() {
      return (
        this.order.status.status === "done" ||
        (this.order.delivery_type === "delivery" &&
          this.order.status.is_packed &&
          this.order.courier)
      );
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.order__items-list
    padding: $vertical-padding-default 0
</style>
