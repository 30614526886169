<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-3">
            <div class="tab-wrap">
              <settings-tabs />
              <div class="wrap-local">
                <div class="section">
                  <div v-if="users" class="table table-big">
                    <div class="table-top">
                      <div class="table-row">
                        <div class="table-col table-col__1">
                          <p>№</p>
                        </div>
                        <div class="table-col table-col__2">
                          <p>Имя</p>
                        </div>
                        <div class="table-col table-col__3">
                          <p>Логин</p>
                        </div>
                        <div class="table-col table-col__4">
                          <p>Должность</p>
                        </div>
                        <div class="table-col table-col__5">
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="table-row"
                      v-for="(user, index) in users"
                      :key="user.id"
                    >
                      <div class="table-col table-col__1">
                        <p>
                          <b>{{ index + 1 }}</b>
                        </p>
                      </div>
                      <div class="table-col table-col__2">
                        <p>{{ user.first_name }} {{ user.last_name }}</p>
                      </div>
                      <div class="table-col table-col__3">
                        <p>{{ user.username }}</p>
                      </div>
                      <div class="table-col table-col__4">
                        <p>{{ printUserGroups(user.groups) }}</p>
                      </div>
                      <div class="table-col table-col__5 col-actions">
                        <p>
                          <router-link
                            :to="{
                              name: 'users-edit',
                              params: { id: user.id },
                            }"
                          >
                            <svg-icon name="edit" />
                          </router-link>
                        </p>
                        <div :class="$style.noRemoveLabel">
                          <label
                            class="switch"
                            v-if="!user.groups.includes('manager')"
                          >
                            <input
                              type="checkbox"
                              :checked="user.is_active"
                              :true-value="true"
                              :false-value="false"
                              @change="
                                toggleUserActive(user.id, !user.is_active)
                              "
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <p :class="$style.noRemove">
                          <a
                            v-if="!user.groups.includes('manager')"
                            href="#"
                            @click="deleteUser(user)"
                            ><svg-icon name="remove-alt"
                          /></a>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-actions">
                  <router-link
                    :to="{ name: 'users-create' }"
                    class="btn btn--primary btn--size-default"
                  >
                    <svg-icon name="plus-alt--no-color" /> <span>Добавить</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import User from "@/api/user";
import SvgIcon from "@/components/SvgIcon";
import SettingsTabs from "@/components/SettingsTabs";

export default {
  name: "UsersListPage",
  components: { SettingsTabs, SvgIcon, PageTabs, PageHeader },
  data() {
    return {
      users: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.users = await User.list(this.$store.getters.currentDepartment.slug);
    },
    async toggleUserActive(userId, active) {
      const user = await User.setUserField("is_active", active, userId, this.$store.getters.currentDepartment.slug);
      let userIndex = this.users.findIndex((u) => u.id === user.id);
      this.users.splice(userIndex, 1, user);
    },
    async deleteUser(user) {
      const resp = await User.delete(
        this.$store.getters.currentDepartment.slug,
        user.id
      );
      if (resp.status === 204) {
        this.users = this.users.filter(function (obj) {
          return obj.id !== user.id;
        });
      }
    },
    printUserGroups(groups) {
      const groupValues = {
        courier: "Курьер",
        cook: "Повар",
        packer: "Упаковщик",
        operator: "Оператор",
      };
      const groupsConverted = [];
      groups.forEach((group) => {
        groupsConverted.push(groupValues[group]);
      });
      return groupsConverted.join(", ");
    },
  },
};
</script>

<style scoped lang="sass">
@import "../../assets/sass/variables"

.col-actions
    display: flex
    justify-content: flex-end
    a
        font-size: 24px
        padding: 0 15px
        color: $blue-dark
        &:hover
            color: $brand-color

.table-actions
    display: flex
    justify-content: flex-end
</style>

<style lang="scss" module>
.noRemove {
  width: 2.2rem;
  span {
    width: 100%;
    display: inline-block;
  }
}
.noRemoveLabel {
  width: 50px;
}
</style>
