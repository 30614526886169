<template>
  <div>
    <div v-if="isOrdersLoading">
      <img src="@/assets/img/loader-pacman.svg" alt="" />
    </div>
    <div class="order-list">
      <order-list-item
        v-for="order in orders"
        :order="order"
        :key="order.id"
        :now="now"
        :depCount="depCount"
      ></order-list-item>
    </div>
    <div :class="$style.buttons">
      <div>
        <button
          @click="prev"
          :class="{ [$style.disabled]: offset === 0 }"
          :disabled="offset === 0"
        >
          Предыдущая страница
        </button>
      </div>
      <div>
        <p :class="$style.error" v-if="nextBtn.timer > 0">
          Заказов меньше 100 ({{ nextBtn.timer }})
        </p>
        <button
          @click="next"
          :class="{ [$style.disabled]: nextBtn.timer > 0 }"
          :disabled="nextBtn.timer > 0"
        >
          Следующая страница
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OrderListItem from "@/components/management/OrdersListItem";
import Order from "@/api/order";
import Department from "@/api/department";

export default {
  name: "OrdersList",
  components: { OrderListItem },
  props: {
    ordersFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOrdersLoading: false,
      now: Date.now(),
      params: this.$route.params.date || "",
      limit: 100,
      offset: 0,
      interval: "",
      nextBtn: {
        timer: 0,
        isWork: true,
      },
      prevBtn: {
        timer: 0,
        isWork: true,
      },
      departments: [],
      depCount: false,
      ordersInterval: "",
    };
  },
  async mounted() {
    if (this.$route.params.date) {
      await this.loadData({ value: true, isInterval: false });
    }
    this.departments = await Department.list(
      this.$store.getters.chosenSitySlug,
      false
    );
    this.departments.sort((a) => {
      if (a.id === this.$store.getters.user.department.id) {
        return -1;
      }
    });
    if (this.departments.length > 1) {
      this.depCount = true;
    }
  },
  methods: {
    playNotification() {
      const audio = new Audio(require("@/assets/sounds/new-order.wav"));
      audio.play();
    },
    async prev() {
      this.offset -= 100;
      await this.loadData({ moveTo: "prev", isInterval: false });
      if (this.prevBtn.isWork) {
        window.scrollTo({ top: 0 });
      }
    },
    async next() {
      this.offset += 100;
      await this.loadData({ moveTo: "next", isInterval: false });
      if (this.nextBtn.isWork) {
        window.scrollTo({ top: 0 });
      }
    },
    async loadData({ moveTo, value, isInterval }) {
      let { result } = await this.$store.dispatch("setOrders", {
        filter: this.ordersFilter,
        limit: this.limit,
        offset: this.offset,
        isMount: value,
      });

      if (moveTo === "next" && result === false) {
        this.offset -= 100;
        this.nextBtn.isWork = false;
        this.nextBtn.timer = 15;
        setTimeout(() => {
          this.nextBtn.isWork = true;
        }, 15000);

        this.interval = setInterval(() => {
          this.nextBtn.timer--;
          if (this.nextBtn.timer === 0) {
            clearInterval(this.interval);
          }
        }, 1000);
      }
      if (moveTo === "prev") {
        this.nextBtn.isWork = true;
        this.nextBtn.timer = 0;
        clearInterval(this.interval);
      }
      if (isInterval) {
        this.ordersInterval = setInterval(() => {
          let { result } = this.$store.dispatch("setOrders", {
            filter: this.ordersFilter,
            limit: this.limit,
            offset: this.offset,
            isMount: value,
          });

          if (moveTo === "next" && result === false) {
            this.offset -= 100;
            this.nextBtn.isWork = false;
            this.nextBtn.timer = 15;
            setTimeout(() => {
              this.nextBtn.isWork = true;
            }, 15000);

            this.interval = setInterval(() => {
              this.nextBtn.timer--;
              if (this.nextBtn.timer === 0) {
                clearInterval(this.interval);
              }
            }, 1000);
          }
          if (moveTo === "prev") {
            this.nextBtn.isWork = true;
            this.nextBtn.timer = 0;
            clearInterval(this.interval);
          }
        }, 60000);
      } else {
        clearInterval(this.ordersInterval);
      }
    },
  },
  computed: {
    orders() {
      return this.ordersFilter.status === "open"
        ? this.$store.getters.orders.filter(
            (order) =>
              order.status.status === Order.STATUS.open &&
              (order.department ===
                this.$store.getters.currentDepartment.slug ||
                !order.department)
          )
        : this.$store.getters.orders.filter(
            (order) => order.status.status !== "open"
          );
    },
    notification() {
      return this.$store.getters.notification;
    },
  },
  watch: {
    ordersFilter() {
      if (!this.$route.params.date && !this.ordersFilter.only_for_time) {
        this.loadData({ value: true, isInterval: true });
      } else {
        this.loadData({ value: true, isInterval: false });
      }
    },
    notification() {
      if (this.notification === true) {
        this.playNotification();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.ordersInterval);
  },
};
</script>

<style lang="scss" module>
.buttons {
  display: flex;
  justify-content: center;
  margin: 0 0 4rem 0;
  div {
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: flex-end;
    .error {
      flex: 1 1 auto;
      color: red;
      margin: 2rem 0 0 0;
    }
    button {
      margin: 0 4rem 0 0;
      font-size: 1.5rem;
      outline: none;
      &.disabled {
        cursor: auto;
      }
    }
  }
}
</style>
