<template>
  <div>
    <orders-list
      :filter="ordersFilter"
      :late-minutes="orderLateMinutes"
      :step-field="stepField"
      :prev-step-field="prevStepField"
    />
    <notifications position="bottom right" />
  </div>
</template>

<script>
import OrdersList from "@/components/cooking-packing/OrdersList";
import { mapGetters } from "vuex";

export default {
  components: { OrdersList },
  data() {
    return {
      ordersFilter: {
        stage: "cooking",
      },
      prevStepField: "is_confirmed",
      stepField: "is_cooked",
    };
  },
  computed: {
    ...mapGetters(["currentCity"]),
    orderLateMinutes() {
      return this.currentCity?.cooking_duration_minutes;
    },
  },
};
</script>
