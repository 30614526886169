<template>
  <div
    class="order__rest-from"
    :class="{ readonly: isPaid || order.status.status === 'done' }"
  >
    <div class="fancy-input fancy-input--no-label">
      <p :class="[{ [$style.error]: getIsRestError }, $style.message]">
        неверное значение
      </p>
      <input
        @blur="saveRest"
        v-model.number="restFrom"
        type="number"
        placeholder="купюры"
        class="order__rest-from-input disabled_scroll"
        :class="{
          readonly: isPaid || order.status.status === 'done',
          isRestError: getIsRestError,
        }"
      />
    </div>
    <order-rest-from-item
      v-for="banknote in banknotes"
      :key="banknote.id"
      :banknote="banknote"
    ></order-rest-from-item>
  </div>
</template>

<script>
import Banknote from "@/api/banknote";
import OrderRestFromItem from "@/components/order/order-rest/OrderRestFromItem";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "OrderRestFromList",
  components: { OrderRestFromItem },
  data: () => {
    return {
      banknotes: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.banknotes = await Banknote.list(localStorage.getItem("countryId"));
    },
    async saveRest() {
      let payload = {
        field: "payment_info.rest_from",
        value: this.restFrom,
      };

      if (this.restFrom < this.order.payment_info.total_price) {
        payload.value = 0;
      }

      await this.$store.dispatch("setOrderField", payload);
    },
  },
  computed: {
    ...mapFields({
      restFrom: "order.payment_info.rest_from",
    }),
    ...mapGetters(["isPaid", "getIsRestError", "order"]),
  },
};
</script>

<style lang="sass" scoped>
input.isRestError
  border: 1px solid red !important
.order__rest-from
    display: flex
    align-items: center
    pointer-events: auto !important
    opacity: 1 !important

    &-input
        width: 85px
</style>

<style lang="scss" module>
.message {
  display: none;
  line-height: 1;
  font-size: 12px;
  position: relative;
  top: 6px;
  &.error {
    display: block;
    color: red;
  }
}
</style>

<style>
.disabled_scroll::-webkit-inner-spin-button {
  display: none;
}
</style>
