<template>
  <div class="categories">
    <categories-list-item
      v-for="category in categories"
      :key="category.id"
      :category="category"
    ></categories-list-item>
  </div>
</template>

<script>
import CategoriesListItem from "./CategoriesListItem";
import Category from "@/api/category";
import { mapGetters } from "vuex";

export default {
  name: "CategoriesList",
  components: { CategoriesListItem },
  data: function () {
    return {
      categories: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const categories = await Category.list(localStorage.getItem("citySlug"));
      this.categories = [];
      categories.forEach((category) => {
        if (category.children.length > 0) {
          this.categories.push(...category.children);
        } else {
          this.categories.push(category);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["isPaid"]),
  },
};
</script>

<style lang="sass" scoped>
.categories
    width: 20%
    padding: 0 10px
</style>
