<template>
  <analytics-monthly-page-component
    period="monthly"
    :labels-handler="labelsHandler"
    :chart-start-date="chartStartDate"
  />
</template>

<script>
import AnalyticsMonthlyPageComponent from "@/components/analytics/monthly/AnalyticsMonthlyPageComponent";
export default {
  name: "AnalyticsMonthlyPage",
  components: { AnalyticsMonthlyPageComponent },
  methods: {
    labelsHandler(data) {
      const res = [];
      data.forEach((month) => {
        let dt = new Date(month.date);
        res.push(dt.toLocaleString("default", { month: "long" }));
      });
      return res;
    },
  },
  computed: {
    chartStartDate() {
      const yearAgo = new Date();
      yearAgo.setFullYear(yearAgo.getFullYear() - 1);
      return yearAgo.toJSON().slice(0, 7);
    },
  },
};
</script>
