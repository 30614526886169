<template>
  <div :class="$style.page">
    <div>
      <h2>Выручка по департаментам <span>(за сегодня)</span></h2>
      <div
        v-for="item in departmentsAnalytics"
        :key="item.id"
        :class="$style.department"
      >
        <h4>
          {{ item.city.title }}
          <span>({{ item.slug }})</span>
        </h4>
        <div :class="$style.money">
          <div :style="{ width: item.percent }" :class="$style.shadow"></div>
          <div :class="$style.orders">{{ item.done_orders_count }}</div>
          <div :class="$style.orders">
            {{ item.earning }} {{ item.city.country.currency_symbol }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2>Выручка по странам <span>(за сегодня)</span></h2>
      <div
        v-for="item in countriesAnalytics"
        :key="item.id"
        :class="$style.item"
      >
        <h3>{{ item.title }}:</h3>
        <div :class="$style.weight">
          {{ item.earning }} {{ item.currency_symbol }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from "@/api/analytics";
export default {
  data() {
    return {
      countriesAnalytics: null,
      departmentsAnalytics: null,
      date: null,
      interval: null,
    };
  },
  mounted() {
    this.setDate();
    this.getData();
  },
  methods: {
    async getData() {
      this.countriesAnalytics = await Analytics.countriesOrdersSummary(
        this.date
      );
      this.departmentsAnalytics = await Analytics.departmentsOrdersSummary(
        this.date
      );
    },
    setDate() {
      let now = new Date();
      let mouth = now.getMonth() + 1;
      if (mouth < 10) {
        mouth = `0${mouth}`;
      }
      this.date = `${now.getFullYear()}-${mouth}-${now.getDate()}`;
    },
    sortDepartments() {
      this.departmentsAnalytics
        .sort((a, b) => {
          if (a.city.country.title < b.city.country.title) {
            return -1;
          }
          if (a.city.country.title > b.city.country.title) {
            return 1;
          }
          return 0;
        })
        .reverse();
      this.departmentsAnalytics
        .sort((a, b) => {
          if (
            a.earning < b.earning &&
            a.city.country.title === b.city.country.title
          ) {
            return -1;
          }
          if (
            a.earning > b.earning &&
            a.city.country.title === b.city.country.title
          ) {
            return 1;
          }
          return 0;
        })
        .reverse();
    },
    sortMoney() {
      let maxArr = [];
      let max = {
        value: this.departmentsAnalytics[0].earning,
        country: this.departmentsAnalytics[0].city.country.title,
      };

      let arr = this.departmentsAnalytics;

      for (let i = 1; i < arr.length; i++) {
        if (arr[i].city.country.title === arr[i - 1].city.country.title) {
          max.country = this.departmentsAnalytics[i].city.country.title;
          if (arr[i].earning > arr[i - 1].earning) {
            max.value = arr[i].earning;
          }
        } else {
          maxArr.push({ value: max.value, country: max.country });
          max.value = arr[i].earning;
          max.country = arr[i].city.country.title;
        }
        if (i === arr.length - 1) {
          maxArr.push(max);
        }
      }

      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < maxArr.length; j++) {
          if (arr[i].city.country.title === maxArr[j].country) {
            if (maxArr[j].value === 0) {
              arr[i].percent = "0%";
            } else {
              arr[i].percent = (arr[i].earning / maxArr[j].value) * 100 + "%";
            }
          }
        }
      }
      this.departmentsAnalytics = arr;
    },
    sortCountries() {
      this.countriesAnalytics.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    },
  },
  watch: {
    countriesAnalytics(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sortCountries();
      }
    },
    departmentsAnalytics(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sortDepartments();
        this.sortMoney();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="sass" module>
.page
  padding: 2rem 4rem
  display: grid
  grid-template-columns: 2fr 1fr
  h2
    display: flex
    align-items: center
    margin: 0 0 2rem 0
    span
      font-size: 1rem
      font-weight: 300
      margin: 0.25rem 0 0 0.25rem
.item
  display: flex
  align-items: center
  .weight
    font-weight: 700
    color: #FF2537
  h3
    width: 10rem
    margin: 0 2rem 0 0

.department
  display: flex
  align-items: center
  margin: 0 0 0.25rem 0

  .money
    width: 20rem
    background-color: #f0f6ff
    padding: 0.1rem 0.5rem
    display: flex
    justify-content: space-between
    border-radius: 0.5rem
    position: relative
    overflow: hidden
    font-weight: 700
    .orders
      position: relative
      z-index: 2

    .shadow
      position: absolute
      top: 0
      left: 0
      height: 100%
      background-color: #dae8fe
  h4
    width: 23rem
    margin: 0 2rem 0 0
    span
      font-weight: 300
      margin: 0 0 0 0.5rem
</style>
