import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { USER_DATA_KEY } from "./store/modules/auth";
import { setAuthHeaders } from "@/utils/auth";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VModal from 'vue-js-modal'
import * as GmapVue from 'gmap-vue'
import Notifications from 'vue-notification'
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from "@sentry/tracing";

Vue.use(VModal);
Vue.use(Notifications)

library.add(faExclamationTriangle, faComment);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import "@/assets/sass/common.sass"

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
    Sentry.init({
        dsn: "https://49aad126f5054be39abf7d66e3240d2e@o162416.ingest.sentry.io/5479071",
        integrations: [
            new VueIntegration({
                Vue,
                tracing: false,
            }),
            // new Integrations.BrowserTracing(),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

router.beforeEach((to, from, next) => {
    if (!store.getters.isAuthenticated && to.name !== 'login') {
        next({ name: 'login' });
    }
    else if (store.getters.isAuthenticated && to.path === '/') {
        next({ name: 'roles' });
    }
    else next()
});

let token = localStorage[USER_DATA_KEY] ? JSON.parse(localStorage[USER_DATA_KEY])['auth_token'] : null;
if (token) {
    setAuthHeaders(token)
}

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyA47GRnst5mM9ZaV2oqQRjH5heIj9sILMc',
        // libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'gmap-vue/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
})

Vue.filter('money', (value) => {
    return parseFloat(value).toFixed(2)
})

Vue.filter('percent', (value) => {
    return `${parseFloat(value).toFixed(2)}%`
})

Vue.filter('duration', (value) => {
    return value.split('.')[0]
})

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');