<template>
  <div class="category-items" :class="{ readonly: loadingForm }">
    <menu-item
      v-for="item in getSortedItems"
      :key="item.id"
      :item="item"
    ></menu-item>
  </div>
</template>

<script>
import MenuItem from "./MenuItem";
import { mapGetters } from "vuex";

export default {
  name: "MenuItemList",
  components: { MenuItem },
  computed: {
    ...mapGetters({
      items: "categoryMenuItems",
      loadingForm: "loadingForm",
    }),
    getSortedItems() {
      let arr = this.items;
      arr.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      return arr;
    },
  },
};
</script>

<style lang="sass" scoped>
.category-items
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    width: 40%
</style>
