<template>
  <div :class="$style.loading">
    <div :class="$style.overlay"></div>

    <div :class="$style.loader">
      <div :class="[{ [$style.visible]: cross }, $style.cross]" @click="close">
        <img src="@/assets/img/icons/close-white.svg" alt="" />
      </div>
      Загрузка...
    </div>
  </div>
</template>

<script>
import { SET_SAVE_LOADING } from "@/store/modules/order-form/mutation-types";
export default {
  data() {
    return {
      cross: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.cross = true;
    }, 10000);
  },
  methods: {
    close() {
      this.$store.commit(SET_SAVE_LOADING, false);
    },
  },
};
</script>

<style lang="scss" module>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 70;
  background-color: rgba(0, 0, 0, 0.3);
  .cross {
    position: absolute;
    top: -1rem;
    right: -1rem;
    cursor: pointer;
    z-index: 80;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1.25rem);
    z-index: 70;
  }
  .loader {
    padding: 3rem;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 1rem;
    z-index: 100;
  }
}
</style>
