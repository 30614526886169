<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-3">
            <div class="tab-wrap">
              <settings-tabs />
              <div class="wrap-local" :class="{ 'is-loading': isLoading }">
                <div class="section">
                  <b>Поиск по названию:</b>
                  <input
                    type="text"
                    v-model.lazy="searchRaw"
                    :disabled="this.isLoading ? true : false"
                    v-debounce="2000"
                  />
                </div>
                <div class="section">
                  <div v-if="menuItems" class="table table-big">
                    <div class="table-top">
                      <div class="table-row">
                        <div class="table-col table-col__1">
                          <p>Фото</p>
                        </div>
                        <div class="table-col table-col__2">
                          <p>Название</p>
                        </div>
                        <div class="table-col">
                          <p>Описание</p>
                        </div>
                        <div class="table-col table-col__4">
                          <p>Цена</p>
                          <p>Цена аггрегатора</p>
                        </div>
                        <div class="table-col table-col__5">
                          <p>Статус</p>
                        </div>
                      </div>
                    </div>
                    <div v-for="item in menuItems" :key="item.id">
                      <div
                        class="table-row"
                        :class="{
                          [$style.hasMod]: item.modifier_groups.length,
                        }"
                      >
                        <div class="table-col table-col__1">
                          <p>
                            <img v-if="item.image" :src="item.image" alt="" />
                            <img
                              v-else
                              src="@/assets/img/placeholder.svg"
                              alt=""
                            />
                          </p>
                        </div>
                        <div class="table-col table-col__2">
                          <p>{{ item.title }}</p>
                        </div>
                        <div class="table-col">
                          <div class="fancy-input fancy-input--no-label">
                            <textarea
                              class="textarea-description"
                              type="text"
                              :value="item.description"
                              :data-item-id="item.slug"
                              @change="updateDescription"
                            />
                          </div>
                        </div>
                        <div class="table-col table-col__4">
                          <div class="fancy-input fancy-input--no-label">
                            <input
                              type="text"
                              :value="item.price"
                              :data-item-id="item.slug"
                              @change="updatePrice"
                            />
                          </div>
                          <div class="fancy-input fancy-input--no-label">
                            <input
                              type="text"
                              :value="item.aggregator_price"
                              :data-item-id="item.slug"
                              @change="updateAgPrice"
                            />
                          </div>
                        </div>
                        <div
                          class="table-col table-col__5 col-actions"
                          :class="$style.tableCol"
                        >
                          <label class="switch">
                            <input
                              type="checkbox"
                              :checked="item.is_active"
                              :true-value="true"
                              :false-value="false"
                              :data-item-id="item.slug"
                              @change="setIsActive"
                            />
                            <span class="slider round"></span>
                          </label>
                          <button
                            @click="openConfirm(item)"
                            :class="$style.noRemove"
                          >
                            <svg-icon name="remove-alt"></svg-icon>
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="item.modifier_groups.length"
                        :class="$style.modifierGroups"
                      >
                        <div
                          v-for="group in item.modifier_groups"
                          :key="group.id"
                          :class="$style.modifierGroup"
                        >
                          <div :class="$style.modifiers">
                            <div
                              :class="$style.modifier"
                              v-for="mod in group.modifiers"
                              :key="mod.id"
                            >
                              <p>{{ mod.title }}</p>
                              <div class="fancy-input fancy-input--no-label">
                                <input
                                  type="text"
                                  :value="mod.price"
                                  :data-item-id="item.slug"
                                  :data-group-id="group.id"
                                  :data-mod-id="mod.id"
                                  @change="updateModPrice"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pagination">
                    <button
                      class="btn btn--secondary btn--size-default"
                      @click="changePage('prev')"
                    >
                      Назад
                    </button>
                    <button
                      class="btn btn--secondary btn--size-default"
                      @click="changePage('next')"
                    >
                      Вперед
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="isOpen" :class="$style.modal">
      <div :class="$style.content">
        <h3>Вы уверены?</h3>
        <div :class="$style.buttons">
          <div
            @click="isOpen = false"
            class="btn btn--secondary btn--size-default"
          >
            Отмена
          </div>
          <div @click="deleteItem" class="btn btn--primary btn--size-default">
            Удалить
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import SettingsTabs from "@/components/SettingsTabs";
import MenuItem from "@/api/menu-item";
import SvgIcon from "@/components/SvgIcon";
import debounce from "v-debounce";

export default {
  name: "MenuItemsListPage",
  components: { SettingsTabs, PageTabs, PageHeader, SvgIcon },
  directives: { debounce },
  data() {
    return {
      menuItems: null,
      searchRaw: null,
      isLoading: false,
      filter: {
        search: null,
        city: localStorage.getItem("citySlug"),
        offset: 0,
        limit: 10,
      },
      chosenItem: null,
      isOpen: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.menuItems = await MenuItem.filter(this.filter);
      this.isLoading = false;
    },
    changePage(direction) {
      const filterUpdated = JSON.parse(JSON.stringify(this.filter));
      if (direction === "next") {
        filterUpdated.offset = filterUpdated.offset + filterUpdated.limit;
      } else {
        filterUpdated.offset = filterUpdated.offset - filterUpdated.limit;
      }
      this.filter = filterUpdated;
    },
    async updatePrice(event) {
      this.isLoading = true;
      const menuItem = this.menuItems.find(
        (f) => f.slug === event.target.dataset.itemId
      );
      const newPrice = event.target.value;
      const oldPrice = menuItem.price;
      menuItem.price = newPrice;
      const resp = await MenuItem.update(
        event.target.dataset.itemId,
        { price: newPrice },
        this.filter.city
      );
      if (resp.status !== 200) {
        menuItem.price = oldPrice;
      }
      this.isLoading = false;
    },
    async updateAgPrice(event) {
      this.isLoading = true;
      const menuItem = this.menuItems.find(
        (f) => f.slug === event.target.dataset.itemId
      );
      const newPrice = event.target.value;
      const oldPrice = menuItem.aggregator_price;
      menuItem.aggregator_price = newPrice;
      const resp = await MenuItem.update(
        event.target.dataset.itemId,
        { aggregator_price: newPrice },
        this.filter.city
      );
      if (resp.status !== 200) {
        menuItem.aggregator_price = oldPrice;
      }
      this.isLoading = false;
    },
    async updateModPrice(event) {
      this.isLoading = true;
      this.menuItems.forEach((item) => {
        item.modifier_groups.forEach((group) => {
          group.modifiers.forEach((mod) => {
            if (mod.id === event.target.dataset.modId) {
              mod.price = event.target.value;
            }
          });
        });
      });
      await MenuItem.updateModifier(
        event.target.dataset.itemId,
        event.target.dataset.groupId,
        event.target.dataset.modId,
        { price: event.target.value }
      );

      this.isLoading = false;
    },
    async updateDescription(event) {
      this.isLoading = true;
      const menuItem = this.menuItems.find(
        (f) => f.slug === event.target.dataset.itemId
      );
      const newDescription = event.target.value;
      const oldDescription = menuItem.description;
      menuItem.description = newDescription;
      const resp = await MenuItem.update(
        event.target.dataset.itemId,
        { description: newDescription },
        this.filter.city
      );
      if (resp.status !== 200) {
        menuItem.description = oldDescription;
      }
      this.isLoading = false;
    },
    async setIsActive(event) {
      this.isLoading = true;
      await MenuItem.update(
        event.target.dataset.itemId,
        { is_active: event.target.checked },
        this.filter.city
      );
      this.isLoading = false;
    },
    async deleteItem() {
      this.isLoading = true;
      await MenuItem.delete(this.chosenItem.slug, this.filter.city);
      this.fetchData();
      this.isLoading = false;
      this.chosenItem = null;
      this.isOpen = false;
    },
    openConfirm(item) {
      this.isOpen = true;
      this.chosenItem = item;
    },
  },
  watch: {
    searchRaw() {
      const filterUpdated = JSON.parse(JSON.stringify(this.filter));
      filterUpdated.search = this.searchRaw;
      filterUpdated.offset = 0;
      this.filter = filterUpdated;
    },
    filter: {
      deep: true,
      handler() {
        return this.fetchData();
      },
    },
  },
};
</script>

<style scoped lang="sass">
.textarea-description
  min-height: 150px
.pagination
    display: flex
    justify-content: flex-end

    .btn
        margin-left: 15px
        margin-top: 10px
</style>

<style lang="scss" module>
@keyframes expand {
  from {
    transform: scale(0.7);
    opacity: 0;
    background: #5470b0;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3ms;
  z-index: 5;
  animation: expand 0.3s ease-in-out;
  .content {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    padding: 2rem 0;
    text-align: center;
    border-radius: 2rem;
    .buttons {
      margin: 2rem 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
}
.tableCol {
  display: flex;
  align-items: center;
}

.noRemove {
  font-size: 22px;
  color: #b8bec7;
  margin-left: 1rem;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #ff2537;
  }
}
.noRemoveLabel {
  width: 50px;
}
.modifierGroups {
  border-bottom: 1px solid #ece9e9;
  .modifierGroup {
    margin: 0 0 0.5rem 0;
    .modifiers {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      .modifier {
        input {
          margin: -1rem 0 0 0;
        }
      }
    }
  }
}
.hasMod {
  border: none !important;
}
</style>
