<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active tab-month" id="tab-2">
            <div class="tab-month__top">
              <div class="tab-month__top-calendar">
                <p>
                  {{
                    this.$route.params.id
                      ? "Редактирование акции"
                      : "Добавление акции"
                  }}
                </p>
              </div>
            </div>
            <div class="main-block">
              <story-form :storyData="storyData" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import StoryForm from "@/components/stories/StoryForm";
import Promo from "@/api/promo";
export default {
  components: { StoryForm, PageTabs, PageHeader },
  data() {
    return {
      storyData: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      const resp = await Promo.getOne(this.$route.params.id);
      if (resp.data) {
        this.storyData = resp.data;
      }
    },
  },
};
</script>
