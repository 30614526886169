import Order from "@/api/order";
import Department from "@/api/department";
import { addMinutes, format } from "date-fns";
//import { kkmExecuteSuccess } from "@/utils/kkm/kkmCallback";

export default async function printReceipt({
  order,
  router,
  store,
  notCopies,
  isNotFiscal,
  isFiscal,
  isChangeType,
  beforeCancel,
  isCanceled,
  reasonId,
  comment,
}) {
  // print
  if ("KkmServer" in window) {
    if (router.history.current.name !== "operator") {
      router.push({ name: "operator" });
    }
    await printReceiptByKKM({
      order,
      store,
      notCopies,
      isNotFiscal,
      isFiscal,
      isChangeType,
      router,
      beforeCancel,
      isCanceled,
      reasonId,
      comment,
    });
  } else {
    router.push({
      name: "order-receipt-page",
      params: { id: order.id },
    });
  }
}

export function buildAddressString(order) {
  let addressStr = "";
  if (order.delivery_type === Order.DELIVERY_TYPE.delivery) {
    addressStr += `${order.address.street} ${order.address.house}`;
    if (order.address.porch) {
      addressStr += `, подъезд: ${order.address.porch}`;
    }
    if (order.address.door_code) {
      addressStr += `, домофон: ${order.address.door_code}`;
    }
    if (order.address.floor) {
      addressStr += `, этаж: ${order.address.floor}`;
    }
    if (order.address.apartment) {
      addressStr += `, квартира: ${order.address.apartment}`;
    }
  } else if (order.delivery_type === Order.DELIVERY_TYPE.takeaway) {
    addressStr = "самовывоз";
  }
  else {
    addressStr = `номер стола: ${order.contacts.table_number}`;
  }
  return addressStr;
}

export function receiptOrderDate(dtString) {
  return format(new Date(dtString), "dd.MM.yyyy");
}

export function receiptOrderTime(dtString) {
  return format(new Date(dtString), "HH:mm");
}

export function receiptOrderCookedTime(dtString) {
  return format(addMinutes(new Date(dtString), 20), "HH:mm");
}

async function printReceiptByKKM({
  order,
  store,
  notCopies,
  isNotFiscal,
  isFiscal,
  isChangeType,
  router,
  beforeCancel,
  isCanceled,
  reasonId,
  comment,
}) {
  let department = await Department.get(order.department);

  let isFiscalCheck = true;

  //Для печати нефискальных чеков при условии !is_receipt_printed и в кейсах,
  //где при создании заказы нет печати сразу, но указан print_a_slip_check_anyway
  if (
    order.is_receipt_printed ||
    (department.print_a_slip_check_anyway && isNotFiscal) ||
    isNotFiscal
  ) {
    isFiscalCheck = false;
  }
  //Для печати фискальных чеков при условии is_receipt_printed и чеков возврата при смене типа оплаты
  if (
    order.status.status === "canceled" ||
    isChangeType ||
    isFiscal ||
    isCanceled
  ) {
    isFiscalCheck = true;
  }
  //Для заказов из аггрегатора всегда нефискальные чеки
  if (order.source === "aggregator") {
    isFiscalCheck = false;
  }

  const Data = window.KkmServer.GetDataCheck();

  // Время (сек) ожидания выполнения команды
  Data.Timeout = 300;

  // Это фискальный или не фискальный чек
  Data.IsFiscalCheck = isFiscalCheck;

  // Тип чека
  // 0 – продажа
  // 1 – возврат продажи
  Data.TypeCheck = 0;
  if (order.status.status === "canceled" || isChangeType || isCanceled) {
    Data.TypeCheck = 1;
  }

  // Не печатать чек на бумагу
  Data.NotPrint = false;

  // Количество копий документа
  if (notCopies) {
    Data.NumberCopies = 0;
  } else {
    Data.NumberCopies = department.kkm_receipt_copies;
  }

  Data.CashierName = department.kkm_cashier_name;
  Data.CashierVATIN = department.kkm_cashier_tax_number;

  Data.CheckStrings = [
    { PrintText: { Text: `Номер заказа: ${order.number}` }, Font: 3 },
    {
      PrintText: {
        Text: `Время заказа: ${receiptOrderTime(order.confirmed_at)}`,
      },
      Font: 1,
    },
    // {
    //   PrintText: {
    //     Text: `Время приготовления: ${receiptOrderCookedTime(
    //       order.confirmed_at
    //     )}`,
    //   },
    //   Font: 1,
    // },
  ];
  if (order.contacts.first_name) {
    Data.CheckStrings.push({
      PrintText: { Text: `Клиент: ${order.contacts.first_name}` },
      Font: 1,
    });
  }
  if (order.contacts.phone) {
    Data.CheckStrings.push({
      PrintText: { Text: `Телефон: ${order.contacts.phone}` },
      Font: 1,
    });
  }
  if (order.delivery_type === Order.DELIVERY_TYPE.delivery) {
    Data.CheckStrings.push({
      PrintText: { Text: `Адрес: ${buildAddressString(order)}` },
      Font: 1,
    });
  }
  Data.CheckStrings.push({
    PrintText: { Text: `Кол-во персон: ${order.persons_qty}` },
    Font: 1,
  });

  if (order.comment) {
    Data.CheckStrings.push({
      PrintText: { Text: `Комментарий: ${order.comment}` },
      Font: 1,
    });
  }
  Data.CheckStrings.push({ PrintText: { Text: "<<->>" } });

  //списать скидку на весь чек нельзя, поэтому раскидываем ее по товарам

  let discountToWriteOff = (
    order.payment_info.subtotal - order.payment_info.total_price
  ).toFixed(2);

  const discountedItemsAmount = {};
  let oldSum = 0;
  let newSum = 0;

  order.items.forEach((item) => {
    let price = 0;
    order.source === "aggregator"
      ? (price = item.aggregator_price_per_item)
      : (price = item.price_per_item);
    let itemPrice = parseFloat(price) * item.qty;
    oldSum += itemPrice;
  });

  order.items.forEach((item) => {
    let price = 0;
    order.source === "aggregator"
      ? (price = item.aggregator_price_per_item)
      : (price = item.price_per_item);
    let itemPrice = parseFloat(price);
    discountedItemsAmount[item.id] = (
      itemPrice -
      (itemPrice / oldSum) * discountToWriteOff
    ).toFixed(2);
    if (item.qty > 1) {
      discountedItemsAmount[item.id] =
        discountedItemsAmount[item.id] * item.qty;
    }
  });

  Object.values(discountedItemsAmount).forEach((i) => {
    newSum += Number(i);
  });
  newSum += Number(discountToWriteOff);

  let difference = newSum.toFixed(2) - oldSum.toFixed(2);

  if (difference !== 0) {
    const item = order.items.find(
      (i) =>
        i.qty == 1 &&
        i.price_per_item > 0 &&
        i.price_per_item > difference.toFixed(2)
    );
    if (item) {
      discountedItemsAmount[item.id] =
        discountedItemsAmount[item.id] - difference.toFixed(2);
    } else {
      order.items.every((item) => {
        if (
          discountedItemsAmount[item.id] > 0 &&
          discountedItemsAmount[item.id] > difference.toFixed(2)
        ) {
          discountedItemsAmount[item.id] =
            discountedItemsAmount[item.id] - difference.toFixed(2);
          return false;
        }
        return true;
      });
    }
  }
  order.items.forEach((item) => {
    discountedItemsAmount[item.id] = Number(
      discountedItemsAmount[item.id]
    ).toFixed(2);
  });

  order.items.forEach((item) => {
    let registerItem = {
      // Наименование товара
      Name: item.title,
      // Количество товара
      Quantity: item.qty,
      // Цена за шт. без скидки
      Price:
        order.source === "aggregator"
          ? item.aggregator_price_per_item
          : item.price_per_item,

      Amount: discountedItemsAmount[item.id],
      // Отдел, по которому ведется продажа
      Department: 0,
      // НДС в процентах или ТЕГ НДС: -1 (НДС не облагается)
      Tax: -1,
      // Признак способа расчета
      // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
      // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
      SignMethodCalculation: 4,
      // Признак предмета расчета
      // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
      SignCalculationObject: 1,
    };
    Data.CheckStrings.push({ Register: registerItem });
    if (!isFiscalCheck) {
      Data.CheckStrings.push({
        PrintText: {
          Text: `${registerItem.Name} x ${registerItem.Quantity}: ${registerItem.Amount}`,
        },
        Font: 1,
      });
      Data.CheckStrings.push({ PrintText: { Text: " " } });
    }
  });

  if (!isFiscalCheck) {
    Data.CheckStrings.push({ PrintText: { Text: "<<->>" } });
  }

  if (order.payment_info.payment_type === Order.PAYMENT_TYPE.online) {
    if (!isFiscalCheck) {
      Data.CheckStrings.push({
        PrintText: { Text: `ИТОГО: ${order.payment_info.total_paid}` },
        Font: 1,
      });
    }
    Data.ElectronicPayment = order.payment_info.total_paid;
    Data.PayByProcessing = false;
  } else if (order.payment_info.payment_type === Order.PAYMENT_TYPE.pos) {
    if (!isFiscalCheck) {
      Data.CheckStrings.push({
        PrintText: { Text: `ИТОГО: ${order.payment_info.total_price}` },
        Font: 4,
      });
    }
    Data.PayByProcessing = department.kkm_pay_by_processing;
    Data.ElectronicPayment = order.payment_info.total_price;
  } else {
    if (parseFloat(order.payment_info.rest_from) > 0) {
      if (!isFiscalCheck) {
        Data.CheckStrings.push({
          PrintText: { Text: `ИТОГО: ${order.payment_info.total_price}` },
          Font: 4,
        });
        Data.CheckStrings.push({
          PrintText: { Text: `ПОЛУЧЕНО: ${order.payment_info.rest_from}` },
          Font: 4,
        });
        Data.CheckStrings.push({
          PrintText: {
            Text: `СДАЧА: ${order.payment_info.rest_from - order.payment_info.total_price
              }`,
          },
          Font: 4,
        });
      }
      Data.Cash = order.payment_info.rest_from;
    } else {
      if (!isFiscalCheck) {
        Data.CheckStrings.push({
          PrintText: { Text: `ИТОГО: ${order.payment_info.total_price}` },
          Font: 4,
        });
      }
      Data.Cash = order.payment_info.total_price;
    }
    Data.PayByProcessing = false;
  }

  let discount = (
    parseFloat(order.payment_info.subtotal_with_discounts) -
    parseFloat(order.payment_info.subtotal)
  ).toFixed(2);

  Data.CheckStrings.push({
    PrintText: { Text: `Скидка: ${discount > 0 ? "+" + discount : discount}` },
    Font: 1,
  });

  let service_percent_amount = parseFloat(
    order.payment_info.service_percent_amount
  );

  if (service_percent_amount !== 0) {
    Data.CheckStrings.push({
      PrintText: { Text: `Обслуживание: ${service_percent_amount}` },
      Font: 1,
    });
  }

  Data.Execute(async (result) => {
    if (result.Error) {
      if (beforeCancel) {
        let payload = {
          field: "is_receipt_printed",
          value: false,
          orderId: order.id,
          isCheck: true,
        };
        await store.dispatch("setOrderField", payload);
      }
      alert("Ошибка при печати чека. " + result.Error);
    } else {
      if (isChangeType) {
        let ptype;
        if (order.payment_info.payment_type === "pos") {
          ptype = "cash";
        } else {
          ptype = "pos";
        }
        let payload = {
          field: "payment_info.payment_type",
          value: ptype,
          orderId: order.id,
        };
        await store.dispatch("changePaymentType", payload);
        let newOrder = order;
        newOrder.payment_info.payment_type = ptype;
        setTimeout(() => {
          printReceipt({
            order: newOrder,
            router: router,
            store: store,
            isFiscal: true,
            notCopies: true,
            beforeCancel: true,
          });
        }, 1000);
      }
      if (isCanceled) {
        const payload = {
          orderId: order.id,
          reasonId: reasonId,
          comment: comment,
        };
        await store.dispatch("cancelOrder", payload);
      }
      if (isFiscalCheck && !order.is_receipt_printed) {
        let payload = {
          field: "is_receipt_printed",
          value: true,
          orderId: order.id,
          isCheck: true,
        };
        await store.dispatch("setOrderField", payload);
      }
    }
  });
}
