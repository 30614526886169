import { mapGetters } from "vuex";
import { sub } from "date-fns";

export default {
    computed: {
        ...mapGetters([
            'currentCity'
        ]),
        dateTimeToCountFrom() {
            if (this.order.deliver_at) {
                const deliverAt = new Date(this.order.deliver_at);
                return sub(deliverAt, { minutes: 60 })
            } else {
                return new Date(this.order.confirmed_at)
            }
        },
    }
}