<template>
  <div>
    <page-header />
    <section class="main">
      <div class="wrapper">
        <page-tabs />
        <div class="main-wrap">
          <div class="tab tab-active" id="tab-3">
            <div class="tab-wrap">
              <ul class="tab-record__list">
                <li>
                  <router-link :to="{ name: 'analytics-reports-revenue' }">
                    <svg-icon name="chart-bars" />
                    <span>По выручкам</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'analytics-reports-bestsellers' }">
                    <svg-icon name="basket" />
                    <span>По продажам</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'analytics-reports-courier-late' }">
                    <svg-icon name="flames-alt" />
                    <span>По опозданиям (на доставку)</span>
                  </router-link>
                </li>
              </ul>
              <div>
                <div class="section">
                  <div
                    class="tab-record__content tab-record__content-active"
                    id="col-tab-1"
                  >
                    <div class="tab-record__top">
                      <form action="">
                        <div class="tab-record__row">
                          <div class="tab-record__row-wrap">
                            <input v-model="filter.startDate" type="date" />
                            <span>-</span>
                            <input v-model="filter.endDate" type="date" />
                          </div>
                        </div>
                      </form>
                      <ul class="tab-record__btn">
                        <download-excel
                          :data="dataForExcel"
                          :name="nameForExcelFile"
                        >
                          <li>
                            <a class="second-btn" href="#">Экспорт в Excel</a>
                          </li>
                        </download-excel>
                        <li>
                          <button
                            class="main-btn"
                            :class="{ readonly: loader }"
                            @click="showReport()"
                          >
                            Показать
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-record__checkbox">
                      <div class="tab-record__checkbox-col">
                        <span class="tab-record__checkbox-title"
                          >Тип доставки:</span
                        >
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="deliveryTypes.delivery"
                            v-model="filter.deliveryTypes"
                          />
                          <span></span>
                          <p>Доставка</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="deliveryTypes.takeaway"
                            v-model="filter.deliveryTypes"
                          />
                          <span></span>
                          <p>Самовывоз</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="deliveryTypes.stay"
                            v-model="filter.deliveryTypes"
                          />
                          <span></span>
                          <p>Зал</p>
                        </div>
                      </div>
                      <div class="tab-record__checkbox-col">
                        <span class="tab-record__checkbox-title"
                          >Источник заказа:</span
                        >
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="orderSources.dashboard"
                            v-model="filter.sources"
                          />
                          <span></span>
                          <p>CRM</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="orderSources.mobile"
                            v-model="filter.sources"
                          />
                          <span></span>
                          <p>Приложение</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="orderSources.website"
                            v-model="filter.sources"
                          />
                          <span></span>
                          <p>Сайт</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="orderSources.aggregator"
                            v-model="filter.sources"
                          />
                          <span></span>
                          <p>Агрегатор</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="orderSources.call_center"
                            v-model="filter.sources"
                          />
                          <span></span>
                          <p>Колл центр</p>
                        </div>
                      </div>
                      <div class="tab-record__checkbox-col">
                        <span class="tab-record__checkbox-title"
                          >Тип оплаты:</span
                        >
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="paymentTypes.cash"
                            v-model="filter.paymentTypes"
                          />
                          <span></span>
                          <p>Наличные</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="paymentTypes.pos"
                            v-model="filter.paymentTypes"
                          />
                          <span></span>
                          <p>POS-терминал</p>
                        </div>
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            :value="paymentTypes.online"
                            v-model="filter.paymentTypes"
                          />
                          <span></span>
                          <p>Картой</p>
                        </div>
                      </div>
                      <div class="tab-record__checkbox-col">
                        <span class="tab-record__checkbox-title"
                          >Категории:</span
                        >
                        <div
                          class="checkbox"
                          v-for="i in categories"
                          :key="i.id"
                        >
                          <input
                            type="checkbox"
                            :value="i.id"
                            v-model="filter.categories"
                          />
                          <span></span>
                          <p>{{ i.title }}</p>
                        </div>
                      </div>
                      <div
                        class="tab-record__checkbox-col tab-record__checkbox-col__last"
                      >
                        <span class="tab-record__checkbox-title"></span>
                        <div class="checkbox" @click="toggleSelectAllFilters">
                          <input
                            type="checkbox"
                            v-model="pageUI.filtersSelectedAll"
                          />
                          <span></span>
                          <p><b>Выбрать все</b></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div v-if="this.pageUI.isReportLoading">
                    <img
                      src="@/assets/img/loader-pacman.svg"
                      alt="Loading..."
                    />
                  </div>
                  <div v-else-if="pageUI.errors.length > 0">
                    <p
                      v-for="error in pageUI.errors"
                      :key="error"
                      class="error"
                    >
                      {{ error }}
                    </p>
                  </div>
                  <div v-else-if="reportRows && reportRows.length === 0">
                    По вашему запросу ничего не найдено
                  </div>
                  <div
                    v-else-if="reportRows && reportRows.length > 0"
                    class="table tab-record__table"
                  >
                    <div class="table-top">
                      <div class="table-row">
                        <div
                          v-for="header in headers"
                          :key="header"
                          class="table-col table-col__1"
                        >
                          <p>{{ header }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="row in reportRows"
                      :key="row.closed_at__date"
                      class="table-row"
                    >
                      <div
                        v-for="field in fieldsList"
                        :key="field"
                        class="table-col table-col__1"
                      >
                        <p>{{ row[field] }}</p>
                      </div>
                    </div>
                    <div class="table-row totalPriceSum">
                      <div class="table-col table-col__1">
                        <p class="result-field">Итого</p>
                      </div>
                      <div class="table-col table-col__1">
                        <p class="result-field">{{ this.countSum }}</p>
                      </div>
                      <div class="table-col table-col__1">
                        <p class="result-field">{{ this.totalPriceSum }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/analytics/PageHeader";
import PageTabs from "@/components/analytics/PageTabs";
import Order from "@/api/order";
import Category from "@/api/category";
import SvgIcon from "@/components/SvgIcon";
import JsonExcel from "vue-json-excel";

export default {
  name: "ReportComponent",
  components: { SvgIcon, PageTabs, PageHeader, downloadExcel: JsonExcel },
  props: {
    fields: {
      required: true,
      type: Object,
    },
    reportType: {
      required: true,
      type: String,
    },
    dataFetchingFunc: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      orderSources: Order.SOURCE,
      paymentTypes: Order.PAYMENT_TYPE,
      deliveryTypes: Order.DELIVERY_TYPE,
      categories: [],
      reportRows: null,
      pageUI: {
        filtersSelectedAll: false,
        isReportLoading: false,
        errors: [],
      },
      filter: {
        startDate: null,
        endDate: null,
        deliveryTypes: [],
        sources: [],
        paymentTypes: [],
        categories: [],
        department: this.$store.getters.currentDepartment.slug,
        reportType: this.reportType,
      },
      totalPriceSum: null,
      countSum: null,
      loader: false,
      dataForExcel: [],
      nameForExcelFile: "",
      selectedType: "",
      rangDate: "",
    };
  },
  async mounted() {
    let categories = await Category.list(localStorage.getItem("citySlug"));
    categories.forEach((i) => {
      if (i.children.length === 0) {
        this.categories.push(i);
      } else {
        i.children.forEach((j) => {
          this.categories.push(j);
        });
      }
    });
    this.toggleSelectAllFilters();
  },
  methods: {
    toggleSelectAllFilters() {
      this.pageUI.filtersSelectedAll = !this.pageUI.filtersSelectedAll;
      if (this.pageUI.filtersSelectedAll) {
        Object.values(this.orderSources).forEach((source) => {
          if (!this.filter.sources.includes(source)) {
            this.filter.sources.push(source);
          }
        });
        Object.values(this.paymentTypes).forEach((pType) => {
          if (!this.filter.paymentTypes.includes(pType)) {
            this.filter.paymentTypes.push(pType);
          }
        });
        Object.values(this.deliveryTypes).forEach((dType) => {
          if (!this.filter.deliveryTypes.includes(dType)) {
            this.filter.deliveryTypes.push(dType);
          }
        });

        // Object.values(this.categories).forEach((cat) => {
        //   if (!this.filter.categories.includes(cat.id)) {
        //     this.filter.categories.push(cat.id);
        //   }
        // });
      } else {
        this.filter.deliveryTypes = [];
        this.filter.paymentTypes = [];
        this.filter.sources = [];
        this.filter.categories = [];
      }
    },
    async showReport() {
      this.totalPriceSum = 0;
      this.countSum = 0;
      this.loader = true;
      let element = {};
      (this.dataForExcel = []),
        // this.startDate = this.startDate.toISOString().slice(0,10).replace(/-/g,"")
        // window.console.log(this.startDate.setDate(this.startDate.getDate() + 1))

        (this.pageUI.errors = []);
      if (!this.filter.startDate) {
        this.pageUI.errors.push("Укажите хотя бы начальную дату");
        this.loader = false;
      }
      if (this.pageUI.errors.length > 0) {
        return;
      }

      function formatDate(date) {
        let d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }

      if (this.filter.startDate && !this.filter.endDate) {
        let date = new Date();
        this.filter.endDate = formatDate(date);
      }

      this.pageUI.isReportLoading = true;
      this.reportRows = await this.dataFetchingFunc(this.filter);
      this.pageUI.isReportLoading = false;

      if (this.filter.reportType === "bestsellers") {
        for (let j = this.reportRows.length - 1; j > 0; j--) {
          for (let i = 0; i < j; i++) {
            if (this.reportRows[i].count < this.reportRows[i + 1].count) {
              let temp = this.reportRows[i];
              this.reportRows[i] = this.reportRows[i + 1];
              this.reportRows[i + 1] = temp;
            }
          }
        }
      }

      if (this.filter.reportType === "bestsellers") {
        for (let i = 0; i < this.reportRows.length; i++) {
          this.totalPriceSum += Number(this.reportRows[i].price_sum);
          this.countSum += this.reportRows[i].count;
          element = {
            Блюдо: this.reportRows[i].item__item__title,
            Заказов: this.reportRows[i].count,
            Сумма: this.reportRows[i].price_sum,
          };

          this.dataForExcel.push(element);
        }
      } else {
        for (let i = 0; i < this.reportRows.length; i++) {
          this.totalPriceSum += Number(this.reportRows[i].total_price_sum);
          this.countSum += this.reportRows[i].count;

          element = {
            Блюдо: this.reportRows[i].closed_at__date,
            Заказов: this.reportRows[i].count,
            Сумма: this.reportRows[i].total_price_sum,
          };

          this.dataForExcel.push(element);
        }
      }

      element = {
        Блюдо: "Итого",
        Заказов: this.countSum,
        Сумма: this.totalPriceSum,
      };

      this.dataForExcel.push(element);

      switch (this.filter.reportType) {
        case "revenue":
          this.nameForExcelFile = "Отчёт по выручкам";
          break;
        case "bestsellers":
          this.nameForExcelFile = "Отчёт по продажам";
          break;
        case "late/courier":
          this.nameForExcelFile = "Отчёт по опозданиям";
          break;
      }

      function formatDateToExcel(date) {
        let d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("-");
      }

      let startDateExcel = formatDateToExcel(this.filter.startDate);
      let endDateExcel = formatDateToExcel(this.filter.endDate);

      this.nameForExcelFile += ` (${startDateExcel} / ${endDateExcel})`;

      this.loader = false;
    },
  },
  computed: {
    headers() {
      return Object.values(this.fields);
    },
    fieldsList() {
      return Object.keys(this.fields);
    },
  },
};
</script>

<style scoped lang="sass">
.result-field
  font-weight: 700
.tab-record__list
    min-width: 340px
    padding-right: 0
</style>
