<template>
  <div class="container">
    <div class="container-login-form">
      <img src="@/assets/img/logo-full.svg" />
      <form @submit.prevent="login">
        <div class="login-group-input">
          <input
            v-model="username"
            type="text"
            class="login-input"
            placeholder="Логин"
          /><br />
          <input
            v-model="password"
            type="password"
            class="login-input"
            placeholder="Пароль"
          /><br />
        </div>
        <button
          type="submit"
          class="btn btn--primary"
          :class="isLoading ? 'readonly' : ''"
        >
          Войти
        </button>
      </form>
    </div>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import User from "@/api/user";
import { SET_USER } from "@/store/modules/auth/mutation-types";
import { setAuthHeaders } from "@/utils/auth";
import { openShift } from "@/utils/kkm/kkmShift";

export default {
  name: "LoginPage",
  data: function () {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      const response = await User.obtainUserData(this.username, this.password);
      if (response.type && response.type.includes("Error")) {
        // при авторизации произошла какая-то ошибка. будет показан toast в другом месте программы, которое
        // обрабатывает сетевые ошибки
        this.isLoading = false;
      } else {
        this.$store.commit(SET_USER, { user: response });
        setAuthHeaders(response.auth_token);
        window.location.href = "/roles";
        openShift();
      }
      this.isLoading = false;
    },
  },
};
</script>

<style src="./auth-common.css"></style>

<style scoped>
.container-login-form {
  border-radius: 35px;
  padding: 50px;
}

.login-group-input {
  margin: 45px 0 20px 0;
}

.login-input {
  border: none;
  border-bottom: 1px solid #b8bec7;
  background: none;
  border-radius: 0;
  padding: 5px;
  margin: 15px 0;
  min-width: 270px;
  outline: none;
  font-weight: normal;
}
</style>
