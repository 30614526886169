<template>
  <div>
    <div class="order-print">
      <!-- <h3>Служба доставки "Три Самурая"</h3> -->
      <ul class="adjusted">
        <li>
          <span class="key">Дата:</span>
          <span class="val">{{ order.placed_at | date }}</span>
        </li>
        <li>
          <span class="key">Время заказа:</span>
          <span class="val">{{ order.placed_at | time }}</span>
        </li>
        <li>
          <span class="key">Наш адрес:</span>
          <span class="val">{{ depAddress }}</span>
        </li>
        <li>
          <span class="key">Наш телефон:</span>
          <span class="val">{{
            $store.state.location.city.phones[0].number
          }}</span>
        </li>
        <!-- <li>
          <span class="key">Время приготовления:</span>
          <span class="val"> {{ cookedAt }}</span>
        </li> -->
      </ul>
      <h4>Заказ {{ order.number }}</h4>
      <ul class="adjusted">
        <li>
          <span class="key">Клиент:</span>
          <span class="val">{{ order.contacts.first_name }}</span>
        </li>
        <li>
          <span class="key">Телефон:</span>
          <span class="val">{{ order.contacts.phone }}</span>
        </li>
        <li>
          <span class="key">Адрес:</span>
          <span class="val">{{ address }}</span>
        </li>
        <li>
          <span class="key">Тип оплаты:</span>
          <span class="val">{{ paymentTypeVerbose }}</span>
        </li>
        <li>
          <span class="key">Кол-во персон:</span>
          <span class="val">{{ order.persons_qty }}</span>
        </li>
        <li v-if="order.payment_info.rest_from > 0">
          <span class="key">Купюра:</span>
          <span class="val">{{ order.payment_info.rest_from }}</span>
        </li>
        <li v-if="order.comment" class="receipt-order-comment-wrapper">
          <span class="key">Комментарий:</span>
          <span class="val">{{ order.comment }}</span>
        </li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Наименование</th>
            <th>Цена</th>
            <th>Кол-во</th>
            <th>Скидка</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in order.items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.title }}</td>
            <td class="numbers">{{ item.price_per_item }}</td>
            <td class="numbers">{{ item.qty }}</td>
            <td class="numbers">0.00</td>
            <td class="numbers">
              {{ (item.price_per_item * item.qty) | money }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">Итого:</td>
            <td class="numbers">
              {{ getRest }}
            </td>
            <td class="numbers">{{ order.payment_info.total_price }}</td>
          </tr>
          <tr>
            <td colspan="4">Сдача:</td>
            <td class="numbers"></td>
            <td class="numbers">
              <span v-if="order.payment_info.rest_from > 0">{{
                (order.payment_info.rest_from - order.payment_info.total_price)
                  | money
              }}</span>
              <span v-else>0.00</span>
            </td>
          </tr>
        </tfoot>
      </table>
      <!--        <ul class="adjusted">-->
      <!--            <li>-->
      <!--                <span class="key">Наш адрес:</span>-->
      <!--                <span id="receipt-department-address" class="val"></span>-->
      <!--            </li>-->
      <!--            <li>-->
      <!--                <span class="key">Телефон:</span>-->
      <!--                <span id="receipt-department-phone" class="val"></span>-->
      <!--            </li>-->
      <!--            <li>-->
      <!--                <span class="key">Мы в сети:</span>-->
      <!--                <span id="receipt-department-site" class="val"></span>-->
      <!--            </li>-->
      <!--        </ul>-->
    </div>
    <!-- <div v-else-if="order.placed_at && order.is_receipt_printed">
            Чек уже напечатан, больше нельзя. Но если очень нужно - обратитесь к менеджеру.
        </div>
        <div v-else>Загрузка...</div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Order from "@/api/order";
import Department from "@/api/department";
import {
  buildAddressString,
  receiptOrderDate,
  receiptOrderTime,
  receiptOrderCookedTime,
} from "@/utils/receipt";

export default {
  name: "OrderReceiptPage",
  async mounted() {
    await this.fetchData();
    window.print();
    this.$router.push({ name: "operator" });
  },
  data() {
    return {
      depAddress: "",
    };
  },
  // updated() {
  //   window.setTimeout(() => {
  //     window.print();
  //     this.$router.push({ name: "operator" });
  //   }, 100);
  // },
  methods: {
    async fetchData() {
      await this.$store.dispatch("setOrderById", this.$route.params.id);
      const resp = await Department.get(this.order.department);
      this.depAddress = resp.address;
    },
  },
  computed: {
    ...mapGetters(["order"]),
    cookedAt() {
      return receiptOrderCookedTime(this.order.placed_at);
    },
    getRest() {
      let rest = 0;
      if (this.order.payment_info.discount_percent_amount >= 0) {
        rest = this.order.payment_info.discount_percent_amount;
      } else if (this.order.payment_info.discount_amount >= 0) {
        rest = this.order.payment_info.discount_amount;
      }
      return rest;
    },
    address() {
      return buildAddressString(this.order);
    },
    paymentTypeVerbose() {
      if (this.order.payment_info.payment_type === Order.PAYMENT_TYPE.cash) {
        return "Наличные";
      } else if (
        this.order.payment_info.payment_type === Order.PAYMENT_TYPE.online
      ) {
        return "Картой в приложении";
      } else if (
        this.order.payment_info.payment_type === Order.PAYMENT_TYPE.pos
      ) {
        return "Терминал";
      } else {
        return "Неопределен";
      }
    },
  },
  filters: {
    date(dtString) {
      return receiptOrderDate(dtString);
    },
    time(dtString) {
      return receiptOrderTime(dtString);
    },
  },
};
</script>

<style scoped>
.order-print {
  font-size: 12px;
}

.order-print h3,
.order-print h4 {
  text-align: center;
}

.order-print ul.adjusted .key {
  min-width: 90px;
  display: inline-block;
}

.order-print li {
  list-style: none;
}

.order-print td,
.order-print th {
  padding: 5px 10px;
}

.order-print .numbers {
  text-align: right;
}

.order-print table {
  border: 1px solid black;
  border-collapse: collapse;
  margin-left: 40px;
}

.order-print td {
  border-top: 1px solid black;
  margin: 0;
  border-collapse: collapse;
}
</style>
