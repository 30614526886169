import {Model} from './index'

class CancellationReason extends Model {
    path = '/v1/cancellation-reasons';

    list() {
        return this._list()
    }
}

export default new CancellationReason()
