<template>
  <modal name="blacklist" height="auto" @before-open="beforeOpen">
    <div class="modal__wrapper">
      <h3 class="modal__header">Причина добавления в черный список</h3>
      <form id="blacklist-form" class="form--branded">
        <div class="fancy-input fancy-input--no-label">
          <textarea placeholder="Причина" rows="5" v-model="comment"></textarea>
        </div>
      </form>
    </div>
    <div class="modal__buttons">
      <div v-if="comment">
        <button
          type="submit"
          class="btn btn--primary btn--size-default"
          @click="addToBlacklist"
        >
          Сохранить
        </button>
      </div>
      <div v-else>
        <button
          type="submit"
          class="btn btn--primary btn--size-default readonly"
          @click="addToBlacklist"
        >
          Сохранить
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalBlacklist",
  data: () => {
    return {
      user: {},
      order: {},
      comment: "",
    };
  },
  methods: {
    beforeOpen(event) {
      this.comment = "";
      this.user = event.params.user;
      this.order = event.params.order;
    },
    addToBlacklist() {
      let payload = {
        field: "blacklist_comment",
        value: this.comment,
        user: this.user,
        order: this.order,
      };
      this.$store.dispatch("setOrderUserField", payload);
      this.$modal.hide("blacklist");
    },
  },
};
</script>
