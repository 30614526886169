<template>
  <div v-if="isVisible">
    <div v-if="order.source === 'app'">
      <div :class="$style.number">
        <img src="@/assets/img/order-phone.svg" alt=" " :class="$style.icon" />
        {{ order.number }}
      </div>
    </div>
    <div v-else>
      {{ order.number }}
    </div>
  </div>
</template>

<script>
import { differenceInMinutes } from "date-fns";
import OrderDateTimeToCountFrom from "@/mixins/OrderDateTimeToCountFrom";

export default {
  name: "OrdersListItem",
  mixins: [OrderDateTimeToCountFrom],
  props: {
    order: Object,
    now: Number,
  },
  computed: {
    isVisible() {
      return differenceInMinutes(this.now, this.dateTimeToCountFrom) > -5;
    },
  },
};
</script>

<style lang="scss" module>
.number {
  position: relative;
  display: unset;

  .icon {
    position: absolute;
    left: -6vh;
    top: 50%;
    transform: translateY(-50%);
    width: 4vh;
  }
}
</style>
