import { API, Model } from './index'

class MenuItem extends Model {
    path = '/v1/menu-items';

    list(categoryId, cityId) {
        let params = {
            city: cityId,
            category: categoryId
        };
        return this._list(params)
    }

    filter(filter) {
        let city = localStorage.getItem("citySlug")
        this.path = `/v1/cities/${city}/menu-items`
        delete filter.city
        return this._list(filter, false)
    }

    async update(slug, payload) {
        this.path = '/menu-items';
        let citySlug = localStorage.getItem("citySlug")
        let path = `/v1/cities/${citySlug}` + this.path + `/${slug}/`
        const resp = await API.patch(path, payload)
        return resp
    }
    async updateModifier(itemSlug, groupSlug, modId, payload) {
        let citySlug = localStorage.getItem("citySlug")
        let path = `/v1/cities/${citySlug}/menu-items/${itemSlug}/modifier-groups/${groupSlug}/modifiers/${modId}/`
        const resp = await API.patch(path, payload)
        return resp
    }
    async get(category, city) {
        this.path = '/menu-items';
        let path = `/v1/cities/${city}` + this.path + "/"
        path += `?category=${category}`
        const resp = await API.get(path)
        return resp
    }

    async getOne(slug) {
        let citySlug = localStorage.getItem("citySlug")
        let path = `/v1/cities/${citySlug}/menu-items/${slug}/`

        const resp = await API.get(path)
        return resp
    }

    async delete(slug) {
        this.path = '/menu-items';
        let citySlug = localStorage.getItem("citySlug")
        let path = `/v1/cities/${citySlug}` + this.path + `/${slug}/`
        const resp = await API.delete(path)
        return resp
    }
}

export default new MenuItem()
