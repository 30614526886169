<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
// REFERENCE: https://www.priestch.com/two-ways-use-svg-icons-in-vue-cli3-based-project/

// https://webpack.js.org/guides/dependency-management/#context-module-api
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("../assets/svg-icons", false, /\.svg$/);
requireAll(req);

export default {
  name: "SvgIcon",
  props: {
    name: {
      // icon filename
      type: String,
      required: true,
    },
    className: {
      // css class name
      type: String,
      default: "",
    },
  },
  computed: {
    iconName() {
      let icon = this.name;
      return icon ? `#icon-${icon}` : "";
    },
    svgClass() {
      let className = this.className;
      return className ? `svg-icon ${className}` : "svg-icon";
    },
  },
};
</script>

<style>
.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor; /* important */
  overflow: hidden;
}
</style>
