var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"order"},[_c('div',[_c('OrderDeliveryTypesList',{class:{
          readonly: _vm.readonly,
        },attrs:{"isTakeawayService":_vm.isTakeawayService}}),_c('div',{staticClass:"order__section"},[_c('OrderAlerts',{class:{
            readonly: _vm.readonly,
          }}),_c('OrderDepartmentList',{class:{
            readonly: _vm.readonly,
          },attrs:{"departments":_vm.departments}}),_c('OrderForm'),_c('OrderItemsList'),_c('div',{staticClass:"order__row-points",class:[
            {
              readonly: _vm.readonly,
            },
            _vm.$style.wrap,
          ]},[_c('OrderPaymentTypeList'),_c('OrderUserPoints')],1),_c('OrderDiscountsList',{class:{
            readonly: _vm.readonly,
          }}),(_vm.order.payment_info.payment_type === 'cash')?_c('div',{class:{
            readonly: _vm.readonlyItems,
          }},[_c('div',[_vm._v("Внесено наличными:")]),_c('OrderRestFromList')],1):_vm._e()],1),_c('div',{staticClass:"order__section"},[_c('OrderMoney',{class:{
            readonly: _vm.readonly,
          }}),_c('OrderSaveCancelButtons',{class:{
            readonly: _vm.readonlyItems,
          }}),_c('OrderCheckboxes',{class:{
            readonly: _vm.readonly && _vm.order.is_receipt_printed,
          }})],1)],1)]),_c('transition',{attrs:{"name":"bounce"}},[(_vm.showCategoriesList)?_c('CategoriesList',{class:{
        readonly: _vm.readonlyItems,
      }}):_vm._e()],1),_c('MenuItemList',{class:{
      readonly: _vm.readonlyItems,
    }}),_c('notifications',{attrs:{"position":"bottom right"}}),(_vm.$store.state.orderForm.toppingsModal.isOpen)?_c('ToppingsModal'):_vm._e(),(_vm.isSaveLoading)?_c('FormLoader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }