<template>
  <div class="order__delivery-types">
    <order-delivery-type-item
      v-for="deliveryType in deliveryTypes"
      :key="deliveryType.id"
      :deliveryType="deliveryType"
    ></order-delivery-type-item>
  </div>
</template>

<script>
import Order from "@/api/order";
import OrderDeliveryTypeItem from "@/components/order/order-delivery-type/OrderDeliveryTypeItem";
import { SET_ORDER_FIELD } from "@/store/modules/order-form/mutation-types";

export default {
  name: "OrderDeliveryTypesList",
  components: { OrderDeliveryTypeItem },
  props: {
    isTakeawayService: Boolean,
  },
  data: () => {
    return {
      deliveryTypes: [
        {
          id: Order.DELIVERY_TYPE.takeaway,
          title: "Самовывоз",
          iconName: "stage-taking-away",
        },
        {
          id: Order.DELIVERY_TYPE.delivery,
          title: "Доставка",
          iconName: "stage-delivering",
        },
        { id: Order.DELIVERY_TYPE.stay, title: "Зал", iconName: "stage-stay" },
      ],
    };
  },
  watch: {
    isTakeawayService() {
      if (!this.isTakeawayService) {
        this.deliveryTypes = [
          {
            id: Order.DELIVERY_TYPE.delivery,
            title: "Доставка",
            iconName: "stage-delivering",
          },
        ];
        let payload = {
          field: "delivery_type",
          value: Order.DELIVERY_TYPE.delivery,
        };
        this.$store.commit(SET_ORDER_FIELD, payload);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.order__delivery-types
    display: flex
    padding-bottom: 30px
</style>
