<template>
  <div @click="logout">
    <svg-icon name="logout" class-name="logout-button"></svg-icon>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon";
import { CLEAR_USER } from "@/store/modules/auth/mutation-types";
import { clearAuthHeaders } from "@/utils/auth";
import { closeDay } from "@/utils/kkm/kkmShift";
import User from "@/api/user";
import { mapGetters } from "vuex";

export default {
  name: "LogoutButton",
  components: {
    SvgIcon,
  },
  methods: {
    async logout() {
      localStorage.removeItem("filter");
      if (this.$store.getters.FCMtoken) {
        await User.deleteFCMToken(this.$store.getters.FCMtoken);
        this.$store.commit("setFSMtoken", null);
        closeDay(this.$store.state.location.department.kkm_pay_by_processing);
      }
      this.$store.commit(CLEAR_USER);
      clearAuthHeaders();

      this.$router.push({ name: "login" });
    },
  },
  computed: {
    ...mapGetters(["currentDepartment"]),
  },
};
</script>

<style lang="sass" scoped>
.logout-button
    width: 25px
    height: 25px
    cursor: pointer
</style>
