<template>
  <div class="main-col col-50">
    <div class="main-col__row">
      <h3 class="main-col__row-title color-red">{{ header }}</h3>
      <div class="row">
        <div class="main-col__row-wrap">
          <div class="main-col__row-info">
            <p>{{ subheader }} за {{ month }} {{ new Date().getFullYear() }}</p>
            <p>
              {{ subheader }} за {{ month }} {{ new Date().getFullYear() - 1 }}
            </p>
          </div>
          <span class="main-col__subtitle"
            >Доставка
            <b
              :class="{
                'color-red': barSize.deliveryPercentDiff.startsWith('-'),
              }"
              >{{ barSize.deliveryPercentDiff }}</b
            ></span
          >
          <div class="main-col__statistics main-col__statistics-big">
            <div
              class="main-col__statistics-row"
              :style="`width: ${barSize.currentDelivery}%`"
            >
              <span>{{ month }} {{ new Date().getFullYear() }}</span>
              <p>{{ summaryCurrent["delivery_" + keyword] | money }}</p>
            </div>
          </div>
          <div class="main-col__statistics">
            <div
              class="main-col__statistics-row"
              :style="`width: ${barSize.yearAgoDelivery}%`"
            >
              <span>{{ month }} {{ new Date().getFullYear() - 1 }}</span>
              <p>{{ summaryYearAgo["delivery_" + keyword] | money }}</p>
            </div>
          </div>
        </div>
        <div class="main-col__row-wrap">
          <div class="main-col__row-info">
            <p>
              {{ summaryCurrent["total_" + keyword] }}
              <b
                :class="{
                  'color-red': barSize.revenuePercentDiff.startsWith('-'),
                }"
                >{{ barSize.revenuePercentDiff }}</b
              >
            </p>
            <p>{{ summaryYearAgo["total_" + keyword] }}</p>
          </div>
          <span class="main-col__subtitle"
            >Самовывоз
            <b
              :class="{
                'color-red': barSize.takeawayPercentDiff.startsWith('-'),
              }"
              >{{ barSize.takeawayPercentDiff }}</b
            ></span
          >
          <div class="main-col__statistics main-col__statistics-big bg-accent">
            <div
              class="main-col__statistics-row"
              :style="`width: ${barSize.currentTakeAway}%`"
            >
              <span>{{ month }} {{ new Date().getFullYear() }}</span>
              <p>{{ summaryCurrent["takeaway_" + keyword] | money }}</p>
            </div>
          </div>
          <div class="main-col__statistics bg-accent">
            <div
              class="main-col__statistics-row"
              :style="`width: ${barSize.yearAgoTakeAway}%`"
            >
              <span>{{ month }} {{ new Date().getFullYear() - 1 }}</span>
              <p>{{ summaryYearAgo["takeaway_" + keyword] | money }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RevenueCountBlock",
  props: {
    month: {
      type: String,
      required: true,
    },
    summaryCurrent: {
      type: Object,
      required: true,
    },
    summaryYearAgo: {
      type: Object,
      required: true,
    },
    keyword: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    subheader: {
      type: String,
      required: true,
    },
  },
  computed: {
    barSize() {
      const sizes = {
        currentDelivery: null,
        yearAgoDelivery: null,
        currentTakeAway: null,
        yearAgoTakeAway: null,
        deliveryPercentDiff: null,
        takeawayPercentDiff: null,
        revenuePercentDiff: null,
      };
      if (
        parseFloat(this.summaryCurrent["delivery_" + this.keyword]) >
        parseFloat(this.summaryYearAgo["delivery_" + this.keyword])
      ) {
        // bar
        sizes.currentDelivery = 100;
        sizes.yearAgoDelivery =
          (parseFloat(this.summaryYearAgo["delivery_" + this.keyword]) /
            parseFloat(this.summaryCurrent["delivery_" + this.keyword])) *
          100;

        // percent diff
        const increaseSum =
          this.summaryCurrent["delivery_" + this.keyword] -
          this.summaryYearAgo["delivery_" + this.keyword];
        const increasePercent =
          (increaseSum / this.summaryYearAgo["delivery_" + this.keyword]) * 100;
        sizes.deliveryPercentDiff = `+${
          isFinite(increasePercent) ? increasePercent.toFixed(2) : "999..."
        }%`;
      } else {
        // bar
        sizes.yearAgoDelivery = 100;
        sizes.currentDelivery =
          (parseFloat(this.summaryCurrent["delivery_" + this.keyword]) /
            parseFloat(this.summaryYearAgo["delivery_" + this.keyword])) *
          100;

        // percent diff
        const decreaseSum =
          this.summaryYearAgo["delivery_" + this.keyword] -
          this.summaryCurrent["delivery_" + this.keyword];
        const decreasePercent =
          (decreaseSum / this.summaryYearAgo["delivery_" + this.keyword]) * 100;
        sizes.deliveryPercentDiff = `-${
          isFinite(decreasePercent) ? decreasePercent.toFixed(2) : "999..."
        }%`;
      }
      if (
        parseFloat(this.summaryCurrent["takeaway_" + this.keyword]) >
        parseFloat(this.summaryYearAgo["takeaway_" + this.keyword])
      ) {
        sizes.currentTakeAway = 100;
        sizes.yearAgoTakeAway =
          (parseFloat(this.summaryYearAgo["takeaway_" + this.keyword]) /
            parseFloat(this.summaryCurrent["takeaway_" + this.keyword])) *
          100;

        // percent diff
        const increaseSum =
          this.summaryCurrent["takeaway_" + this.keyword] -
          this.summaryYearAgo["takeaway_" + this.keyword];
        const increasePercent =
          (increaseSum / this.summaryYearAgo["takeaway_" + this.keyword]) * 100;
        sizes.takeawayPercentDiff = `+${
          isFinite(increasePercent) ? increasePercent.toFixed(2) : "999..."
        }%`;
      } else {
        sizes.yearAgoTakeAway = 100;
        sizes.currentTakeAway =
          (parseFloat(this.summaryCurrent["takeaway_" + this.keyword]) /
            parseFloat(this.summaryYearAgo["takeaway_" + this.keyword])) *
          100;

        // percent diff
        const decreaseSum =
          this.summaryYearAgo["takeaway_" + this.keyword] -
          this.summaryCurrent["takeaway_" + this.keyword];
        const decreasePercent =
          (decreaseSum / this.summaryYearAgo["takeaway_" + this.keyword]) * 100;
        sizes.takeawayPercentDiff = `-${
          isFinite(decreasePercent) ? decreasePercent.toFixed(2) : "999..."
        }%`;
      }
      if (
        parseFloat(this.summaryCurrent["total_" + this.keyword]) >
        parseFloat(this.summaryYearAgo["total_" + this.keyword])
      ) {
        const increaseSum =
          this.summaryCurrent["total_" + this.keyword] -
          this.summaryYearAgo["total_" + this.keyword];
        const increasePercent =
          (increaseSum / this.summaryYearAgo["total_" + this.keyword]) * 100;
        sizes.revenuePercentDiff = `+${
          isFinite(increasePercent) ? increasePercent.toFixed(2) : "999..."
        }%`;
      } else {
        const decreaseSum =
          this.summaryYearAgo["total_" + this.keyword] -
          this.summaryCurrent["total_" + this.keyword];
        const decreasePercent =
          (decreaseSum / this.summaryYearAgo["total_" + this.keyword]) * 100;
        sizes.revenuePercentDiff = `-${
          isFinite(decreasePercent) ? decreasePercent.toFixed(2) : "999..."
        }%`;
      }
      return sizes;
    },
  },
};
</script>

<style scoped></style>
