<template>
  <button
    class="btn order__departments-department"
    :class="
      order.department === department.slug ? 'btn--primary' : 'btn--secondary'
    "
    @click="setOrderDepartment()"
  >
    <svg height="20" width="20">
      <circle cx="8" cy="8" r="6" fill="#f0f0f0" />
    </svg>
    {{ department.title }}
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_ORDER_FIELD } from "@/store/modules/order-form/mutation-types";

export default {
  name: "OrderDepartment",
  props: {
    department: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setOrderDepartment() {
      let payload = {
        field: "department",
        value: this.department.slug,
      };
      this.$store.commit(SET_ORDER_FIELD, payload);
    },
  },
  computed: {
    ...mapGetters(["order"]),
  },
};
</script>

<style lang="sass" scoped>
@import "../../../assets/sass/variables"

.order__departments-department
    padding: 10px
    margin: 5px 0
    text-align: left
    flex-grow: 1
    flex-basis: 0

    &:first-child
        margin-right: $horizontal-siblings-padding
    &:last-child
        margin-left: $horizontal-siblings-padding
</style>
