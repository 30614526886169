<template>
  <report-component
    :fields="fields"
    :data-fetching-func="dataFetchingFunc"
    :report-type="reportType"
  />
</template>

<script>
import ReportComponent from "@/components/analytics/reports/ReportComponent";
import Analytics from "@/api/analytics";

export default {
  components: { ReportComponent },
  data() {
    return {
      fields: {
        closed_at__date: "Дата",
        count: "Заказов",
        total_price_sum: "Сумма со скидкой",
      },
      dataFetchingFunc: Analytics.report,
      reportType: "late/courier",
    };
  },
};
</script>

<style scoped></style>
