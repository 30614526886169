<template>
  <input
    type="text"
    ref="street"
    :id="this.inputId"
    placeholder=""
    :value="order.address.street"
    @keyup="onManualChange"
  />
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import { mapGetters } from "vuex";

export default {
  name: "GoogleAutocompleteStreetInput",
  data: () => {
    return {
      inputId: "google-autocomplete-street",
      locationRadius: 35000, // 35 kilometers
    };
  },
  async mounted() {
    this.google = await GoogleMapsApiLoader({
      libraries: ["places"],
      apiKey: "AIzaSyA47GRnst5mM9ZaV2oqQRjH5heIj9sILMc",
      language: "ru",
    });
    this.initAutocomplete();
  },
  methods: {
    initAutocomplete() {
      let geolocation = {
        lng: this.currentDepartment.longitude,
        lat: this.currentDepartment.latitude,
      };
      let circle = new this.google.maps.Circle({
        center: geolocation,
        radius: this.locationRadius,
      });
      let options = {
        types: ["address"],
        bounds: circle.getBounds(),
        strictBounds: true,
      };
      this.autocomplete = new this.google.maps.places.Autocomplete(
        document.getElementById(this.inputId),
        options
      );
      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();
      document.getElementById(this.inputId).value = place.name;
      this.$emit("changed", place.name);
    },
    onManualChange() {
      this.$emit("changed", this.$refs.street.value);
    },
  },
  computed: {
    ...mapGetters(["order", "currentDepartment"]),
  },
};
</script>

<style scoped></style>
