<template>
  <modal name="private-comment" height="auto" @before-open="beforeOpen">
    <div class="modal__wrapper">
      <h3 class="modal__header">Комментарий о клиенте (не виден клиенту)</h3>
      <form id="blacklist-form" class="form--branded">
        <div class="fancy-input fancy-input--no-label">
          <textarea
            placeholder="Комментарий"
            rows="5"
            v-model="comment"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="modal__buttons">
      <button
        type="submit"
        class="btn btn--primary btn--size-default"
        @click="editPrivateComment"
      >
        Сохранить
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalPrivateComment",
  data: () => {
    return {
      user: {},
      order: {},
      comment: "",
    };
  },
  methods: {
    beforeOpen(event) {
      this.user = event.params.user;
      this.order = event.params.order;
      if (this.user.private_comment) {
        this.comment = this.user.private_comment;
      } else {
        this.comment = "";
      }
    },
    editPrivateComment() {
      let payload = {
        field: "private_comment",
        value: this.comment,
        user: this.user,
        order: this.order,
      };
      this.$store.dispatch("setOrderUserField", payload);
      this.$modal.hide("private-comment");
    },
  },
};
</script>
