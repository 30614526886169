import MenuItem from "@/api/menu-item";
import {
  SET_CATEGORY,
  SET_CATEGORY_MENU_ITEMS,
  SET_ORDER,
  SET_ORDER_FIELD,
  SET_LOADING_FORM,
  RESET_STATE,
  SET_ADDRESSES,
  TOGGLE_PRINT_RECEIPT,
  SET_SAVE_LOADING,
} from "./mutation-types";
import Order from "@/api/order";
import User from "@/api/user";
import { getField, updateField } from "vuex-map-fields";
import { UPDATE_ORDERS_ORDER } from "@/store/modules/orders-list/mutation-types";
import { isApiError } from "@/utils/api";

//import locationState from '@/store/modules/location/index.js'

const getDefaultState = () => {
  return {
    isRestError: false,
    order: {
      contacts: {},
      address: {},
      payment_info: {
        rest_from: 0,
      },
      status: {
        status: Order.STATUS.cart,
      },
      items: [],
      cancellation: {},
      comment: "",
    },
    category: {},
    categoryMenuItems: [],
    addresses: [],
    hasNotSyncedFields: false,
    loadingForm: false,
    printReceipt: true,
    isModalOpen: false,
    orderId: null,
    isLoading: false,
    isSaveLoading: false,
    readonlyChange: false,
    isPatchNeed: true,
    orderPhone: null,
    toppingsModal: {},
  };
};

export default {
  state: getDefaultState(),
  getters: {
    getReadonly: (state) => {
      return state.readonlyChange;
    },
    getField,

    isLoading: (state) => {
      return state.isLoading;
    },
    isSaveLoading: (state) => {
      return state.isSaveLoading;
    },

    isModalOpen: (state) => {
      return state.isModalOpen;
    },
    orderId: (state) => {
      return state.orderId;
    },

    order: (state) => {
      return state.order;
    },
    getIsRestError: (state) => {
      return state.isRestError;
    },
    hasNotSyncedFields: (state) => {
      return state.hasNotSyncedFields;
    },
    isPaid: (state) => {
      return state.order.payment_info.total_paid > 0;
    },
    category: (state) => {
      return state.category;
    },
    categoryMenuItems: (state) => {
      return state.categoryMenuItems;
    },
    restAmount: (state) => {
      let rest =
        state.order.payment_info.rest_from -
        state.order.payment_info.total_price;
      return rest > 0 ? rest.toFixed(2) : 0;
    },
    loadingForm: (state) => {
      return state.loadingForm;
    },
    lastAddresses: (state) => {
      return state.addresses;
    },
    printReceipt: (state) => {
      return state.printReceipt;
    },
    isPatchNeed: (state) => {
      return state.isPatchNeed;
    },
  },
  mutations: {
    updateField,

    [SET_CATEGORY](state, { category }) {
      state.category = category;
    },
    [SET_CATEGORY_MENU_ITEMS](state, { items }) {
      state.categoryMenuItems = items;
    },
    [SET_ORDER](state, { order }) {
      state.order = order;
      if (
        state.order.payment_info.rest_from <
        parseFloat(state.order.payment_info.total_price)
      ) {
        state.order.payment_info.rest_from = "0.00";
      }
    },
    [SET_ORDER_FIELD](state, { field, path, value }) {
      if (path) {
        // user can pass order's `field` name (e.g. payment_info.rest_from) or full `path` (e.g. order.payment_info.rest_from)
        field = path.split(".").slice(1).join(".");
      }

      if (field.split(".").length > 1) {
        // e.g. 'payment_info.payment_type'
        if (!(field.split(".")[0] in state.order)) {
          state.order[field.split(".")[0]] = {};
        }
        state.order[field.split(".")[0]][field.split(".")[1]] = value;
      } else {
        state.order[field] = value;
      }

      state.hasNotSyncedFields = true;
    },
    [SET_LOADING_FORM](state, bool) {
      state.loadingForm = bool;
    },
    [SET_SAVE_LOADING](state, bool) {
      state.isSaveLoading = bool;
    },
    [RESET_STATE](state) {
      Object.assign(state, getDefaultState());
    },
    [SET_ADDRESSES](state, { addresses }) {
      state.addresses = addresses;
    },
    [TOGGLE_PRINT_RECEIPT](state) {
      state.printReceipt = !state.printReceipt;
    },
    setPrintReceipt(state, value) {
      state.printReceipt = value;
    },
    setIsRestError(state, value) {
      state.isRestError = value;
    },
    setToppingsModal(state, { isOpen, item, type }) {
      state.toppingsModal = {
        isOpen,
        item,
        type,
      };
    },
    changeIsModalOpen(state, { value, id }) {
      state.isModalOpen = value;
      state.orderId = id;
    },
    changeIsLoading(state, value) {
      state.isLoading = value;
    },
    setRestValue(state, value) {
      state.order.payment_info.rest_from = value;
    },
    changeStateValues(state) {
      if (
        state.order.payment_info.rest_from <
        parseInt(state.order.payment_info.total_price)
      ) {
        state.order.payment_info.rest_from = 0;
      }
    },
    setRestFrom(state, value) {
      state.order.payment_info.rest_from = value;
    },
    readonlyChange(state, value) {
      state.readonlyChange = value;
    },
    resetFields(state) {
      state.order.address.street = null;
      state.order.address.house = null;
      state.order.address.apartment = null;
      state.order.address.porch = null;
      state.order.address.floor = null;
    },
    setIsPatchNeed(state, value) {
      state.isPatchNeed = value;
    },
    setOrderPhone(state, { value, isNull }) {
      if (value) {
        state.orderPhone = value;
      } else if (!value && isNull) {
        state.orderPhone = null;
      }
    },
  },
  actions: {
    async setCategoryMenuItems({ commit }, category) {
      commit(SET_CATEGORY, { category });
      commit(SET_CATEGORY_MENU_ITEMS, { items: [] });
      let items = await MenuItem.get(
        category.slug,
        localStorage.getItem("citySlug")
      );
      commit(SET_CATEGORY_MENU_ITEMS, { items: items.data.results });
    },
    async setOrderById({ commit }, orderId) {
      let order = {};
      order = await Order.get(orderId);
      commit(SET_ORDER, { order });
    },

    async createOrder({ commit, getters }) {
      let order = {};
      if (!getters.order.id) {
        let orderData = {};
        for (const [key, value] of Object.entries(
          JSON.parse(JSON.stringify(getters.order))
        )) {
          if (value.length > 0 || key == "payment_info") {
            orderData[key] = value;
          }
        }
        order = await Order.create(orderData);
        if (!isApiError(order)) {
          commit(SET_ORDER, { order });
        }
      }
    },

    async addItemToOrder(
      { commit, getters, dispatch },
      { itemId, modifier_groups, item }
    ) {
      if (getters.order.status.is_confirmed) {
        const result = await Order.get(getters.order.id);
        if (
          result.status.status === "done" ||
          (result.status.is_packed &&
            result.delivery_type === "delivery" &&
            result.courier)
        ) {
          alert("Заказ уже завершен");
          return;
        }
      }
      if (
        getters.order.status.is_confirmed &&
        getters.order.delivery_type === "stay" &&
        getters.order.payment_info.payment_type !== "cash"
      ) {
        alert("Товары можно добавлять только при оплате наличными");
        return;
      }

      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");

      let order = {};
      if (getters.order.id) {
        order = await Order.addItem(getters.order.id, item, modifier_groups);
      } else {
        let orderData = {};
        for (const [key, value] of Object.entries(
          JSON.parse(JSON.stringify(getters.order))
        )) {
          if (value.length > 0) {
            orderData[key] = value;
          }
        }
        orderData.items = [
          {
            slug: itemId,
            modifier_groups: modifier_groups,
          },
        ];
        order = await Order.create(orderData);
      }
      commit(SET_LOADING_FORM, false);
      if (!isApiError(order)) {
        commit(SET_ORDER, { order });
        commit("changeStateValues");
        return true;
      } else {
        return false;
      }
    },
    async removeItemFromOrder({ commit, getters, dispatch }, itemId) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.removeItem(itemId, getters.order.id);
      commit(SET_ORDER, { order });
      commit(SET_LOADING_FORM, false);
    },
    async changeOrderItemQty({ commit, getters, dispatch }, { itemId, qty }) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.changeItemQty(itemId, getters.order.id, qty);
      if (!isApiError(order)) {
        commit(SET_ORDER, { order });
      }
      commit(SET_LOADING_FORM, false);
    },
    async addComment({ commit, getters, dispatch }, { itemId, comment }) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.addComment(itemId, getters.order.id, comment);
      if (!isApiError(order)) {
        commit(SET_ORDER, { order });
      }
      commit(SET_LOADING_FORM, false);
    },
    async addOrderItemTopping(
      { commit, getters, dispatch },
      { itemId, modifier_groups }
    ) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.addItemTopping(
        itemId,
        getters.order.id,
        modifier_groups
      );
      if (!isApiError(order)) {
        commit(SET_ORDER, { order });
        commit(SET_LOADING_FORM, false);
        return true;
      } else {
        return false;
      }
    },
    async removeItemTopping(
      { commit, getters, dispatch },
      { itemId, toppingId }
    ) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.removeItemTopping(
        itemId,
        getters.order.id,
        toppingId
      );
      if (!isApiError(order)) {
        commit(SET_ORDER, { order });
      }
      commit(SET_LOADING_FORM, false);
    },
    async setOrderField(
      { commit, getters, dispatch },
      { field, value, orderId, isCheck }
    ) {
      commit(SET_LOADING_FORM, true);
      if (!isCheck) {
        await dispatch("saveNotSyncedFields");
      }

      let formOrder;
      if (!orderId) {
        orderId = getters.order.id;
        formOrder = true;
      } else {
        formOrder = false;
      }
      let order = await Order.setField(field, value, orderId);

      if (!isApiError(order)) {
        if (formOrder) {
          commit(SET_ORDER, { order });
        } else {
          commit(UPDATE_ORDERS_ORDER, { order });
        }
      }
      commit(SET_LOADING_FORM, false);
    },
    async changeDeliveryType(
      { commit },
      { field, value, orderId, address, contacts, comment }
    ) {
      commit("changeIsLoading", true);
      if (value === "takeaway") {
        commit("changeIsModalOpen", { value: true });
      }
      commit(SET_LOADING_FORM, true);

      let order = await Order.changeDeliveryType(
        field,
        value,
        orderId,
        address,
        contacts,
        comment
      );

      if (
        order.fallback_message === "Phone: Введен некорректный номер телефона."
      ) {
        setTimeout(() => {
          location.reload();
        }, 1000);
      }

      commit(SET_ORDER, { order });
      commit("changeIsLoading", false);
      commit("changeIsModalOpen", { value: false });
      commit(SET_LOADING_FORM, false);
      commit("readonlyChange", false);
    },
    async changePaymentType({ commit }, { field, value, orderId }) {
      commit("changeIsLoading", true);
      commit("changeIsModalOpen", { value: true });
      commit(SET_LOADING_FORM, true);

      let order = await Order.changePaymentType(field, value, orderId);

      commit(SET_ORDER, { order });
      commit(SET_LOADING_FORM, false);
      commit("changeIsLoading", false);
      commit("changeIsModalOpen", { value: false });
      commit("readonlyChange", false);
    },
    async applyUserPoints({ commit, getters, dispatch }, { code }) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.applyUserPoints(getters.order.id, code);
      if (order && !isApiError(order)) {
        commit(SET_ORDER, { order });
      }
      commit(SET_LOADING_FORM, false);
    },
    async changeUserPoints({ commit, getters, dispatch }, { points }) {
      commit(SET_LOADING_FORM, true);
      await dispatch("saveNotSyncedFields");
      let order = await Order.changeUserPoints(getters.order.id, points);
      if (order && !isApiError(order)) {
        commit(SET_ORDER, { order });
      }
      commit(SET_LOADING_FORM, false);
    },
    async saveNotSyncedFields({ commit, getters, dispatch }) {
      if (getters.isPatchNeed) {
        await dispatch("createOrder");
        if (getters.hasNotSyncedFields && getters.order.id) {
          await dispatch("saveOrder");
          commit(SET_LOADING_FORM, false);
        }
      }
      commit("setIsPatchNeed", true);
    },

    async getSuggest(context, { input, city }) {
      const responseData = await Order.getSuggest(input, city);
      if (isApiError(responseData.data)) {
        return false;
      } else {
        return responseData.data;
      }
    },
    async getGeo(context, { address, city, id }) {
      const responseData = await Order.getGeo({ address, city, id });
      if (isApiError(responseData.data)) {
        return false;
      } else {
        return responseData.data[0];
      }
    },
    async checkGeo(context, { city, coordinates }) {
      const responseData = await Order.checkGeo(city, coordinates);
      if (isApiError(responseData.data)) {
        return false;
      } else {
        return responseData.data;
      }
    },
    async saveOrder({ state, commit, getters }, payload) {
      commit(SET_LOADING_FORM, true);
      let confirm = false;
      let checkout = false;
      let isSave = false;
      if (payload) {
        confirm = payload.confirm;
        checkout = payload.checkout;
        isSave = payload.isSave;
      }
      if (isSave) {
        commit(SET_SAVE_LOADING, true);
      }
      let order = {};

      if (confirm) {
        commit(SET_ORDER_FIELD, { field: "status.is_confirmed", value: true });
        commit(SET_ORDER_FIELD, {
          field: "department",
          value: getters.currentDepartment.slug,
        });
        commit(SET_ORDER_FIELD, {
          field: "city",
          value: localStorage.getItem("citySlug"),
        });
      }

      if (getters.order.delivery_type === "takeaway") {
        if (
          state.orderPhone == getters.user.department.city.country.phone_code
        ) {
          commit(SET_ORDER_FIELD, { field: "contacts.phone", value: null });
        } else {
          commit(SET_ORDER_FIELD, {
            field: "contacts.phone",
            value: state.orderPhone,
          });
        }
      }

      if (getters.order.delivery_type === "stay") {
        commit(SET_ORDER_FIELD, { field: "contacts.first_name", value: " " });
      }
      if (checkout && !confirm) {
        if (
          getters.order.delivery_type === "delivery" &&
          state.orderPhone == null
        ) {
          commit(SET_ORDER_FIELD, {
            field: "contacts.phone",
            value: getters.user.department.city.country.phone_code,
          });
        } else if (
          getters.order.delivery_type === "delivery" &&
          state.orderPhone
        ) {
          commit(SET_ORDER_FIELD, {
            field: "contacts.phone",
            value: state.orderPhone,
          });
        }
        commit("setIsPatchNeed", false);
        order = await Order.checkout(getters.order);
      } else {
        if (confirm) {
          if (
            getters.order.source === "dashboard" ||
            getters.order.source === "aggregator"
          ) {
            order = await Order.checkout(getters.order);
          } else {
            order = await Order.update(getters.order);
            order = await Order.changeState({
              orderId: getters.order.id,
              state: "confirmed",
            });
          }
        } else {
          order = await Order.update(getters.order);
        }
      }
      const success = !isApiError(order);
      if (success) {
        commit(SET_ORDER, { order });
        commit(UPDATE_ORDERS_ORDER, { order });
      } else {
        commit(SET_SAVE_LOADING, false);
      }
      commit(SET_LOADING_FORM, false);
      return success;
    },
    async changeState(context, payload) {
      await Order.changeState({
        orderId: payload.orderId,
        state: payload.state,
        items: payload.items,
      });
    },
    async cancelOrder({ commit }, { orderId, reasonId, comment }) {
      const order = await Order.cancel(orderId, reasonId, comment);
      if (!isApiError(order)) {
        commit(UPDATE_ORDERS_ORDER, { order });
      }
    },
    async accomplishOrder({ commit }, { order }) {
      if (order.status.is_confirmed && !order.status.is_cooked) {
        let items = order.items.map((i) => i.id);
        await Order.changeState({
          orderId: order.id,
          state: "cooked",
          items: items,
        });
        await Order.changeState({
          orderId: order.id,
          state: "packed",
          items: items,
        });
      }
      if (order.status.is_cooked && !order.status.is_packed) {
        let items = order.items.map((i) => i.id);
        await Order.changeState({
          orderId: order.id,
          state: "packed",
          items: items,
        });
      }
      const orderData = await Order.accomplish(order.id);
      if (!isApiError(orderData)) {
        commit(UPDATE_ORDERS_ORDER, { orderData });
      }
    },
    async fetchLastAddresses({ commit }, phone) {
      const addresses = await User.getLastAddresses(phone);
      commit(SET_ADDRESSES, { addresses });
    },
    setOrderDefaults({ commit, getters }) {
      commit("setRestFrom", 0);
      let order = JSON.parse(JSON.stringify(getDefaultState().order));
      order.delivery_type = Order.DELIVERY_TYPE.takeaway;
      order.city = localStorage.getItem("citySlug");

      order.department = getters.currentDepartment.slug;
      order.payment_info.payment_type = Order.PAYMENT_TYPE.cash;
      order.source = Order.SOURCE.dashboard;
      order.payment_info.rest_from = 0;

      commit(SET_ORDER, { order });
    },
  },
};
