import { kkmExecuteSuccess } from "@/utils/kkm/kkmCallback";

export function closeDay(kkm_pay_by_processing) {
    if ("KkmServer" in window) {
        CloseShift();
        if (kkm_pay_by_processing) {
            Settlement();
            //XReport();
            TerminalReport();
        }

    }
}

export function openShift() {
    if ("KkmServer" in window) {
        var Data = window.KkmServer.GetDataCheck();
        Data.Command = "OpenShift";
        //Data.CashierName = department.kkm_cashier_name;
        //Data.CashierVATIN = department.kkm_cashier_tax_number;
        Data.NotPrint = false;
        Data.Execute(kkmExecuteSuccess);
    }
}

function CloseShift() {
    var Data = window.KkmServer.GetDataCheck();
    Data.Command = "CloseShift";
    //Data.CashierName = department.kkm_cashier_name;
    //Data.CashierVATIN = department.kkm_cashier_tax_number;
    Data.NotPrint = false;
    Data.Execute(kkmExecuteSuccess);
}

// function XReport () {
//     var Data = window.KkmServer.GetDataCheck();
//     Data.Command = "XReport";
//     Data.Execute(kkmExecuteSuccess);
// }

// Закрыть смену по картам
function Settlement() {
    var Data = window.KkmServer.GetDataCheck();
    Data.Command = "Settlement";
    Data.NumDevice = 2;
    Data.Execute(kkmExecuteSuccess);
}

// Получить итоги дня по картам
function TerminalReport() {
    var Data = window.KkmServer.GetDataCheck();
    Data.Command = "TerminalReport";
    Data.NumDevice = 2;
    Data.Detailed = true;
    Data.Execute(kkmExecuteSuccess);
}