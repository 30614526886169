<template>
  <div class="main-block main-block__congestion">
    <div class="row row-no">
      <div class="col-line__wrap">
        <span class="main-col__subtitle">Загруженность линии:</span>
        <div class="row">
          <div class="col-line">
            <b>{{ ordersByStep?.confirmation || "0" }}</b>
            <span>подтверждаются</span>
            <p>
              <span>{{
                summaryToday.avg_confirmation
                  ? summaryToday.avg_confirmation.substr(0, 8)
                  : "00:00:00"
              }}</span>
              (ср. время)
            </p>
          </div>
          <div class="col-line">
            <b>{{ ordersByStep?.cooking || "0" }}</b>
            <span>готовятся</span>
            <p>
              <span>{{
                summaryToday.avg_cooking
                  ? summaryToday.avg_cooking.substr(0, 8)
                  : "00:00:00"
              }}</span>
              (ср. время)
            </p>
          </div>
          <div class="col-line">
            <b>{{ ordersByStep?.packing || "0" }}</b>
            <span>упаковываются</span>
            <p>
              <span>{{
                summaryToday.avg_packing
                  ? summaryToday.avg_packing.substr(0, 8)
                  : "00:00:00"
              }}</span>
              (ср. время)
            </p>
          </div>
          <div class="col-line">
            <b>{{ ordersByStep?.waiting_for_courier || "0" }}</b>
            <span>ожидают доставку</span>
            <p>
              <span>{{
                summaryToday.avg_waiting_for_delivery
                  ? summaryToday.avg_waiting_for_delivery.substr(0, 8)
                  : "00:00:00"
              }}</span>
              (ср. время)
            </p>
          </div>
          <div class="col-line">
            <b>{{ ordersByStep?.delivering || "0" }}</b>
            <span>доставляются</span>
            <p>
              <span>{{
                summaryToday.avg_delivering
                  ? summaryToday.avg_delivering.substr(0, 8)
                  : "00:00:00"
              }}</span>
              (ср. время)
            </p>
          </div>
        </div>
      </div>
      <div class="col-speed">
        <span class="main-col__subtitle">Скорость:</span>
        <ul>
          <li class="text-big">
            Заказы
            <span
              >{{ summaryToday.done_count || "0" }} /
              {{
                (summaryToday.done_count / totalCount) * 100 || "0" | percent
              }}</span
            >
          </li>
          <li class="color-red text-big">
            Опоздавшие
            <span
              >{{ summaryToday.late_count || "0" }} /
              {{
                (summaryToday.late_count / totalCount) * 100 || "0" | percent
              }}</span
            >
          </li>
          <li>
            по вине кухни
            <span class="color-red">{{
              summaryToday.late_kitchen_count || "0"
            }}</span>
          </li>
          <li>
            по вине курьеров
            <span class="color-red">{{
              summaryToday.late_delivery_count || "0"
            }}</span>
          </li>
          <li>
            по вине кухни и курьеров
            <span class="color-red">{{
              summaryToday.late_kitchen_n_delivery_count || "0"
            }}</span>
          </li>
          <li>---</li>
          <li>
            ср. время выполнения заказа (доставка)
            <span>{{
              summaryToday.avg_processing_delivery
                ? summaryToday.avg_processing_delivery.substr(0, 8)
                : "00:00:00"
            }}</span>
          </li>
          <li>
            ср. время выполнения заказа (самовывоз)
            <span>{{
              summaryToday.avg_processing_takeaway
                ? summaryToday.avg_processing_takeaway.substr(0, 8)
                : "00:00:00"
            }}</span>
          </li>
        </ul>
      </div>
    </div>
    <p>
      Средний чек доставка:
      <b class="color-red"
        >{{ summaryToday.avg_order_total_price_delivery || "0"
        }}{{ currencySymbol }}</b
      >
    </p>
    <p>
      Средний чек самовывоз:
      <b class="color-red"
        >{{ summaryToday.avg_order_total_price_takeaway || "0"
        }}{{ currencySymbol }}</b
      >
    </p>
  </div>
  <!-- block -->
</template>

<script>
import Auth from "@/store/modules/auth";

export default {
  name: "LinePerformanceBlock",
  props: {
    summaryToday: {
      type: Object,
      required: true,
    },
    ordersByStep: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencySymbol: "",
    };
  },
  mounted() {
    this.currencySymbol = Auth.state.user.country.currency_symbol;
  },
  computed: {
    totalCount() {
      return this.summaryToday.done_count + this.summaryToday.canceled_count;
    },
  },
};
</script>

<style scoped></style>
