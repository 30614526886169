import { render, staticRenderFns } from "./OrderEditPage.vue?vue&type=template&id=6cff08c3&scoped=true&"
import script from "./OrderEditPage.vue?vue&type=script&lang=js&"
export * from "./OrderEditPage.vue?vue&type=script&lang=js&"
import style0 from "./OrderEditPage.vue?vue&type=style&index=0&id=6cff08c3&prod&lang=sass&scoped=true&"
import style1 from "./OrderEditPage.vue?vue&type=style&index=1&id=6cff08c3&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6cff08c3",
  null
  
)

export default component.exports