<template>
  <div>
    <orders-list
      :filter="ordersFilter"
      :lateMinutes="orderLateMinutes"
      :step-field="stepField"
      :prev-step-field="prevStepField"
    />
    <notifications position="bottom right" />
  </div>
</template>

<script>
import OrdersList from "@/components/cooking-packing/OrdersList";
import { mapGetters } from "vuex";

export default {
  components: { OrdersList },
  data() {
    return {
      ordersFilter: {
        stage: "packing",
      },
      prevStepField: "is_cooked",
      stepField: "is_packed",
    };
  },
  computed: {
    ...mapGetters(["currentCity"]),
    orderLateMinutes() {
      return (
        this.currentCity?.cooking_duration_minutes +
        this.currentCity?.packing_duration_minutes
      );
    },
  },
};
</script>
